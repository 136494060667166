import {createSlice} from "@reduxjs/toolkit";

const tourSlice = createSlice({
  name: "tour",
  initialState: {
    bp: {
      toggle: false,
    },
    guide: {
      toggle: false,
    },
  },
  reducers: {
    openBpTour: (state) => {
      state.bp.toggle = true;
      state.guide.toggle = false;
      return state;
    },
    closeBpTour: (state) => {
      state.bp.toggle = false;
      return state;
    },
    openGuideTour: (state) => {
      state.bp.toggle = false;
      state.guide.toggle = true;
      return state;
    },
    closeGuideTour: (state) => {
      state.guide.toggle = false;
      return state;
    },
  }
});

export default tourSlice.reducer;

export const {
  openBpTour,
  closeBpTour,
  openGuideTour,
  closeGuideTour,
} = tourSlice.actions;