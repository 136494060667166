import React, { useEffect, useMemo } from "react";
import { DenyIcon } from "../../components/DynamicIcon/edit/editIcon";
import "./modalWrapper.css";

/**
 * 弹窗
 * @param children
 * @param {function} onClickOutside - 点击弹窗外侧区域触发事件
 * @param {Object} style - 指定弹窗的内联样式
 * @param {Array<string>} classes - 弹窗附加类名列表
 * @param {boolean} mask - 是否有底层毛玻璃遮罩, 默认值为true
 * @param showCloseBtn - 默认值为false
 * @param onClickCloseBtn
 * @returns {JSX.Element}
 * @constructor
 */
export default function ModalWrapper({children, onClickOutside=()=>{}, style, classes=[], mask=true, showCloseBtn=false, onClickCloseBtn=()=>{}}) {
  const userDevice = localStorage.getItem("device")
  useEffect(() => {
    const clickOutside = () => {
      onClickOutside();
    };
    window.addEventListener("mousedown", clickOutside);
    return () => {
      window.removeEventListener("mousedown", clickOutside);
    };

  }, [onClickOutside]);

  const classList = useMemo(()=>classes.join(" "), [classes])

  function handleKeyUp(e) {
    e.stopPropagation()
    if(e.key==='Escape'){
      onClickOutside()
    }
  }

  return (
    <>
      {mask&&
        <div className={`modal-wrapper-mask`} data-payment={classList==="payment"}/>
      }
      <div className={`modal-wrapper ${classList}`} style={style} data-mobile={userDevice==="Mobile"}
           onClick={e => e.stopPropagation()}
           onKeyUp={handleKeyUp} onMouseDown={e=>e.stopPropagation()}
      >
        {showCloseBtn &&
          <div className={"close-btn-wrapper"}
               onClick={e=>{e.stopPropagation(); onClickCloseBtn()}}
          ><DenyIcon fill={"#888888"}/></div>
        }
        {children}
      </div>
    </>
  );
}