import { createSlice } from "@reduxjs/toolkit";
import { handleCompositeQaoDatas, handleQaoData } from "../components/pro/data";

export const proPreQuestionSlice = createSlice({
  name:"proPreQuestion",
  initialState : {
    document: [
      {
        currentDocId:null,
        currentCId:null,
        currentSId:null,
      }
    ],
    question: [],//后端响应回来的原始数据
    qaoDatas:[],//处理后的数据
    questionId: [],//可渲染的问题id
    questionShowOrder:[],//已显示的问题序号
    questionShow: [],//标记问题是否显示
    dynamicTitle: [],//记录动态问题的标题文本和id
    expandQuestionId: [],//记录拓展依赖问题id（已弃用）
    needCompletedQuestion: [],//需要完成的问题列表
    activeQuestion:""//标记用户正在点击问题列表里的问题id
  },
  reducers: {
    setQuestion : (state, action) => {
      state.question = action.payload;
      return state;
    },

    setUpdateQuestion: (state, action) => {
      const data = action.payload;
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const value = data[key];
          // 将键值对添加到 state.question 对象中
          state.question[key] = value;
        }
      }
      return state;
    },

    setCurrentDocument: (state, action) => {
      const { docId, cId, sId } = action.payload;
      
      return {
        ...state,
        document: {
          currentDocId: docId,
          currentCId: cId,
          currentSId: sId,
        },
      };
    },
    
    setQuestionId : (state, action) => {
      state.questionId = action.payload;
      return state;
    },

    setExpandQuestionId : (state, action) => {
      state.expandQuestionId = action.payload;
      return state;
    },
    
    setQaoDatas: (state, action) => {
      state.qaoDatas = action.payload;
      return state;
    },

    setUpDateQaoDatas: (state, action) => {
      const {questionInfo,questionId,handlechoicesIndexList} = action.payload;
      const {qaoDatas} = state;
      let upDateQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId) {
          return {
            ...item,
            choiceIndexes: handlechoicesIndexList
          };
            
        } else {
          return item;
        }
      });
      for (const key in questionInfo) {
        if (Object.hasOwnProperty.call(questionInfo, key)) {
          const upDateQaoData = handleQaoData(questionInfo[key], key);
          const existingIndex = upDateQaoDatas.findIndex(item => item.questionId === upDateQaoData[0].questionId);

          if (existingIndex !== -1) {
            // 如果存在，则替换
            upDateQaoDatas[existingIndex] = Object.assign({}, upDateQaoData[0]);
          } else {
            // 否则添加到数组中
            upDateQaoDatas.push(Object.assign({}, upDateQaoData[0]));
          }
        }
      }
      
      return {
        ...state,
        qaoDatas:upDateQaoDatas
      }
    },

    setInitQaoDatasOptions:(state,action) => {
      const { qaoDatas } = state;
      const {questionId} = action.payload;
      const updatedQaoDatas = qaoDatas.map(item => {
        if(item.questionId === questionId) {
          return {
            ...item,
            options: {
              choiceOptions:[],
              choiceColorOptions:[],
              textOptions:[],
              timeOptions:[],
              affixedTextOptions:[],
              tableOptions:[],
              dropdownOptions:[],
              compositeOptions:[],
              provinceCityOptions:[],
              addQuestionOptions:[],
              textMixButtonOptions:[],
              buttonMixDropDownOptions:[],
              buttonMixTimeOptions:[],
            }
          };
        }
        return item;
      })
      return {
        ...state,
        qaoDatas: updatedQaoDatas
    }; 
    },

    setQaoDatasQuestion: (state, action) => {
      const { qaoDatas } = state;
      const { questionId,newReplacedQuestion} = action.payload;
      
      const updatedQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId) {
          return {
            ...item,
            question: newReplacedQuestion
          };
        } else {
          return item;
        }
      })
      
      return {
        ...state,
        qaoDatas: updatedQaoDatas
      };
    },

    setQaoDatasOptions: (state, action) => {
      const { qaoDatas } = state;
      const {questionId,list,optionType,singleOption} = action.payload;//传list和传singleOption需要互斥
      const updatedQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId && optionType==="CHOICE") {
            return {
              ...item,
              options: {
                ...item.options,
                choiceOptions: singleOption?[...item.options.choiceOptions,singleOption]:list
              },
            };
        }else if(item.questionId === questionId && optionType==="CHOICE_COLOR") {
          return {
            ...item,
            options: {
                ...item.options,
                choiceColorOptions: singleOption?[...item.options.choiceColorOptions,singleOption]:list
            }
          };
        }else if(item.questionId === questionId && optionType==="TEXT") {
          return {
            ...item,
            options: {
                ...item.options,
                textOptions: singleOption?[...item.options.textOptions,singleOption]:list
            }
          };
        }else if(item.questionId === questionId && optionType==="TIME") {
          return {
            ...item,
            options: {
                ...item.options,
                timeOptions: singleOption?[...item.options.timeOptions,singleOption]:list
            }
          };
        }else if(item.questionId === questionId && optionType==="DROPDOWN") {
          return {
            ...item,
            options: {
                ...item.options,
                dropdownOptions: singleOption?[...item.options.dropdownOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="PROVINCE_CITY_DISTRICT") {
          return {
            ...item,
            options: {
                ...item.options,
                provinceCityOptions: singleOption?[...item.options.provinceCityOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="COMPOSITE") {
          return {
            ...item,
            options: {
                ...item.options,
                compositeOptions: singleOption?[...item.options.compositeOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="AFFIXED_TEXT") {
          return {
            ...item,
            options: {
                ...item.options,
                affixedTextOptions: singleOption?[...item.options.affixedTextOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="TABLE") {
          return {
            ...item,
            options: {
                ...item.options,
                tableOptions: singleOption?[...item.options.tableOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="TEXT_MIXTURE_BUTTON") {
          return {
            ...item,
            options: {
                ...item.options,
                textMixButtonOptions: singleOption?[...item.options.textMixButtonOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="BUTTON_AND_DROPDOWN") {
          return {
            ...item,
            options: {
                ...item.options,
                buttonMixDropDownOptions: singleOption?[...item.options.buttonMixDropDownOptions,singleOption]:list
            }
          };
        }
        else if(item.questionId === questionId && optionType==="BUTTON_AND_TIME") {
          return {
            ...item,
            options: {
                ...item.options,
                buttonMixTimeOptions: singleOption?[...item.options.buttonMixTimeOptions,singleOption]:list
            }
          };
        }
        return item;
      });

      return {
          ...state,
          qaoDatas: updatedQaoDatas
      };    
    },

    setQaoDatasCompositeOptions: (state, action) => {
      const {qaoDatas} = state;
      const {questionId,dropdownList,optionIndex,optionType,selectedValue,currentIndex,dropdownListText,dropdownListIndex} = action.payload;

      const {updateList} = handleCompositeQaoDatas({qaoDatas,questionId,dropdownList,optionIndex,optionType,selectedValue,currentIndex,dropdownListText,dropdownListIndex})
      const newChoiceIndexes = updateList.map(itemData=>{
        if(itemData.text!==""){
          return itemData.option_index
        }else{
          return null
        }
      }).filter(item=>item!==null)
      const updatedQaoDatas = qaoDatas.map(item => {
        if(item.questionId === questionId ) {
         
          return {
            ...item,
            options: {
                ...item.options,
                compositeOptions: updateList
            },
            choiceIndexes:newChoiceIndexes
          };
        }
        return item
      })

      return {
          ...state,
          qaoDatas: updatedQaoDatas
      };   
    },
      

    setCompositeOptions: (state, action) => {
      const { qaoDatas } = state;
      const {questionId,newOption} = action.payload;
      const updatedQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId) {
            return {
              ...item,
              options: {
                ...item.options,
                compositeOptions: [
                  ...item.options.compositeOptions,
                  newOption
                ]
              },
            };
        }
        return item;
      })
      return {
        ...state,
        qaoDatas: updatedQaoDatas
    }; 
    },

    setCompositeDropDownList: (state, action) => {
      const { qaoDatas } = state;
      const { questionId, text, index } = action.payload;
      const updatedQaoDatas = qaoDatas.map(qaoData => {
        if (qaoData.questionId === questionId) {
          if (qaoData.options && qaoData.options.compositeOptions && qaoData.options.compositeOptions.length > 0) {
            const compositeOption = qaoData.options.compositeOptions[0];
            if (compositeOption.dropdown_list && index >= 0 && index < compositeOption.dropdown_list.length) {
              const newDropdownList = compositeOption.dropdown_list.map((item, idx) => {
                if(item.hasOwnProperty("dropdown_list")&&item.dropdown_list.hasOwnProperty("text")&&item.dropdown_list.hasOwnProperty("left")&&text.includes(item.dropdown_list.left)){
                  const currentDropdownList = {...item.dropdown_list,text:text}
                  return idx === index ? { ...item, text: text,dropdown_list:currentDropdownList} : item;
                }
                return idx === index ? { ...item, text: text} : item;
              });
              return {
                ...qaoData,
                options: {
                  ...qaoData.options,
                  compositeOptions: [
                    {
                      ...compositeOption,
                      text:text,
                      dropdown_list: newDropdownList
                    },
                    ...qaoData.options.compositeOptions.slice(1)
                  ]
                }
              };
            }
          }
        }
        return qaoData;
      });
    
      return {
        ...state,
        qaoDatas: updatedQaoDatas
      };
    },

    setQaoDatasChoiceIndexes: (state, action) => {
      const { qaoDatas } = state;
      const {questionId,reqValue,optionType,isTableShow} = action.payload;
      const currentQaoData = qaoDatas.find(qaoData => qaoData.questionId === questionId);
      const currentChoiceIndexes = currentQaoData.choiceIndexes;

      //对CHOICE选项做处理
      const currentChoiceOptionsIndexes = currentQaoData.options.choiceOptions.map(({option_index}) => option_index);
      const isNotChoiceIndexes = currentChoiceIndexes.filter(item=> !currentChoiceOptionsIndexes.includes(item));
      let combinedArray = [];
      if(optionType === "CHOICE") {
        combinedArray = [...new Set(reqValue.concat(isNotChoiceIndexes))];
      }

      //对CHOICE_COLOR选项做处理
      const currentChoiceColorOptionsIndexes = currentQaoData.options.choiceColorOptions.map(({option_index}) => option_index);
      const isNotChoiceColorIndexes = currentChoiceIndexes.filter(item=> !currentChoiceColorOptionsIndexes.includes(item));
        
      if(optionType === "CHOICE_COLOR") {
        combinedArray = [...new Set(reqValue.concat(isNotChoiceColorIndexes))];
      }

      //对TEXTE选项做处理
      if(optionType === "TEXT") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.textOptions.find(({option_index})=>option_index===item).text !== ""
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.textOptions.find(({option_index})=>option_index===item).text === ""
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对COMPOSITE选项做处理
      if(optionType === "COMPOSITE") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.compositeOptions.find(({option_index})=>option_index===item).text !== ""
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.compositeOptions.find(({option_index})=>option_index===item).text === ""
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对AFFIXED_TEXT选项做处理
      if(optionType === "AFFIXED_TEXT") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          if(isTableShow !== undefined){
            return currentQaoData.options.affixedTextOptions.find(({option_index})=>option_index===item).text !== ""&&!isTableShow
          }else{
            return currentQaoData.options.affixedTextOptions.find(({option_index})=>option_index===item).text !== ""
          }
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          if(isTableShow !== undefined){
            return currentQaoData.options.affixedTextOptions.find(({option_index})=>option_index===item).text === ""|| isTableShow
          }else{
            return currentQaoData.options.affixedTextOptions.find(({option_index})=>option_index===item).text === ""
          } 
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
        if(isTableShow !== undefined && !isTableShow) {
          const currentTableOptionsIndex = currentQaoData.options.tableOptions.map(({option_index})=>option_index);
          filteredChoiceIndexes = filteredChoiceIndexes.filter(item=>!currentTableOptionsIndex.includes(item))
        }
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对TABLE选项做处理
      if(optionType === "TABLE") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          if(isTableShow !== undefined){
            return currentQaoData.options.tableOptions.find(({option_index})=>option_index===item).text !== ""&&isTableShow
          }else{
            return currentQaoData.options.tableOptions.find(({option_index})=>option_index===item).text !== ""
          }
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          if(isTableShow !== undefined){
            return currentQaoData.options.tableOptions.find(({option_index})=>option_index===item).text === ""|| !isTableShow
          }else{
            return currentQaoData.options.tableOptions.find(({option_index})=>option_index===item).text === ""
          } 
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
        if(isTableShow !== undefined && isTableShow) {
          const currentTableOptionsIndex = currentQaoData.options.affixedTextOptions.map(({option_index})=>option_index);
          filteredChoiceIndexes = filteredChoiceIndexes.filter(item=>!currentTableOptionsIndex.includes(item))
        }
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对BUTTON_AND_TIME选项做处理
      if(optionType === "BUTTON_AND_TIME") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.buttonMixTimeOptions.find(({option_index})=>option_index===item).text !== ""
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.buttonMixTimeOptions.find(({option_index})=>option_index===item).text === ""
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对省市区组件做处理
      if(optionType === "PROVINCE_CITY_DISTRICT") {
        const currentHasTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.provinceCityOptions.find(({option_index})=>option_index===item).text !== ""
        })
        const currentNoTextIndexes = reqValue.filter((item)=>{
          return currentQaoData.options.provinceCityOptions.find(({option_index})=>option_index===item).text === ""
        })
        let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
      }

      //对TIME选项做处理
    if(optionType === "TIME") {
      const currentHasTextIndexes = reqValue.filter((item)=>{
        return currentQaoData.options.timeOptions.find(({option_index})=>option_index===item).text !== ""
      })
      const currentNoTextIndexes = reqValue.filter((item)=>{
        return currentQaoData.options.timeOptions.find(({option_index})=>option_index===item).text === ""
      })
      let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
      combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
    }

      const updatedQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId) {
          return {
            ...item,
            choiceIndexes: combinedArray
          };
            
        } else {
          return item;
        }
      })
    
      return {
          ...state,
          qaoDatas: updatedQaoDatas
      };

    },

    setQaoDatasDynamicChoiceIndexes: (state, action) => {
      const { qaoDatas } = state;
      const {questionId,dynamicOptionsChoiceIndex} = action.payload;
      const updatedQaoDatas = qaoDatas.map(item => {
        if (item.questionId === questionId) {
          return {
            ...item,
            choiceIndexes: dynamicOptionsChoiceIndex
          };
        } else {
          return item;
        }
      })
      
      return {
        ...state,
        qaoDatas: updatedQaoDatas
      };
    },

    //初始化选项（跳过）
    setInitChoiceIndexes : (state, action) => {
      const { qaoDatas } = state;
      const { questionId } = action.payload;
      const updatedQaoDatas = qaoDatas.map(qaoData => {
        if (qaoData.questionId === questionId) {
          return {//对时间类型做处理
                ...qaoData,
                choiceIndexes: []
              }
            
        } else {
          return qaoData;
        }
      });

      return {
        ...state,
        qaoDatas: updatedQaoDatas
    };
    },

    setDynamicTitile: (state, action) => {
      const { dynamicTitle } = state;
      const { questionId,question,description,questionType } = action.payload;

      const index = dynamicTitle.findIndex(item => item.questionId === questionId);

      if (index === -1) {
        // 如果问题不在列表中，则将其添加
        state.dynamicTitle.push({ questionId,question,description,questionType });
      } else {
        // 如果问题已存在于列表中，则更新isCompleted的值
        if (dynamicTitle[index].question !== question) {
          state.dynamicTitle[index].question = question;
        }
      }
    },

    setInitNeedCompletedQuestion: (state) => {
      return {
        ...state,
        dynamicTitle: [],
      }
    },

    setResetNeedCompletedQuestion:(state, action)=>{
      state.needCompletedQuestion = action.payload;
      return state;
    },

    setNeedCompletedQuestion: (state, action) => {
      const { needCompletedQuestion } = state;
      const { questionId,question,value,isCompleted } = action.payload;

      const index = needCompletedQuestion.findIndex(item => item.questionId === questionId);

      if (index === -1) {
        // 如果问题不在列表中，则将其添加
        state.needCompletedQuestion.push({ questionId,question,value,isCompleted });
      } else {
        // 如果问题已存在于列表中，则更新isCompleted的值
        if (needCompletedQuestion[index].isCompleted !== isCompleted) {
          state.needCompletedQuestion[index].isCompleted = isCompleted;
        }
      }
    },

    setDeleteNeedCompletedQuestion: (state, action) => {
      const { needCompletedQuestion } = state;
      const filteredQuestions = needCompletedQuestion.filter(item => item.questionId !== action.payload);
      
      return {
        ...state,
        needCompletedQuestion: filteredQuestions
      };
    },

    setQuestionOrder: (state,action) => {
      const { needCompletedQuestionSort } = action.payload;

      const newQuestionShowOrder = needCompletedQuestionSort.map(({ questionId }, index) => {
        return { questionId: questionId, order: index + 1 };
      });
    
      return {
        ...state,
        questionShowOrder: newQuestionShowOrder
      };
    },    

    setQuestionShow: (state, action) => {
      const { questionShow } = state;
      const { questionId, isShow } = action.payload;
    
      const index = questionShow.findIndex(item => item.questionId === questionId);

      if (index === -1) {
        // 如果问题不在列表中，则将其添加
        state.questionShow.push({ questionId, isShow });
      } else {
        // 如果问题已存在于列表中，则更新isShow的值
        if (questionShow[index].isShow !== isShow) {
          state.questionShow[index].isShow = isShow;
        }
      }
    },

    setActiveQuestion: (state, action) => {
      state.activeQuestion = action.payload;
      return state;
    },

    //清除redux数据
    setClearPreQuestion: (state) => {
      return {
        ...state,
        question: [],
        qaoDatas:[],
        questionId: [],
        questionShowOrder:[],
        questionShow: [],
        dynamicTitle: [],
        expandQuestionId: [],
        needCompletedQuestion: [],
        activeQuestion:""
      }
    },
  }
})

export const {
  setQuestion,
  setUpdateQuestion,
  setCurrentDocument,
  setQuestionId,
  setExpandQuestionId,
  setQaoDatas,
  setUpDateQaoDatas,
  setInitQaoDatasOptions,
  setCompositeDropDownList,
  setQaoDatasQuestion,
  setQaoDatasOptions,
  setQaoDatasCompositeOptions,
  setCompositeOptions,
  setQaoDatasChoiceIndexes,
  setQaoDatasDynamicChoiceIndexes,
  setInitChoiceIndexes,
  setDynamicTitile,
  setNeedCompletedQuestion,
  setResetNeedCompletedQuestion,
  setInitNeedCompletedQuestion,
  setDeleteNeedCompletedQuestion,
  setQuestionOrder,
  setClearPreQuestion,
  setQuestionShow,
  setActiveQuestion
} = proPreQuestionSlice.actions;

export default proPreQuestionSlice.reducer;