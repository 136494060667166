import { useEffect, useRef, useState } from "react";
import WorkHeader from "../components/Header/WorkHeader";
// import QABeginner from "../components/createDoc/QABeginner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./mobilePage.css"
import { useDispatch, useSelector } from "react-redux";
import { useExtraPreValidationHook, usePreValidationHook } from "../composables/preValidation";
import { setMessage, setMessageWaiting, setScreenStatus,openNew as openNewQABeginner, setOutline, setOutlineDone, swapOutlineH2, setOutlineH1, deleteOutlineH1, setOutlineH2, deleteOutlineH2, pushOutlineH2, setTemplateDone, setContinueProPlanFull, setTemplateProMiddleDone, setTemplateProDone, init } from "../reducers/QABeginnerSlice";
import { useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router";
import LoginRegister from "../components/LoginRegister";
import { useEventTracking } from "../hook";
import { setWordCountLeft } from "../reducers/userSlice";
import { useErrCodeHook } from "../composables/errCodeHook";
import { parseTargetToOrigin, reqBeginnerOutline, reqBeginnerPlanByOutline, reqBeginnerProPlanByOutline, reqBeginnerProPlanFull, reqDetailByIdea } from "../components/createDoc/network";
import {App} from "antd";
import { reqCreateNewBeginnerBp } from "../components/QT32/network";
import { Spin } from "antd";
import { getNewIdFromArray, parseOriginToTarget } from "../components/createDoc/utils";
import LoadingModal from "../components/createDoc/LoadingModal";
import { parseBackSlashNtoBr } from "../utils/parseMsgToHTML";
import { openShop, setRechargeMember } from "../reducers/shopSlice";
import { reqSelectTemplate } from "../page/bp/network";

const qaBeginnerIdeaTips = [
  "在线健康和健身指导平台",
  "可持续生活产品店铺",
  "家庭厨艺分享平台",
  "在线技能培训平台",
  "无人机服务公司",
  "老年人社交俱乐部",
  "可定制的健康食品公司",
  "智能家居解决方案提供商",
  "可持续能源解决方案提供商",
  "可持续旅游体验提供商",
  "医疗大数据分析平台",
  "网络安全咨询公司",
  "医疗健康咨询服务",
  "租借共享服务平台",
]

const MobilePage = () => {
  const {
    history,
    chatId,
    screenStatus,
    template,
    templateDone,
    templateProMiddleDone,
    templatePro,
    templateProDone,
    outline,
    outlineDone,
    continueProPlanFull,
  } = useSelector(s => s.QABeginner);
  const {message} = App.useApp();
  const isAuth = useIsAuthenticated();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {handleErrCode} = useErrCodeHook();
  const {preValidate} = usePreValidationHook();
  const {extraPreValidate} = useExtraPreValidationHook();
  const lastIdeaRef = useRef(history[1].message);
  const [selectedDetailId, setSelectedDetailId] = useState(2);
  const [loginModalToggle, setLoginModalToggle] = useState(false);
  const [authenticated, setAuthenticated] = useState(isAuth());
  const [currentMessage,setCurrentMessage] = useState(history[1].message);
  const percent = template.length * 100 / 2000;
  const [vipIconShow,setVipIconShow] = useState(false);
  const userInfo = useSelector(s=>s.user.userInfo);
  const [isPro,setIsPro] = useState(false)
  useEventTracking(!isAuth());//控制第一次加载是否是在首页

  useEffect(() => {
    return () => {
      // 在组件卸载时清空状态
      dispatch(init());
      setIsPro(false)
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkAuthStatus = () => {
      const authStatus = isAuth();
      setAuthenticated(authStatus);
    };

    checkAuthStatus(); // 初始化时检查认证状态

    const interval = setInterval(checkAuthStatus, 1000); // 每秒检查一次认证状态

    return () => clearInterval(interval);
  }, [isAuth]);

  
  useEffect(()=>{
    if(authenticated){
      reqCreateNewBeginnerBp()
      .then(([code, data]) => {
        handleErrCode(code);
        if (code === 0) {
          const {chat_id: chatId} = data;
          dispatch(openNewQABeginner({chatId:chatId,message:history[1].message}));
          dispatch(setScreenStatus("IDEA"));
        }
      });
    }
    // eslint-disable-next-line
  },[authenticated,dispatch,handleErrCode])
  
  useEffect(() => {
    lastIdeaRef.current = history[1].message;
    // eslint-disable-next-line
  }, [chatId]);

  function handleOnLogin() {
    setLoginModalToggle(false);
    // alert("为了后续体验，请移步pc端")
    // setTimeout(() => {
    //   navigate("/")
     
    // // 延迟得比登录操作还要久，以防跳转到warehouse用过期token访问资源
    // }, 1000);
  }

  useEffect(()=>{
    if(templateDone){
      dispatch(setScreenStatus("PRO"));
      // handleClickCheckTemplatePro();
    }
    // eslint-disable-next-line
  },[templateDone])

  function handleClickStart(e) {
    // e.stopPropagation();
    if (!isAuth()) {
      // e.stopPropagation();
      // e.preventDefault();
      setLoginModalToggle(true);
    } else {
      setLoginModalToggle(false);
      // alert("为了后续体验，请移步pc端")
      // navigate("/");
    }
  }

  function handleMessage(e,i) {
    i?setCurrentMessage(i):setCurrentMessage(e.target.value)
  }
    

  function handleClickIdeaNext(e) {
    e.stopPropagation();
    if (currentMessage.trim() === "") {
      message.info("请输入想法").then();
      return;
    }
    
    if (history[1].message === currentMessage) {
      // 在没有改动的情况下直接到下一步
      dispatch(setScreenStatus("DETAIL"));
      return;
    }
    // 如果有改动，则记录一次Ref并生成3Q
    lastIdeaRef.current = currentMessage;
    dispatch(setScreenStatus("DETAIL"));
    dispatch(setMessage({message: currentMessage, historyIndex: 1}))
    dispatch(setMessageWaiting(true));
    
    dispatch(setMessage({message: "正在生成想法...", historyIndex: 2}));
    dispatch(setMessage({message: "正在生成想法...", historyIndex: 3}));
    dispatch(setMessage({message: "正在生成想法...", historyIndex: 4}));
    reqDetailByIdea(chatId, currentMessage)
      .then(([code, data]) => {
        if(code === 0) {
          const {detail1, detail2, detail3, balance} = data;
          dispatch(setMessage({message: detail1, historyIndex: 2}));
          dispatch(setMessage({message: detail2, historyIndex: 3}));
          dispatch(setMessage({message: detail3, historyIndex: 4}));
          dispatch(setWordCountLeft(balance));
        } else {
          handleErrCode(code);
          dispatch(setScreenStatus("IDEA"));
        }
        dispatch(setMessageWaiting(false));
      })
  }
  
  function adjustHeight(e) {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  // 点击DETAIL页的上一步按钮
  function handleDetailBefore(e) {
    e.stopPropagation();
    dispatch(setScreenStatus("IDEA"));
  }

  function handleOutlineBefore(e) {
    e.stopPropagation();
    dispatch(setScreenStatus("DETAIL"));
  }

  async function handleClickDetailNext(e) {
    e.stopPropagation();
    const ok = await preValidate(`POST /chat/generateProjectPlanOutline/`, {
      change_entrepreneurial_ideas: history[selectedDetailId].message,
      chat_id: chatId,
      uuid: chatId,});
    if (!ok) return;
    dispatch(setScreenStatus("OUTLINE"));
    dispatch(setOutlineDone(false))
    reqBeginnerOutline(chatId, history[selectedDetailId].message)
      .then(([code, outline]) => {
        handleErrCode(code);
        if(code === 0) {
          if(Array.isArray(outline)) {
            dispatch(setOutline(parseOriginToTarget(outline)))
          } else {
            // TODO 应当加入埋点
            console.error("outline's type isn't Array", outline);
          }
          dispatch(setOutlineDone(true));
        } else {
          dispatch(setScreenStatus("DETAIL"));
        }
      })
      .finally(()=>{dispatch(setOutlineDone(true))})
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.droppableId === "bigTitle") {
      console.log("h1", result);
      const items = Array.from(outline);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      dispatch(setOutline(items));
    }
    else if (/subTitle/.test(result.destination.droppableId)) {
      console.log("h2", result)
      const [,h1Index] = result.destination.droppableId.split("-");
      const fromH2Id = result.source.index;
      const toH2Id = result.destination.index;
      dispatch(swapOutlineH2([h1Index, fromH2Id, toH2Id]))
    }
  }

  async function handleClickOutlineNext(e) {
    e.stopPropagation();
    const ok = await preValidate(`POST /chat/generateTemplateFromOutline/`, {
      outline_content: parseTargetToOrigin(outline),
      chat_id: chatId,
      uuid: chatId,
    });
    if(!ok) return;

    dispatch(setScreenStatus("GEN"));
    const [code] = await reqBeginnerPlanByOutline(chatId, outline);
    handleErrCode(code);
    if (code === 0) {
      dispatch(setTemplateDone(true));
    } else {
      dispatch(setScreenStatus("OUTLINE"));
    }
  }

  async function handleClickCheckTemplatePro(e) {
    // e.stopPropagation();

    // 默认选择初版
    // const [code1] = await reqSelectTemplate(chatId, template);
    // handleErrCode(code1);

    const ok = await preValidate(`POST /chat/smartVersionFillContent/`, {
      chat_id: chatId,
      uuid: chatId,
      version: "1",
    })
    if (!ok) return;
    const [code] = await reqBeginnerProPlanByOutline(chatId);
    handleErrCode(code);
    if (code === 0) {
      repairPermissions(true);
    }
  }

  async function repairPermissions(continueProPlanFull){
    const isVip = await extraPreValidate(`POST /chat/smartFillSurplusContent/`, {
      chat_id: chatId,
      version: "1",
      uuid: chatId,
    })
    dispatch(setContinueProPlanFull({continueProPlanFull}))
    if (!isVip){
      setVipIconShow(true);
      dispatch(setTemplateProDone(true));
    }else{
      setVipIconShow(false);
      dispatch(setTemplateProMiddleDone(true));//若有权限，直接接第二个接口内容
    }
  }

  useEffect(()=>{
    if(continueProPlanFull){
      repairPermissions(continueProPlanFull);
    }
    // eslint-disable-next-line
  },[userInfo])//无权限充值会员后,重新验证接口是否可继续填充内容

  async function handleContinueProPlanFull(){
    dispatch(setTemplateProDone(false));
    const [code] = await reqBeginnerProPlanFull(chatId);
    handleErrCode(code);
    dispatch(setTemplateProDone(true));
    dispatch(setTemplateProMiddleDone(false));
}

  useEffect(()=>{
    if(continueProPlanFull){
      if(templateProMiddleDone){
        handleContinueProPlanFull()
      }
    }
    // eslint-disable-next-line
  },[continueProPlanFull,templateProMiddleDone])

  return (
    <div className="mobile-page">
      <WorkHeader backgroundColor={"linear-gradient(0deg, #e5edfb, #d5edfd)"} handleClickStart={handleClickStart} modalType={"mobile"}></WorkHeader>
      {loginModalToggle &&
          <LoginRegister onLogin={handleOnLogin}
                         onClickClose={()=>{setLoginModalToggle(false)}}
                         modalType={"mobile"}
          />
      }
      {(screenStatus === "IDEA"||screenStatus === "")&&
        <>
          <div className="mobile-page-banner">
          <h1>创意转为商业计划</h1>
          <h2>量身定制吸引投资人</h2>
          <hr />
          <p>一个想法、一键生成商业计划书与PPT</p>
          <p>行业领先的AI智能交互</p>
          </div>
          <div className="quick-create">
          {/* <QABeginner/> */}
          <main className={"qa-beginner-idea-main"}>
              <div className={"user-icon"}>
                <img alt={""} src={"/icons/common/ai_wly_icon.svg"}/>
              </div>
              <div className={"main-content"}>
                您可以在下方输入你想要的主题，若能补充行业、用途等信息，智能生成的大纲内容会更丰富哦！
                <div className={"input-container"}>
                  <input placeholder={"试试“我想开一家咖啡店”"}
                         value={currentMessage}
                         className={"qa-beginner-textarea1"}
                         onChange={e => handleMessage(e)}
                  />
                  <img alt="" src={"/icons/bp_sider/send.svg"} onClick={authenticated?handleClickIdeaNext:handleClickStart}/>
                </div>
                <div className={"tips-btn-group"}>
                  {
                    qaBeginnerIdeaTips.map(i=><button
                      key={i}
                      onClick={(e)=>handleMessage(e,i)}
                    >{i}</button>)
                  }
                </div>
              </div>
          </main>
          <footer style={{textAlign: "center"}}>
            <button
              disabled={currentMessage === ""}
              onClick={authenticated?handleClickIdeaNext:handleClickStart}
            >下一步
            </button>
          </footer>
          </div>
        </>
      }
      {
        screenStatus === "DETAIL"&&
        <div className="quick-create">
          <main className={"qa-beginner-detail-main"}>
            <div className={"user-icon"}>
                <img alt={""} src={"/icons/common/ai_wly_icon.svg"}/>
            </div>
            <div className={"main-content"}>
                <h6>请选择与您认为一致的选项</h6>
                {history[2].waiting && <Spin/>}
                <div className={selectedDetailId === 2 ?"qa-beginner-detail-item selected":"qa-beginner-detail-item"}
                     onClick={() => setSelectedDetailId(2)}
                >
                  <img alt={""} src={"/icons/warehouse/edit_pencil_blue.svg"} className={"pencil"}/>
                  <textarea
                    value={history[2].message}
                    onChange={e=>{
                        dispatch(setMessage({message: e.target.value,historyIndex: 2,}));
                        adjustHeight(e);
                      }
                    }
                    maxLength={500}
                  />
                  <img alt={""} src={"/images/warehouse/correct.png"}/>
                </div>
                <div className={selectedDetailId === 3 ?"qa-beginner-detail-item selected":"qa-beginner-detail-item"}
                     onClick={() => setSelectedDetailId(3)}
                >
                  <img alt={""} src={"/icons/warehouse/edit_pencil_blue.svg"} className={"pencil"}/>
                  <textarea
                    value={history[3].message}
                    onChange={e=>{
                      dispatch(setMessage({message: e.target.value,historyIndex: 3,}));
                      adjustHeight(e);
                      }
                    }
                    maxLength={500}
                  />
                  <img alt={""} src={"/images/warehouse/correct.png"}/>
                </div>
                <div  className={selectedDetailId === 4 ?"qa-beginner-detail-item selected":"qa-beginner-detail-item"}
                      onClick={() => setSelectedDetailId(4)}
                >
                  <img alt={""} src={"/icons/warehouse/edit_pencil_blue.svg"} className={"pencil"}/>
                  <textarea
                    value={history[4].message}
                    onChange={e=>{
                      dispatch(setMessage({message: e.target.value,historyIndex: 4,}));
                      adjustHeight(e);
                      }
                    }
                    maxLength={500}
                  />
                  <img alt={""} src={"/images/warehouse/correct.png"}/>
                </div>
            </div>
          </main>
          <footer className={"qa-beginner-detail-footer"}>
            <button
              disabled={false}
              onClick={handleDetailBefore}
            >上一步
            </button>
            <button
              disabled={history[selectedDetailId].message===""}
              onClick={handleClickDetailNext}
            >下一步
            </button>
          </footer>
        </div>
      }
      {
        screenStatus === "OUTLINE"&&
        <div className="quick-create">
          <main className={"qa-beginner-outline-main"}>
              <div className={"user-icon"}>
                <img alt={""} src={"/icons/common/ai_wly_icon.svg"}/>
              </div>
              <div className={"main-content"}>
                调整大纲
              {
                outlineDone
                  ? <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="bigTitle">
                      {
                        (provided) => (
                          <ol {...provided.droppableProps} ref={provided.innerRef}>
                            {outline.map(({title, id, children}, h1Index) => {
                              return <Draggable key={id} draggableId={id} index={h1Index}>
                                {
                                  (provided) => (
                                    <li data-id={id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                      <input value={title}
                                             onChange={e=>{
                                               dispatch(setOutlineH1([h1Index, e.target.value]))
                                             }}
                                             onBlur={()=>{
                                               if(title === "") {
                                                 dispatch(deleteOutlineH1([h1Index]))
                                               }
                                             }}
                                      />
                                      {/*<img className={"drag-handle"} {...provided.dragHandleProps} src={"/icons/drag_handle.svg"} alt={""}/>*/}
                                      <span className={"drag-handle"} {...provided.dragHandleProps}>
                                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M2.02412 0.233015C1.44422 0.233015 0.974121 0.726629 0.974121 1.33553C0.974121 1.94444 1.44422 2.43805 2.02412 2.43805L20.9241 2.43805C21.504 2.43805 21.9741 1.94443 21.9741 1.33553C21.9741 0.726628 21.504 0.233014 20.9241 0.233014L2.02412 0.233015Z" fill="#BFBFBF"/>
                                          <path d="M2.02412 5.82237C1.44422 5.82237 0.974121 6.31598 0.974121 6.92489C0.974121 7.53379 1.44422 8.0274 2.02412 8.0274L20.9241 8.0274C21.504 8.0274 21.9741 7.53379 21.9741 6.92489C21.9741 6.31598 21.504 5.82237 20.9241 5.82237L2.02412 5.82237Z" fill="#BFBFBF"/>
                                          <path d="M2.02412 11.4117C1.44422 11.4117 0.974121 11.9053 0.974121 12.5142C0.974121 13.1231 1.44422 13.6168 2.02412 13.6168L20.9241 13.6168C21.504 13.6168 21.9741 13.1231 21.9741 12.5142C21.9741 11.9053 21.504 11.4117 20.9241 11.4117L2.02412 11.4117Z" fill="#BFBFBF"/>
                                        </svg>
                                      </span>
                                      <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId={`subTitle-${h1Index}`}>
                                          {(provided) => (
                                            <ul {...provided.droppableProps} ref={provided.innerRef}>
                                              {children.map(({title, id}, h2Index) => (
                                                <Draggable key={id} draggableId={id} index={h2Index}>
                                                  {(provided) =>
                                                    <li ref={provided.innerRef}
                                                        {...provided.draggableProps}>
                                                      <input value={title}
                                                             onChange={e=>{
                                                               dispatch(setOutlineH2([h1Index, h2Index, e.target.value]))
                                                             }}
                                                             onBlur={()=>{
                                                               if(title === "") {
                                                                 dispatch(deleteOutlineH2([h1Index, h2Index]))
                                                               }
                                                             }}
                                                      />
                                                      <span className={"drag-small-handle"} {...provided.dragHandleProps}>
                                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M1.03788 0.422956C0.642927 0.422956 0.322754 0.91657 0.322754 1.52547C0.322754 2.13438 0.642927 2.62799 1.03788 2.62799L13.9102 2.62799C14.3051 2.62799 14.6253 2.13438 14.6253 1.52547C14.6253 0.91657 14.3051 0.422956 13.9102 0.422956L1.03788 0.422956Z" fill="#BFBFBF"/>
                                                          <path d="M1.03788 6.01231C0.642927 6.01231 0.322754 6.50593 0.322754 7.11483C0.322754 7.72373 0.642927 8.21735 1.03788 8.21735L13.9102 8.21735C14.3051 8.21735 14.6253 7.72373 14.6253 7.11483C14.6253 6.50593 14.3051 6.01231 13.9102 6.01231L1.03788 6.01231Z" fill="#BFBFBF"/>
                                                          <path d="M1.03788 11.6017C0.642927 11.6017 0.322754 12.0953 0.322754 12.7042C0.322754 13.3131 0.642927 13.8067 1.03788 13.8067L13.9102 13.8067C14.3051 13.8067 14.6253 13.3131 14.6253 12.7042C14.6253 12.0953 14.3051 11.6017 13.9102 11.6017L1.03788 11.6017Z" fill="#BFBFBF"/>
                                                        </svg>
                                                      </span>
                                                      {/*<img src={"/icons/drag_handle.svg"} alt={""}/>*/}
                                                    </li>}
                                                </Draggable>
                                              ))}
                                              {provided.placeholder}
                                              <button
                                                title={"新增小标题"}
                                                onClick={() => {
                                                  console.log(outline, outline[0]);
                                                  dispatch(pushOutlineH2([h1Index, "新增小标题"]))
                                                }}
                                              >+</button>
                                            </ul>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </li>)
                                }
                              </Draggable>
                            })}
                            {provided.placeholder}
                            <button title="新增大标题"
                                    onClick={() => {
                                      const newOL = (outline => {
                                        let newOutline = outline.slice();
                                        newOutline.push({
                                          id: getNewIdFromArray(newOutline),
                                          title: "新增大标题",
                                          children: []
                                        });
                                        return newOutline;
                                      })(outline);
                                      dispatch(setOutline(newOL));
                                    }}>+
                            </button>
                          </ol>
                        )
                      }
                    </Droppable>
                  </DragDropContext>
                  : <div style={{textAlign: "center"}}>"正在生成大纲"<Spin/></div>
              }
              </div>
            </main>
            <footer className={"qa-beginner-outline-footer"}>
              <button
                disabled={false}
                onClick={handleOutlineBefore}
              >上一步
              </button>
              <button
                disabled={!outlineDone}
                onClick={handleClickOutlineNext}
              >下一步
              </button>
            </footer>
        </div>
      }
      {
        screenStatus === "GEN"&&
        <div className="quick-create">
          <LoadingModal
            modalType={"mobile"}
            mainContent={templateDone ? <p>生成完成</p> : <p>正在生成商业计划书</p>}
                      percent={templateDone
                        ? 100
                        : percent <= 98
                          ? percent
                          : 98}
                      footer={

                          <>
                            {/* <button
                              disabled={!templateDone}
                              onClick={handleClickCheckTemplate}
                            >进入文档
                            </button>
                            <button
                              className={"gen-pro-button"}
                              disabled={!templateDone}
                              onClick={handleClickCheckTemplatePro}
                            >Pro升级
                              <div>
                                AI自动为您填写正文内容<br/>
                                实现更智能的内容生成
                              </div>
                            </button> */}
                          </>
            }
          >
          </LoadingModal>
        </div>
      }
      {
        screenStatus === "PRO"&&
        <div className="quick-create">
           <main className={"qa-beginner-pro-main"}>
              <h3>基础版</h3>
              <div className={"doc"}>
                <p dangerouslySetInnerHTML={{__html: parseBackSlashNtoBr(template)}}/>
              </div>
              <button
                className={"normal"}
                disabled={!templateProDone && isPro}
                onClick={async () => {
                  const [code] = await reqSelectTemplate(chatId, "1");
                  handleErrCode(code);
                  if (code === 0) navigate(`/bp/${chatId}`)
                }}
              >选择该版本
              </button>
              <h3>Pro版
                <p>
                  AI自动为您丰富正文内容<br/>
                  实现更智能的内容生成
                </p>
              </h3>
             
              <div className={"doc"}>
                <p dangerouslySetInnerHTML={{__html: parseBackSlashNtoBr(templatePro)}}/>
                {!templateProDone&&isPro&&<div className={"spin-container"}><Spin/></div>}
                {vipIconShow&&<div className={"vip-lock-mask"} >
                  <img alt="" src={"/images/vip_pro_doc_lock.png"} onClick={e=>{e.stopPropagation();dispatch(setRechargeMember(true));dispatch(openShop());}}/>
                </div>}
              </div>
              {isPro
              ? (templateProDone? <button
                  className={"pro"}
                  disabled={!templateProDone}
                  onClick={async () => {
                    if(vipIconShow){
                      dispatch(setRechargeMember(true));
                      dispatch(openShop());
                      return;
                    }
                    const [code] = await reqSelectTemplate(chatId, "-1");
                    handleErrCode(code);
                    if (code === 0) navigate(`/bp/${chatId}`)
                  }}
                >选择该版本
                </button>
                :<button
                    className={"pro"}
                    disabled={!templateProDone}
                  >正在生成中
                  </button>
                )
              : <button
                  className={"normal"}
                  onClick={()=>{setIsPro(true);handleClickCheckTemplatePro()}}
                >Pro升级
                </button>
              }
            </main>
        </div>
      }
    </div>
  )
}

export default MobilePage;