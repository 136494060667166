const handleOptions = (options) => {
  const choiceOptions = [];
  const choiceColorOptions = [];
  const textOptions = [];
  const timeOptions = [];
  const dropdownOptions = [];
  const compositeOptions = [];
  const provinceCityOptions = [];
  const addQuestionOptions = [];
  const affixedTextOptions = [];
  const tableOptions = [];
  const textMixButtonOptions = [];
  const buttonMixDropDownOptions = [];
  const buttonMixTimeOptions= [];
  options?.forEach((option,index) => {
    if(option?.option_type === "CHOICE"){
      choiceOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "CHOICE_COLOR"){
      choiceColorOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "TEXT") {
      textOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "TIME") {
      timeOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "DROPDOWN_ADD_CHOICE" || option?.option_type === "DROPDOWN_INPUT") {
      dropdownOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "COMPOSITE") {
      compositeOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "PROVINCE_CITY_DISTRICT") {
      provinceCityOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "ADD_QUESTION") {
      addQuestionOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "AFFIXED_TEXT"){
      affixedTextOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "TABLE"){
      tableOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "TEXT_MIXTURE_BUTTON"){
      textMixButtonOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "BUTTON_AND_DROPDOWN"){
      buttonMixDropDownOptions.push({...option,"option_index":index})
    }
    if(option?.option_type === "BUTTON_AND_TIME"){
      buttonMixTimeOptions.push({...option,"option_index":index})
    }
  })
  return {
    choiceOptions:choiceOptions,
    choiceColorOptions:choiceColorOptions,
    textOptions:textOptions,
    timeOptions:timeOptions,
    affixedTextOptions:affixedTextOptions,
    tableOptions:tableOptions,
    dropdownOptions:dropdownOptions,
    compositeOptions:compositeOptions,
    provinceCityOptions:provinceCityOptions,
    addQuestionOptions:addQuestionOptions,
    textMixButtonOptions:textMixButtonOptions,
    buttonMixDropDownOptions:buttonMixDropDownOptions,
    buttonMixTimeOptions:buttonMixTimeOptions
  }
}

export const handleQaoData = (data,questionId) => [
  {
    questionId:questionId,
    questionCode:data.question_code,
    questionType:data.question_type,
    question:data.text,
    description:data.description,
    options:handleOptions(data.options_list),
    choiceIndexes:data.choice_indexes,
    maxChoice:data.max_choice,
    minChoice:data.min_choice,
    skippable:data.skippable,
    skipped:data.skipped,
    canAddOption:data.can_add_option,
    canAddQuestion:data.can_add_question,
    canAiGenerateOption:data.can_ai_generate_option,
    canDelete:data.can_delete,
    availableConditions:data.available_conditions,
    dynamicOptions: data.dynamic_options
  }
]

//计算里层对象个数
export const countInnermostObjects = (obj) => {
  let count = 0;

  function recursiveCount(value) {
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (Array.isArray(item)) {
          recursiveCount(item);
        } else if (typeof item === 'object' && item !== null) {
          count++;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.values(value).forEach(val => recursiveCount(val));
    }
  }

  recursiveCount(obj);
  return count;
}

/*对需要提交的choiceIndexes做处理 */
export const handleSubmitChoiceList = ({reqValue,optionType,updatedQaoDatasOptions,qaoDatas,questionId,isTableShow}) => {
  const currentQaoData = qaoDatas.find(item => item.questionId === questionId);
  const currentChoiceIndexes = currentQaoData.choiceIndexes;

  let combinedArray = [];
  //对CHOICE选项做处理
  const currentChoiceOptionsIndexes = currentQaoData.options.choiceOptions.map(({option_index}) => option_index);
  const isNotChoiceIndexes = currentChoiceIndexes.filter(item=> !currentChoiceOptionsIndexes.includes(item));
  if(optionType === "CHOICE") {
    combinedArray = [...new Set(reqValue.concat(isNotChoiceIndexes))];
  }

  //对CHOICE_COLOR选项做处理
  const currentChoiceColorOptionsIndexes = currentQaoData.options.choiceColorOptions.map(({option_index}) => option_index);
  const isNotChoiceColorIndexes = currentChoiceIndexes.filter(item=> !currentChoiceColorOptionsIndexes.includes(item));

  if(optionType === "CHOICE_COLOR") {
    combinedArray = [...new Set(reqValue.concat(isNotChoiceColorIndexes))];
  }

  //对TEXT选项做处理
  if(optionType === "TEXT") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
    })

    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }

  //对COMPOSITE选项做处理
  if(optionType === "COMPOSITE") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
    })

    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }

  //对AFFIXED_TEXT选项做处理
  if(optionType === "AFFIXED_TEXT") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      if(isTableShow !== undefined){
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""&&!isTableShow
      }else{
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
      }
      
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      if(isTableShow !== undefined){
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""|| isTableShow
      }else{
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
      } 
      
    })

    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    if(isTableShow !== undefined && !isTableShow) {
      const currentTableOptionsIndex = currentQaoData.options.tableOptions.map(({option_index})=>option_index);
      filteredChoiceIndexes = filteredChoiceIndexes.filter(item=>!currentTableOptionsIndex.includes(item))
    }
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }

  //对TABLE选项做处理
  if(optionType === "TABLE") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      if(isTableShow !== undefined){
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""&&isTableShow
      }else{
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
      }
    })
    
    const currentNoTextIndexes =reqValue.filter((item)=>{
      if(isTableShow !== undefined){
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""|| !isTableShow
      }else{
        return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
      } 
    })

    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    if(isTableShow !== undefined && isTableShow) {
      const currentTableOptionsIndex = currentQaoData.options.affixedTextOptions.map(({option_index})=>option_index);
      filteredChoiceIndexes = filteredChoiceIndexes.filter(item=>!currentTableOptionsIndex.includes(item))
    }
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
    
  }

  //对省市区组件做处理
  if(optionType === "PROVINCE_CITY_DISTRICT") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
    })

    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }

  //对BUTTON_AND_TIME选项做处理
  if(optionType === "BUTTON_AND_TIME") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
    })
    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }

  //对TIME选项做处理
  if(optionType === "TIME") {
    const currentHasTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text !== ""
    })
    const currentNoTextIndexes = reqValue.filter((item)=>{
      return updatedQaoDatasOptions.find(({option_index})=>option_index===item).text === ""
    })
    let filteredChoiceIndexes = currentChoiceIndexes.filter(item => !currentNoTextIndexes.includes(item));
    combinedArray = [...new Set([...filteredChoiceIndexes, ...currentHasTextIndexes])];
  }
  
  return combinedArray
} 

export function numberToChinese(number) {
  const units = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const positions = ['', '十', '百', '千'];
  let chineseRepresentation = '';

  if (number === 0) {
    return units[0];
  }

  for (let i = 0; number > 0; i++) {
    const mod = number % 10;
    if (mod !== 0) {
      chineseRepresentation = units[mod] + positions[i] + chineseRepresentation;
    } else if (!chineseRepresentation.startsWith(units[0])) {
      chineseRepresentation = units[mod] + chineseRepresentation;
    }
    number = Math.floor(number / 10);
  }

  chineseRepresentation = chineseRepresentation.replace('一十', '十'); // 特殊规则：10 应该表示为 十 而不是 一十
  return chineseRepresentation;
}

export function handleCompositeQaoDatas({qaoDatas,questionId,dropdownList,optionIndex,optionType,selectedValue,currentIndex,dropdownListText,dropdownListIndex}) {
  let currentDropdownList = []
   
  if(dropdownListText&&typeof dropdownListIndex === "number"){//处理最里层dropdown_list
    currentDropdownList = dropdownList.map((item, idx) => {
      if (idx === currentIndex) {
        if(item.dropdown_list.hasOwnProperty("left")){
          const currentInsideDropdownList = {...item.dropdown_list,text:dropdownListText}
          return {...item, "text": selectedValue,"dropdown_list":currentInsideDropdownList}
        }else{
         const currentInsideDropdownList = [...item.dropdown_list]
         const changeInsideDropdownList=currentInsideDropdownList.map((item, index)=>{
           if (index === dropdownListIndex){
             return { ...item, text: dropdownListText }
           }
           return {...item}
         })
       
         return {...item, "text": selectedValue,"dropdown_list":changeInsideDropdownList}
        }
      }
      return item; 
    })
  }else{
    currentDropdownList = dropdownList.map((item, idx) => {
      if (idx === currentIndex) {
        return { ...item, text: selectedValue }; 
      }
      return item; 
    });
  }
  const concatenatedText = currentDropdownList.map(item => item.text).join("");
  const currentData = qaoDatas.find((item=>item.questionId === questionId)).options.compositeOptions[optionIndex]
  const updatedOption = {
    "text": concatenatedText, 
    "option_type": optionType,
    "description": currentData?.description,
    "source_type": currentData?.source_type,
    "dropdown_list":currentDropdownList,
    "option_index": optionIndex,
    "dynamic_id":(currentData?.dynamic_id)?(currentData?.dynamic_id):null
  }

  const updateList = [
    ...qaoDatas.find((item=>item.questionId === questionId)).options.compositeOptions.filter(opt => opt.option_index !== optionIndex),
    updatedOption
  ].sort((a, b) => a.option_index - b.option_index);

  let dynamicOptionAdd
  if(dropdownListText&&typeof dropdownListIndex === "number"){
    dynamicOptionAdd = qaoDatas.find((item=>item.questionId === questionId)).options.compositeOptions.map((item,index)=> {
      if(optionIndex===index){
        return {...updatedOption}
      }else{
        return item
      }
    })
  }

  return {dynamicOptionAdd,updateList,currentData}
}

export function handleCurrentCardIsEdit(canCardToEdit, cI, chapters){
  if ( chapters === undefined ){
    return false
  }
  if (cI === 0) {
    // 检查每个章节是否至少完成了一半的sections（不包括第一个章节）
    const halfDone = chapters.slice(1).map(({ sections }) => {
      const completedSections = sections.filter(s => s.status !== "NOT_STARTED").length;
      return completedSections >= Math.ceil(sections.length / 2);
    });
    // 只有所有章节都符合条件时返回true
    return halfDone.every(status => status);
  }

  // 映射表，用于存储每个cI依赖的章节
  const dependencies = {3:[1, 2], 4:[1,2], 5:[1,2], 6:[5]};
  // 基于依赖映射表进行检查
  if (canCardToEdit.length !== 0) {
    const requiredDependencies = dependencies[cI] || []; // 获取当前cI所依赖的章节

    // 遍历所有依赖章节，确保它们都已完成
    for (let i = 0; i < requiredDependencies.length; i++) {
      if (canCardToEdit[requiredDependencies[i]] === false) {
        return false
      }
    }
    return true;
  }

  return false;
};

// 将章节内容处理成pdf所需样式
export const generatePDFContent = (chapters,safeValue,doc) => {
  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document Title</title>
    <style>
    * {
        box-sizing: border-box;
    }
    @page {
      size: A4;
      margin: 0; /* 取消默认页边距 */
      padding: 0; /* 取消默认页边距 */
    }
    @page toc {
      margin: 22mm 0; /* 设置 toc-page 的页边距 */
    }

    @page container {
      margin: calc(22mm - 32px) 0;
    }

    /* 应用于 toc-page */
    .toc-page {
      page: toc;
    }

    .container {
      page: container;
    }
    
    html,body {
      font-family: 'PingFang SC', sans-serif;
      margin: 0;
      padding: 0;
      background-color: #fff;
      height: calc(100vh * 1.5);
      width:calc(100vw * 1.5);
      color: #242625;
    }

    .title-page, .toc-page, .content {
      page-break-after: always;
      height:100%;
      width: 100%;
    }

    .chapter-page {
      page-break-before: always;
      page-break-after: always;
      width: 100%;
      height:100%;
      background: #0A7BFF;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      letter-spacing: 8px;
  }
    
    h1, h2, h3, h4 {
        margin: 0.5em 0;
    }
    
    table {
        width: 100%;
        border-collapse: collapse;
    }
    
    th, td {
        border: 1px solid #000;
        padding: 5px;
    }
    
    .container {
        page-break-inside: avoid;
        page-break-before: always; 
        position: relative;
        counter-increment: page; 
    }

    .container::before {
        content: counter(page);
        position: absolute;
        top: 0;
        left: 22mm;
        font-size: 12px; 
        font-weight: 700;
        text-align: center;
        line-height: 32px;
        height: 32px;
        width: 32px;
        color: #fff;
        background-color: #0A7BFF; 
    }
    .container::after {
        content: "";
        position: absolute;
        top: 32px;
        left: 22mm;
        font-size: 12px; 
        width: calc(100% - 22mm * 2);
        border-bottom: #0A7BFF 1px solid;
    }
    
    .container h2 {
      line-height: 120px;
      color: #fff;
      letter-spacing: 3px;
      padding-top: 100px;
      padding-bottom: 50px;
    }
    .container h2 div {
        display: flex;
        padding: 0 80px;
        font-size: 38px;

        background: #0A7BFF;
    }
    .container h2 div span {
        margin-left: auto;
        letter-spacing: 0px;
    }
    
    .chapter-page h1 {
        font-size: 110px;
        line-height: 90px;
    }
    
    .container .section-content {
        padding: 0 22mm;
        letter-spacing: 1px;
        line-height: 30px;
    }
    
    .title-page {
        display: flex;
        justify-content: center;
        /* background: linear-gradient(20deg,#e1e5fe,#cad7fa,#d4e4fa,#b5dafe,#e0f0fd); */
    }

    .title-page svg {
      position: absolute;
      width: calc(100vw * 1.5);
      top: 0;
      left:0
    } 
    
    .title-page h1 {
        display: flex;
        justify-content: center;
        position: relative;
        align-self: center;
        font-size: 110px;
        text-align: center;
        letter-spacing: 8px;
    }
    
    .title-page h1::after {
        position: absolute;
        content: "Business Plan";
        bottom: -80px;
        font-size: 58px;
        white-space: nowrap;
        letter-spacing: 0.6px;
        color: #0A7BFF;
        font-weight: 500;
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
    
    .toc-page {
        padding: 22mm;
        height: auto;
    }
    
    .toc-page .catalog-list {
        margin: 20px 0 48px 0;
    }
    
    .toc-page h1 {
        font-size: 48px;
        margin: 52px 0;
    }
    
    .toc-page h2 {
        position: relative;
        font-size: 32px;
        line-height: 52px;
    }
    
    .toc-page h2::before {
        position: absolute;
        content: "";
        width: 98px;
        height: 5px;
        background: #0A7BFF;
        top: -5px;
        left: 0px;
    }
    
    .toc-page h3{
      border-top: 1px solid #CFCFCF;
      line-height: 50px;
      height: 40px;
      width: 100%;
    }
    .toc-page h4 {
        width: 100%;
        font-size: 16px;
        color: #3a343d;
        font-weight: 600;
    }
    
    .toc-page h4 span {
        line-height: 16px;
        color: #0A7BFF;
        margin: 0 20px;
    }
    
    .toc-page h3 span {
        color: #0A7BFF;
        margin-right: 20px;
    }
</style>
  </head>
  <body>
    <!-- Title Page -->
    <div class="title-page">
      <svg id="wave" style="transform:rotate(180deg); transition: 0.3s" viewBox="0 0 1440 490" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(10, 123, 255, 1)" offset="0%"></stop><stop stop-color="rgba(149.617, 186.835, 230.311, 1)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,245L34.3,228.7C68.6,212,137,180,206,196C274.3,212,343,278,411,253.2C480,229,549,114,617,98C685.7,82,754,163,823,220.5C891.4,278,960,310,1029,269.5C1097.1,229,1166,114,1234,89.8C1302.9,65,1371,131,1440,155.2C1508.6,180,1577,163,1646,179.7C1714.3,196,1783,245,1851,236.8C1920,229,1989,163,2057,138.8C2125.7,114,2194,131,2263,130.7C2331.4,131,2400,114,2469,106.2C2537.1,98,2606,98,2674,155.2C2742.9,212,2811,327,2880,367.5C2948.6,408,3017,376,3086,351.2C3154.3,327,3223,310,3291,318.5C3360,327,3429,359,3497,326.7C3565.7,294,3634,196,3703,187.8C3771.4,180,3840,261,3909,253.2C3977.1,245,4046,147,4114,89.8C4182.9,33,4251,16,4320,8.2C4388.6,0,4457,0,4526,73.5C4594.3,147,4663,294,4731,367.5C4800,441,4869,441,4903,441L4937.1,441L4937.1,490L4902.9,490C4868.6,490,4800,490,4731,490C4662.9,490,4594,490,4526,490C4457.1,490,4389,490,4320,490C4251.4,490,4183,490,4114,490C4045.7,490,3977,490,3909,490C3840,490,3771,490,3703,490C3634.3,490,3566,490,3497,490C3428.6,490,3360,490,3291,490C3222.9,490,3154,490,3086,490C3017.1,490,2949,490,2880,490C2811.4,490,2743,490,2674,490C2605.7,490,2537,490,2469,490C2400,490,2331,490,2263,490C2194.3,490,2126,490,2057,490C1988.6,490,1920,490,1851,490C1782.9,490,1714,490,1646,490C1577.1,490,1509,490,1440,490C1371.4,490,1303,490,1234,490C1165.7,490,1097,490,1029,490C960,490,891,490,823,490C754.3,490,686,490,617,490C548.6,490,480,490,411,490C342.9,490,274,490,206,490C137.1,490,69,490,34,490L0,490Z"></path></svg>
      <h1>${doc.docName}</h1>
    </div>

    <!-- Table of Contents Page -->
    <div class="toc-page">
      <h1>目录</h1>
      ${chapters.map(chapter => `
        <h2>第${safeValue(numberToChinese(chapter.chapterId + 1))}章 | ${safeValue(chapter.chapterName)}</h2>
        ${chapter.sections.filter(({ sectionId }) => sectionId !== 0).map(({ sectionId, sectionName,subSections}) => `
        <div class="catalog-list">
          <h3><span>${safeValue(chapter.chapterId + 1)}.${safeValue(sectionId)}</span> ${safeValue(sectionName)}</h3>
          ${subSections.map(({ subSectionId, subSectionName }) => `
            <h4><span>${safeValue(chapter.chapterId + 1)}.${safeValue(sectionId)}.${safeValue(subSectionId)}</span> ${safeValue(subSectionName)}</h4>
          `).join('')}
          </div>`).join('')}
      `).join('')}
    </div>

    <!-- Content Pages -->
    <div class="content">
      ${chapters.map(chapter => `
        <div class="chapter-page"><h1>${safeValue(chapter.chapterName)}</h1><h2>第${safeValue(numberToChinese(chapter.chapterId + 1))}章</h2></div>
          ${chapter.sections.filter(({ sectionId }) => sectionId !== 0).map(({ sectionId, sectionName, subSections }) => `
          <div class="container">
            <h2><div>${safeValue(sectionName)}<span>${safeValue(chapter.chapterId + 1)}.${safeValue(sectionId)}</span></div></h2>
            ${subSections.map(({ subSectionId, subSectionName, paragraphs }) => `
            <div class="section-content">
              <h3>${safeValue(chapter.chapterId + 1)}.${safeValue(sectionId)}.${safeValue(subSectionId)} ${safeValue(subSectionName)}</h3>
              ${paragraphs.map((paragraph) => {
                let contentArray = [];
                let htmlContent = "";
                if (typeof paragraph.paragraphContent.slateContent === "string") {
                  contentArray = safeValue(paragraph.paragraphContent.slateContent).split('\n');
                } else if (paragraph.paragraphContent.slateContent) {
                  contentArray = safeValue(paragraph.paragraphContent.slateContent[0].children[0].text).split('\n');
                }
                if (paragraph.paragraphContent.tableContent && paragraph.paragraphContent.tableContent.headers.length > 1 && paragraph.paragraphContent.tableContent.rows.length > 1) {
                  const tableHeaders = paragraph.paragraphContent.tableContent.headers;
                  const tableRows = paragraph.paragraphContent.tableContent.rows;
                  const tableTitle = safeValue(paragraph.paragraphContent.tableContent.title);
                  htmlContent = `<h4>${tableTitle}</h4>`;
                  htmlContent += `<table><thead><tr>${tableHeaders.map(header => `<th colSpan="${safeValue(header.colspan)}">${safeValue(header.content)}</th>`).join('')}</tr></thead><tbody>`;
                  tableRows.forEach(row => {
                    htmlContent += `<tr>${row.map(cell => `<td colSpan="${safeValue(cell.colspan)}" rowspan="${safeValue(cell.rowspan)}">${safeValue(cell.content)}</td>`).join('')}</tr>`;
                  });
                  htmlContent += `</tbody></table>`;
                } else {
                  htmlContent = contentArray.map((line) => `<p>${safeValue(line)}</p>`).join('');
                }
                return htmlContent;
              }).join('')}
         </div>`).join('')}
        </div>`).join('')}
      `).join('')}
    </div>
   
  </body>
  </html>
  `;
};
