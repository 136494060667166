import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function AnalyzeWrapper({children}) {
  const location = useLocation();

  useEffect(() => {
    // `_hmt`是百度统计的全局对象
    if (window._hmt) {
      // 使用当前路径作为页面访问的路径
      const currentPath = location.pathname + location.search;
      // 调用百度统计API进行页面访问统计
      window._hmt.push(['_trackPageview', currentPath]);
      console.log("track");
    }
  }, [location]);
  return <>{children}</>
}