import { fontList } from "./data";

const FontList = () => {
  return (
    <div className="font-list ">
      <header>
        <span><img src="/icons/recharge/free_version.svg" alt=""></img></span>
        <span><img src="/icons/recharge/average_version.svg" alt="" /></span>
        <span><img src="/icons/recharge/font_vip_diamon.svg" alt=""></img></span>
        <span><img src="/icons/recharge/average_version.svg" alt="" /></span>
      </header>
      <ul>
        {fontList.map((item, index) => {
          return Object.keys(item).map((key) => {
            return( 
              <li key={`${index}_${key}`}>  
                {
                  item[key].map(({ font,price }, idx) => (
                    <div key={`${index}_${idx}`}>
                      <span>{font}</span>
                      <span>{price}</span>
                    </div>
                  ))
                }
              </li>
            )
          });
        })}
      </ul>
      <footer>
        {/* <span>SVIP尊享<span>11</span>项SVIP专属特权,VIP尊享<span>8</span>项SVIP专属特权</span>
        <span>划线价说明:宏耀AI展示的划线价为建议零售价</span> */}
      </footer>
    </div>
  )
}

export default FontList;