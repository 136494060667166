import {createSlice} from "@reduxjs/toolkit";

function generateNewChat() {
  return {
    templates: [{content: ""}, {content: ""}],
    usedKeywords: [],
    mainChat: {
      aiAnswering: false,
      history: []
    },
    activeGuideId: -1,
    guideChats: []
    // guideChats： [{guideId, guideName, history, aiAnswering}]
  };
}

/**
 * @description 通过查找chatId和guideId来确认要操作的guideChat或mainChat;
 * @description 如果没有传入，则选择activeChatId和activeGuideId对应的guideChat或mainChat
 * @param {State} state
 * @param {string} chatId
 * @param {number} guideId
 * @returns {GuideChatEntry|MainChat|{aiAnswering: boolean, history: []}}
 */
function findChatEntryByChatIdAndGuideId(state, chatId, guideId) {
  if (!chatId) chatId = state.activeChatId;
  const chat = state.chats[chatId];
  if (guideId === undefined) {
    guideId = state.chats[chatId].activeGuideId;
  }
  if (guideId === -1) {
    return chat.mainChat
  } else {
    for (const guideChat of chat.guideChats) {
      if (guideChat.guideId === guideId) {
        return guideChat
      }
    }
  }
}
export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    /**
     * @typedef {Object} ChatHistoryEntry
     * @property {string} type - 消息类型 ai | human | line | endLine
     * @property {string} message - 消息内容
     */

    /**
     * @typedef {Object} TemplateEntry - 模板内容
     * @property {string} content
     */

    /**
     * @typedef {Object} GuideChatEntry - 引导会话条目
     * @property {string} guideName - 用来展示的title
     * @property {number} guideId
     * @property {number} headingOneId
     * @property {Array<ChatHistoryEntry>} history
     * @property {boolean} aiAnswering - 标记AI是否正在回答这个GuideChat
     */

    /**
     * @typedef {Object} MainChat
     * @property {Array<ChatHistoryEntry>} history
     * @property {boolean} aiAnswering - 标记AI是否正在回答MainChat
     */

    /**
     * @typedef {Object} UsedKeyword
     * @property {string} keyword
     * @property {number} lastTime - Date().getTime() - Unix Timestamp
     */

    /**
     * @typedef {Object} ChatEntry
     * @property {Array<TemplateEntry>} templates - 选择模板阶段需要选择的内容，目前分左右两侧，所以一般长度为2
     * @property {Array<UsedKeyword>} usedKeywords - 记录使用过的关键词的对象
     * @property {MainChat} mainChat - 主会话
     * @property {Array<GuideChatEntry>} guideChats
     * @property {number} activeGuideId
     */

    /**
     * @typedef {string} ChatId
     */

    /**
     * @type {Object<ChatId, ChatEntry>}
     */
    chats: {},

    /**
     * @type {string} - 当前活跃chatId - 一般和路由参数保持一致，在使用到该参数的路由页面 useEffect 监听 chatId 并更新 redux状态
     */
    activeChatId: "",
    activeChatName: "",
  },
  reducers: {
    updateActiveChatId: (state, action) => {
      state.activeChatId = action.payload;
      return state;
    },

    // 往对应的chat中追加template的内容，适用于生成模板时使用
    appendTemplateContent: (state, action) => {
      const {chatId, idx, text} = action.payload;
      const chat = state.chats[chatId];
      if (!chat) {
        return state;
      }
      chat.templates[idx].content = chat.templates[idx].content + text;
      return state
    },

    appendHistoryMsg: (state, action) => {
      const {chatId, guideId, hIdx: hIdx_, msg} = action.payload;
      const hIdx = typeof (hIdx_) === "number" ? hIdx_ : parseInt(hIdx_);

      const guideChat = findChatEntryByChatIdAndGuideId(state, chatId, guideId)
      if(!hIdx) {
      // 在hIdx为空的情况下，自动加到最后一个
        console.info("hIdx autofill", hIdx)
        if (guideChat.history && guideChat.history.length > 0) {
          guideChat.history[guideChat.history.length - 1].message += msg;
        }
        return state;
      }
      if (!guideChat.history[hIdx]) {
        console.error("chat.history[hIdx] wrong", hIdx)
        return state
      }
      guideChat.history[hIdx].message += msg;
      return state;
    },

    pushUsedKeywords: (state, action) => {
      const {keyword, lastTime, chatId} = action.payload
      console.log("Oa ",keyword, lastTime, chatId)
      if(state.chats[chatId].usedKeywords) {
        state.chats[chatId].usedKeywords.push({keyword, lastTime})
      }else {
        state.chats[chatId].usedKeywords = [{keyword, lastTime}]
      }
      return state
    },

    // 新功能 --------
    setAiAnswering: (state, action) => {
      let {chatId, guideId, isAiAnswering} = action.payload;
      const chat = findChatEntryByChatIdAndGuideId(state, chatId, guideId)
      chat.aiAnswering = isAiAnswering;
      return state;
    },

    setNumber: (state, action) => {
      let {chatId, guideId, cdNo,gchNo,historyIdx} = action.payload;
      const chat = findChatEntryByChatIdAndGuideId(state, chatId, guideId)
      if(cdNo&&chat.history[historyIdx]&&chat.history[historyIdx].cd_no){
        chat.history[historyIdx].cd_no = cdNo;
      }
      if(gchNo&&chat.history[historyIdx]&&chat.history[historyIdx].gch_no){
        chat.history[historyIdx].gch_no = gchNo;
      }
      return state;
    },

    newChat: (state, action) => {
      const {chatId} = action.payload;
      if (state.chats[chatId]) return state;
      state.chats[chatId] = generateNewChat();
    },

    setGuideChats: (state, action) => {
      let {chatId, guideChats} = action.payload;
      if (!chatId) {
        chatId = state.activeChatId;
      }
      state.chats[chatId].guideChats = guideChats;
    },

    // 支持对mainChat的history或guideChats中某个guideChat的history赋值
    setChatHistory: (state, action) => {
      let {chatId, guideId, history} = action.payload;
      const chat = findChatEntryByChatIdAndGuideId(state, chatId, guideId)
      chat.history = history;
      return state;
    },

    // 更新具体某一条会话历史记录的消息
    updateChatHistoryEntryMessage: (state, action) => {
      const {chatId, guideId, hIdx, message} = action.payload;
      const chat = findChatEntryByChatIdAndGuideId(state, chatId, guideId);
      chat.history[hIdx].message = message;
      // todo 这里写的不是很好，从代码规范上，不应该在这里关掉waiting
      if(chat.history[hIdx].waiting){
        chat.history[hIdx].waiting = false
      }
      return state;
    },

    activateGuideId: (state, action) => {
      let {chatId, guideId} = action.payload;
      if (!chatId) {
        chatId = state.activeChatId;
      }
      state.chats[chatId].activeGuideId = guideId;
      return state;
    },

    // 往对应的chat中对应的guide中添加一条history记录,常用于用户发送一句对话.
    pushHistoryEntry: (state, action) => {
      let {chatId, guideId, entry} = action.payload;
      const chat = findChatEntryByChatIdAndGuideId(state, chatId, guideId)
      chat.history.push(entry);
      return state;
    },

    newGuideChat: (state, action) => {
      const {chatId, guideId, guideName, headingOneId} = action.payload;
      if (!chatId) return state;
      if (guideId === undefined) return state;
      const chat = state.chats[chatId]

      if(!chat) return state;
      chat.guideChats.push({
        guideId,
        guideName,
        headingOneId,
        aiAnswering: false,
        history: [{
          message: "",
          type: "ai",
        }]
      })
    },

    setActiveChatName: (state, action) => {
      state.activeChatName = action.payload;
      return state;
    },
  }
});

export const {
  updateActiveChatId,
  appendTemplateContent,
  appendHistoryMsg,
  pushHistoryEntry,
  pushUsedKeywords,
  setAiAnswering,
  setChatHistory,
  setGuideChats,
  updateChatHistoryEntryMessage,
  activateGuideId,
  newChat,
  newGuideChat,
  setNumber,
  setActiveChatName
} = chatSlice.actions;

export default chatSlice.reducer;