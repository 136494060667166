import {createSlice} from "@reduxjs/toolkit";

const shopSlice = createSlice({
  name: "shop",
  initialState: {
    modalToggle: false,
    rechargeMember: true,
  },
  reducers: {
    openShop: (state) => {
      state.modalToggle = true;
      return state;
    },
    closeShop: (state) => {
      state.modalToggle = false;
      return state;
    },

    //标记是否为会员充值，默认true为会员充值
    setRechargeMember: (state, action) => {
      state.rechargeMember = action.payload
      return state
    },
  }
})

export default shopSlice.reducer;
export const {
  openShop,
  closeShop,
  setRechargeMember,
} = shopSlice.actions;