import {wlyAxios} from "../../composables/chatRequest";

export function reqCreateNewBp() {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/createChatBp/`, {
        "rounds": "SEED_ROUND",
        "chat_version": "professional_edition",
      })
      .then(({code, msg, data}) => {
        // data example
        //   "chat_id": "fbefcdbef945345340d71b2cf",
        //   "user_id": "45",
        //   "ip": "1901717917",
        //   "chat_name": "chat_bp",
        //   "chat_state": "ChatState.THERE_TWO_QUESTION",
        //   "rounds": "FinancingRounds.SEED_ROUND",
        //   "create_time": "2023-11-04 17:05:50",
        //   "update_time": "2023-11-04 17:05:50"
        resolve([code, data]);
      })
      .catch(({code}) => resolve([code, undefined]));
  });
}

export async function reqInitStaticQt({A1, A2, A3, chatId}) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/entrepreneurialIdea/`, {
        "answer": [
          {
            "id": 0,
            "A": A1
          },
          {
            "id": 1,
            "A": A2
          },
          {
            "id": 2,
            "A": A3
          }
        ],
        "chat_id": chatId
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

export function reqCreateNewBeginnerBp() {
  return new Promise((resolve)=>{
    wlyAxios()
      .post(`/chat/createChatBp/`, {
        "rounds": "SEED_ROUND",
        "chat_version": "beginner_edition",
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

export function reqGenerateBusinessPlan(qt32ChatId, history) {
  return new Promise((resolve)=>{
    wlyAxios({needXWinId: true})
      .post(`/chat/generateBusinessPlan/`, {
        "answer": [
          {
            "id": 0,
            "Q": history[6].message,
            "A": history[7].message,
          },
          {
            "id": 1,
            "Q": history[8].message,
            "A": history[9].message,
          }
        ],
        "chat_id": qt32ChatId,
        "uuid": qt32ChatId
      })
      .then(({code, msg, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]))
  })
}