import { useState } from "react";
import LoginModel from "./LoginModel";
import { SetPwdBox } from "./SetPwdBox";

//modalType为mobile时匹配移动端样式
export default function LoginRegister({onLogin=()=>{},onClickClose=()=>{},modalType=""}) {
  const [showSetPwdBoxToggle, setShowSetPwdBoxToggle] = useState(false);

  return (
    <>
      {
        showSetPwdBoxToggle
          ? <SetPwdBox onLogin={onLogin} modalType={modalType}/>
          : <LoginModel onLogin={onLogin}
                        onClickClose={onClickClose}
                        onNeedSetPwd={() => {
                          setShowSetPwdBoxToggle(true);
                        }}
                        modalType={modalType}
          />
      }
    </>
  );
}