import {createSlice} from "@reduxjs/toolkit";

export const loginRegisterSlice = createSlice({
  name: "loginRegister",
  initialState: {
    reqVerifyCodeLastTime: (new Date(0)).getTime(),
  },
  reducers: {
    setReqVerifyCodeLastTime(state, action) {
      state.reqVerifyCodeLastTime = action.payload;
    }
  }
})

export const {setReqVerifyCodeLastTime} = loginRegisterSlice.actions;
export default loginRegisterSlice.reducer;