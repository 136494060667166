import {App} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { wlyAxios } from "../../../composables/chatRequest";
import { useErrCodeHook } from "../../../composables/errCodeHook";
import { setReqVerifyCodeLastTime } from "../../../reducers/loginRegisterSlice";
import "./LoginModel.css";
import VerifyCodeCountDown from "./VerifyCodeCountDown";

export default function LoginModel({
                                     onNeedSetPwd,
                                     onLogin,
                                     onClickClose,
                                     modalType
                                   }) {
  const dispatch = useDispatch();
  /**
   * @typedef {string} Selected
   * 枚举 'Acc', 'Msg', 'Reg' (账号, 短信, 注册-已废弃)
   */
  const [selected, setSelected] = useState("Msg");
  const onSelectAccount = () => setSelected("Acc");
  const onSelectMsg = () => setSelected("Msg");
  const {message} = App.useApp();
  const {handleErrCode} = useErrCodeHook();
  const [referralCode, setReferralCode] = useState("");
  const isReferralCode = useMemo(()=>referralCode&&referralCode.length>0, [referralCode])
  const location = useLocation();

  useEffect(() =>{
    const searchParams = new URLSearchParams(location.search);
    setReferralCode(searchParams.get('referral_code'));
  },[location])

  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneValid = useMemo(() => (/^1[0-9]{10}$/.test(phone)), [phone]);
  const [verifyCode, setVerifyCode] = useState("");
  const [checkAgreeToggle, setCheckAgreeToggle] = useState(true);

  const signIn = useSignIn();
  const [lastClickLoginTime, setLastClickLoginTime] = useState(new Date(new Date() - 5*1000));  // 以五秒前的时间为初始值
  function handleClickLogin() {
    if (!checkAgreeToggle) {
      message.info("请勾选同意协议").then();
      return
    }
    let now = new Date();
    if (now - lastClickLoginTime < 1000) {
      // 只允许1秒点击一次
      message.info("操作过于频繁，请稍后再点击").then();
      return
    }
    setLastClickLoginTime(now)
    if(selected === "Acc" && !isReferralCode){
      loginByAcc()
    } else if (selected === "Msg" || isReferralCode) {
      loginByMsg();
    }
  }

  function loginByAcc() {
    if (!phoneValid) {
      message.error("请正确填写手机号").then(null);
      return;
    }

    wlyAxios({needToken: false})
      .post(`/user/login/password/`, {
        "phone_number": phone,
        "user_password": password
      })
      .then(({code, msg, data}) => {
        switch (code) {
          case 0:
            login(data);
            message.success("登录成功").then(null);
            onLogin();
            break;
          case -4: // 当前手机号未设置密码
            message.info("当前手机号未设置密码，请使用短信验证码登录").then(null);
            break;
          case -2: // 手机号未注册
            message.info("当前手机号未注册，请使用短信验证码登录").then(null);
            break;
          case -1: // 手机号格式不正确
          case -5: // 账号或密码错误
            message.info(msg).then(null);
            break;
          default:
            message.error(msg).then(null);
        }
      })
  }

  // 验证码登录
  function loginByMsg() {
    if (!phoneValid) {
      message.error("请正确填写手机号").then(null);
      return;
    }

    wlyAxios({needToken: false})
      .post(referralCode === "" ?
        `/user/login/otp/`
        :`/user/login/otp/?referral_code=${referralCode}`, {
        "phone_number": phone,
        "otp": verifyCode,
      })
      .then(({code, msg, data}) => {
        // code 0-已注册有密码, 马上登录; 1-已注册无密码, 2-刚注册(必定无密码), 把token存好，然后弹出设置密码框，但不马上登录
        switch (code) {
          case 1:
          case 2:
            // 标记需要弹出设置密码框
            // login(data);
            localStorage.setItem("_hongyao", data.access_token);
            // 记下相关信息
            localStorage.setItem("_hongyao_state", JSON.stringify({username: phone, phone}));
            message.info(msg).then(null);
            onNeedSetPwd();
            break;
          case 0:
            login(data);
            message.success(msg).then(null);
            onLogin()
            break;
          case -1: // 验证码错误
          case -2: // 手机号格式错误
          case -3: // 数据库异常
            message.info(msg).then(null);
            break;
          default:
            handleErrCode(code);
        }
      })
  }

  function login(data) {
    localStorage.setItem("_hongyao", data.access_token);
    setTimeout(()=>{
      // 延迟一下，不要马上触发App.js 改变路由 / 对应的组件
      if (signIn({
        token: data.access_token,
        tokenType: "Bearer",
        expiresIn: 14 * 24 * 60,
        authState: {
          username: phone,
          phone: phone,
        }
      })) {
        console.log("rsk success");
      } else {
        // 理论上来说singIn函数应该不会出错
        console.error("rsk err");
      }
    }, 300)
  }

  function handleClickReqVC() {
    if (!phoneValid) {
      message.error("请正确填写手机号").then(null);
      return;
    }

    wlyAxios({needToken: false})
      .post(`/user/send_otp/`, {
        "phone_number": phone,
        "otp": "string",  // 留空
        "captcha_response": "string"  // 留空
      })
      .then(({code})=>{
        if (code === 0) {
        } else {
          dispatch(setReqVerifyCodeLastTime((new Date(0)).getTime()));
        }
        handleErrCode(code);
      })
  }

  return (
    <div className="login-model"  data-type={modalType}>
      {modalType!=="moblie"&&
      <aside>
        <img alt={""} src={"/images/login_register/blocks_boom.png"} id={"login-register-blocks-boom"}/>
        <img alt={""} src={"/images/login_register/welcome.svg"}
             id={"login-register-welcome"}
             className={"big"}
        />
        {/*{selected === "Msg" &&<div className={"gouzhu"}>构筑坚实计划<br/>点燃创意火花</div>}*/}
        <div className={"dont-have"}>Don’t have an account?<br/>Create Account!</div>
        <div className={"shuru"}>
          输入内容主题<br/>
          AI为您打造精准而高效的商业计划
        </div>
        {/*{selected === "Msg" && <div className={"dream-big"}>*/}
        {/*  Dream Big，Plan Smart<br/>*/}
        {/*  Transform Ideas into Business Plans*/}
        {/*</div>}*/}
        {/*{selected === "Acc" &&<div className={"precise"}>*/}
        {/*  Precise and efficient*/}
        {/*</div>}*/}
      </aside>}
      <main>
        <img alt={""} src={"/images/login_register/close_btn.svg"}
             className={"login-register-close-btn"}
             onClick={() => onClickClose()}
        />
        <h1>创意转为商业计划</h1>
        <p>量身定制吸引投资人的商业计划书</p>
        <section className={"tab-container"}>
          <button className={(selected === "Msg" || isReferralCode) ? "tab-btn active" : "tab-btn"}
                  onClick={() => onSelectMsg()}>
                  验证码登录
          </button>
          {!isReferralCode &&
            <>&nbsp;
              <span style={{color: "#4D4C4BB2"}}>|</span>&nbsp;
              <button className={(selected === "Acc" && !isReferralCode) ? "tab-btn active" : "tab-btn"}
                      onClick={() => onSelectAccount()}>账号密码登录
              </button>
            </>}
        </section>

        {selected === "Msg" && <div className={"input-container"}>
          <input placeholder="手机 / Telephone Number"
                 required name={"phone"} value={phone}
                 aria-label={"phone"}
                 onChange={e => setPhone(e.target.value)}
          />
          <hr className={(!phoneValid) && phone ? "error" : undefined}/>
          <input placeholder="验证码 / Code"
                 aria-label={"code"}
                 required name={"code"} value={verifyCode}
                 onChange={e => setVerifyCode(e.target.value)}
          />
          <VerifyCodeCountDown onClick={handleClickReqVC} clickAble={phoneValid}/>
          <hr/>
        </div>}

        {selected === "Acc" && <div className={"input-container"}>
          <input placeholder="账号 / Username" required value={phone} className={"phone"}
                 aria-label={"account"}
                 onChange={e => setPhone(e.target.value)}
          />
          <hr/>
          <input placeholder="密码 / Password" required value={password} className={"password"}
                 type={passwordVisible ? "text" : "password"}
                 aria-label={"password"}
                 onChange={e => setPassword(e.target.value)}
          />
          <span onClick={() => setPasswordVisible(!passwordVisible)}>
            {
              passwordVisible
                ? <img alt={""} src={"/images/login_register/open_eye.svg"}/>
                : <img alt={""} src={"/images/login_register/close_eye.svg"}/>
            }
          </span>
          <hr/>
        </div>}

        <div className={"policy"}>
          {checkAgreeToggle
            ? <img alt={""} src={"/icons/checkbox_true.svg"} onClick={()=>setCheckAgreeToggle(false)}/>
            : <img alt={""} src={"/icons/checkbox_false.svg"} onClick={()=>setCheckAgreeToggle(true)}/>
          }&nbsp;
           
          {selected === "Msg" ? <>未注册的手机将自动注册。勾选即代表您同意并接受</> :<>您同意并接受</>}
          <a href={"/about/service"} target={"_blank"} rel={"noreferrer"}>《服务协议》</a>与
          <a href={"/about/privacy"} target={"_blank"} rel={"noreferrer"}>《隐私政策》</a>
          <br/><br/>
          {referralCode && `正在使用邀请码:${referralCode}`}
        </div>

        <footer>
          <button className="signin" onClick={e => {
            // e.stopPropagation();
            // e.preventDefault();
            handleClickLogin();
          }}>登录
          </button>
          <div>智能互动写作体验</div>
        </footer>

      </main>
    </div>
  )
}