import {createSlice} from "@reduxjs/toolkit";

const init = {
  keyword : "",
    status : "",
};

const keywordSlice = createSlice({
  name: "keyword",
  initialState: init,
  reducers: {
    setKeyword: (state, action) => {
      state = {
        status: true,
        ...action.payload
      };
      return state
    },
    endKeyword: (state) => {
      state.status = false
      return state
    }
  }
});

export const {setKeyword, endKeyword} = keywordSlice.actions;

export default keywordSlice.reducer;