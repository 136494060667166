import {createSlice} from "@reduxjs/toolkit";

const init = {
  email: "",
  name: "",
  wordCount: {
    left: 0
  },
  userInfo:null,
  userHomeVisit:{
    info:[],
    click:false,
    client_id:"",
    device_type:""
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState: init,
  reducers: {
    setWordCountLeft: (state, action)=>{
      state.wordCount.left = action.payload
      return state
    },
    setUserInfo:  (state, action)=>{
      state.userInfo = action.payload
      return state
    },
    setUserHomeVisit: (state, action)=>{
      state.userHomeVisit = action.payload
      return state
    },
    setInitUserHomeVisit: (state)=>{
      state.userHomeVisit = {
        info:[],
        click:false,
        client_id:"",
        device_type:"",
      };
      return state
    },
  }
});

export const {setWordCountLeft,setUserInfo,setUserHomeVisit,setInitUserHomeVisit} = userSlice.actions;

export default userSlice.reducer;