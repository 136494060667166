import {createSlice} from "@reduxjs/toolkit";

const getBlankHistory = () => [
  {id: 0, type: "ai", message: "Q1：请告诉我关于您的创业项目的核心想法和理念是什么？"},
  {id: 1, type: "human", message: "", placeholder: "示例：通过结合先进的自然语言处理和机器学习算法，我们可以为用户提供更快速、更准确、更创意的写作体验。我们的平台不仅仅是一种工具，更是激发创造力的伙伴。"},
  {id: 2, type: "ai", message: "Q2：您的目标市场是什么？可以描述您的目标客户群体吗？"},
  {id: 3, type: "human", message: "", placeholder: "示例：我们广泛服务各类用户，包括内容创作者、学生和企业专业人士，独特之处在于我们专注于为创业者提供特定支持。"},
  {id: 4, type: "ai", message: "Q3：您计划如何与竞争对手区分开来？使其在市场上脱颖而出？"},
  {id: 5, type: "human", message: "", placeholder: "示例：我们深刻了解用户的写作风格，注重创新、可定制性和卓越的客户体验。通过引导功能，我们协助用户轻松撰写出更为出色的商业计划书。"},
  {id: 6, type: "ai", message: "正在生成动态问题 ...", waiting: true},
  {id: 7, type: "human", message: ""},
  {id: 8, type: "ai", message: "正在生成动态问题 ...", waiting: true},
  {id: 9, type: "human", message: ""},
];

// 该SLICE的状态仅为 QAProfession这一个组件服务（其他地方可以执行action）
const QAProfessionSlice = createSlice({
  name: "QAProfession",
  initialState: {
    toggle: "close", // open close hidden-暂时不使用hidden
    chatId: "",
    screenStatus: "3Q",   // 3Q-三个问题 2Q-两个问题 GEN-生成计划书中
    history: getBlankHistory(),
    template: "",
    templateDone: false,
  },
  reducers: {
    open: (state, action) => {
      state.toggle = "open";
      return state;
    },
    openNew: (state, action) => {
      const chatId = action.payload;
      state.toggle = "open";
      state.chatId = chatId;
      state.screenStatus = "3Q";
      state.history = getBlankHistory();
      state.template = "";
      state.templateDone = false;
      return state
    },
    close: (state, action) => {
      state.toggle = "close";
      return state;
    },
    setScreenStatus: (state, action) => {
      if (["3Q", "2Q", "GEN"].indexOf(action.payload) === -1) {
        console.error("不支持的screenStatus", action.payload);
      }
      state.screenStatus = action.payload;
      return state;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
      return state;
    },
    setMessage: (state, action) => {
      const {message, historyIndex} = action.payload;
      state.history[historyIndex].message = message;
      return state;
    },
    setMessageWaiting: (state, action) => {
      state.history[6].waiting = action.payload;
      state.history[8].waiting = action.payload;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
      return state;
    },
    appendTemplate: (state, action)=>{
      // 设置检查chatId是防止上一篇文章的生成内容继续填在此处。
      const {content, chatId} = action.payload
      if (chatId !== state.chatId) {
        return state
      }
      state.template = state.template + content;
      return state;
    },
    setTemplateDone: (state, action) => {
      state.templateDone = action.payload;
      return state
    },
    init: (state) => {
      return {
        toggle: "close", // open close hidden
        chatId: "",
        screenStatus: "3Q",   // 3Q-三个问题 2Q-两个问题 GEN-生成计划书中
        history: getBlankHistory(),
        template: "",
        templateDone: false,
      }
    }
  }
});

export default QAProfessionSlice.reducer;

export const {
  open,
  openNew,
  close,
  setScreenStatus,
  setHistory,
  setMessage,
  setMessageWaiting,
  setTemplate,
  appendTemplate,
  setTemplateDone,
  init,
} = QAProfessionSlice.actions;