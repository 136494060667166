import { createContext } from "react";
import { newHYAxios } from "../composables/chatRequest";

export const interruptFunc = (uuid="",message) => {
  newHYAxios()
    .get(`/chat/websocketInterrupt/`,{
      params: {uuid}
    })
    .then(({code, msg})=>{
      if (!(code !== undefined && code === 0)) {
        message.info(msg).then(null)
      }
    })
    .catch(({code, msg, data})=>{
      message.error(msg).then(null)
      console.error(code, msg, data)
    })
}

export const WebsocketInterruptContext = createContext({interruptFunc})