import { createSlice } from "@reduxjs/toolkit";

export const slateSlice = createSlice({
    name: "slate",
    initialState: {
        node: [],
        headingOneId: null,
        optimizedStart:null,
        optimizedResult:"",
        decorate:null,
        refresh:false,
        isSave:false,
        guideSummaryInsert:{
            guideSummaryContent:"",
            guideSummaryHeadingOneId:null,
        },
        guideSummaryClose:false
    },
    reducers: {
        //记录slate当前页面的所有节点数据,用于保存
        setNode: (state, action) => {
            state.node = action.payload
            return state
        },

        //记录标题一id，用于引导识别
        setHeaderId: (state, action) => {
            state.headingOneId = action.payload
            return state
        },

        //记录当前插入或替换文本，用于高光标识
        setOptimizedResult: (state, action) => {
            state.optimizedResult = action.payload
            return state
        },

        //记录当前插入或替换初始位置，用于高光标识
        setOptimizedStart: (state, action) => {
            state.optimizedStart = action.payload
            return state
        },

        //高光显示，decorate应为range数据格式+高光属性
        setDecorate:  (state, action) => {
            state.decorate = action.payload
            return state
        },

        setRefresh: (state, action) => {
            state.refresh = action.payload
            return state
        },

        //标志用户正在手动保存中，若为ture，自动保存不触发，
        setIsSave: (state, action) => {
            state.isSave = action.payload
            return state
        },

        //引导总结内容插入
        setGuideSummaryInsert: (state, action) => {
            const {guideSummaryContent, guideSummaryHeadingOneId} = action.payload;
            state.guideSummaryInsert.guideSummaryContent = guideSummaryContent;
            state.guideSummaryInsert.guideSummaryHeadingOneId = guideSummaryHeadingOneId;
        },

        //标志引导总结内容插入后关闭弹窗
        setGuideSummaryClose: (state, action) => {
            state.guideSummaryClose = action.payload
            return state
        },
    }
});

export const { 
    setNode, 
    setHeaderId, 
    setOptimizedResult,
    setOptimizedStart,
    setDecorate,
    setRefresh, 
    setIsSave,
    setGuideSummaryInsert,
    setGuideSummaryClose
} = slateSlice.actions;

export default slateSlice.reducer;