import { useCallback, useState } from "react";
// import {useNavigate} from "react-router-dom";
import { App } from "antd";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openShop } from "../reducers/shopSlice";
import { NO_TOKEN_CODE, NO_X_WIN_ID_CODE, PROCESS_NEEDLESS_CODE, UNKNOWN_CODE } from "./chatRequest";

const handleErrCodeLastTimeMap = {}
export function useErrCodeHook() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {message} = App.useApp();
  const openShopCallback = useCallback(()=>dispatch(openShop()), [dispatch]);
  const toHome = () =>navigate("/")
  const [errCodeMap,] = useState({
    /*前端错误码（请求前出了问题或后端无响应/响应异常等）*/
    // 注意带中括号，不然key名就是"NO_TOKEN_CODE"了
    [NO_TOKEN_CODE]: {notice: "客户端获取身份令牌出错，请尝试重新登录",callback:toHome},
    [NO_X_WIN_ID_CODE]: {notice: "客户端获取窗口ID出错，请尝试刷新页面",},
    [UNKNOWN_CODE]: {notice: "网络错误，请检查您的网络情况",},

    /*后端响应错误码*/
    100000: {notice: "出现未知错误，请刷新重试"},
    101100: {notice: "网络异常，请重试",},
    101101: {notice: "网络异常，请刷新重试",},
    101103: {notice: "出现未知错误，请刷新重试",},
    101104: {notice: "出现未知错误，请刷新重试",},
    101105: {notice: "出现未知错误，请重试",},
    101106: {notice: "",},
    101107: {notice: "出现未知错误，请重试",},
    101108: {notice: "出现未知错误，请刷新重试"},
    101109: {notice: "",},
    101110: {notice: "",},
    101111: {notice: ""},
    101112: {notice: "",},
    101114: {notice: "",},  //通过前端传入的chat_id和version没有找到对应商业计划书内容
    101115: {notice: "",},  //通过前端传入的chat_id传入的chat_id不是32位

    102100: {notice: "",},
    102101: {notice: "出现未知错误，请重试",},
    102102: {notice: "出现未知错误，请重试",},
    102103: {notice: "网络异常，请刷新重试",},
    102104: {notice: "网络异常，请刷新重试",},
    102105: {notice: "网络异常，请刷新重试",},
    102106: {notice: "该引导窗口已删除，请刷新",},
    102107: {notice: "该引导窗口已删除，请刷新",},
    102108: {notice: "该引导窗口已删除，请刷新",},
    102109: {notice: "该引导窗口已删除，请刷新",},
    102110: {notice: "出现未知错误，请刷新重试",},
    102111: {notice: "出现未知错误，请刷新重试",},
    102112: {notice: "出现未知错误，请刷新重试",},

    103100: {notice: "",},
    103101: {notice: "",},
    103102: {notice: "出现未知错误，请刷新重试",},
    103103: {notice: "出现未知错误，请刷新重试",},
    103104: {notice: "出现未知错误，请刷新重试",},
    103105: {notice: "出现未知错误，请刷新重试",},
    103106: {notice: "出现未知错误，请刷新重试",},
    103107: {notice: "出现未知错误，请刷新重试",},
    103108: {notice: "出现未知错误，请刷新重试",},
    103109: {notice: "出现未知错误，请刷新重试",},
    103110: {notice: "出现未知错误，请刷新重试",},
    103111: {notice: "生成ppt文本失败",},


    104100: {notice: "出现未知错误，请刷新重试",},
    104101: {notice: "出现未知错误，请刷新重试",},
    104102: {notice: "",},
    104103: {notice: "网络异常，请刷新重试",},
    104104: {notice: "",},
    104105: {notice: "出现未知错误，请刷新重试",},
    104106: {notice: "出现未知错误，请刷新重试",},
    104107: {notice: "出现未知错误，请刷新重试",},
    104108: {notice: "出现未知错误，请刷新重试",},
    104109: {notice: "出现未知错误，请刷新重试",},
    104110: {notice: "出现未知错误，请刷新重试",},
    104111: {notice: "出现未知错误，请刷新重试",},
    104112: {notice: "出现未知错误，请刷新重试",},
    104113: {notice: "出现未知错误，请刷新重试",},
    104114: {notice: "",},
    104115: {notice: "出现未知错误，请刷新重试",},

    105100: {notice: "",},
    105101: {notice: "弹「用户登录」",}, // todo

    107101: {notice: "出现未知错误，请刷新重试",},
    107102: {notice: "出现未知错误，请刷新重试",},
    107103: {notice: "出现未知错误，请刷新重试",},
    107104: {notice: "出现未知错误，请刷新重试",},
    107105: {notice: "出现未知错误，请刷新重试",},
    107106: {notice: "出现未知错误，请刷新重试",},
    107107: {notice: "出现未知错误，请刷新重试",},
    107108: {notice: "出现未知错误，请刷新重试",},
    107109: {notice: "出现未知错误，请刷新重试",},
    107110: {notice: "出现未知错误，请刷新重试",},
    107111: {notice: "出现未知错误，请刷新重试",},
    107112: {notice: "出现未知错误，请刷新重试",},
    107113: {notice: "出现未知错误，请刷新重试",},
    107114: {notice: "出现未知错误，请刷新重试",},
    107115: {notice: "出现未知错误，请刷新重试",},
    107116: {notice: "出现未知错误，请刷新重试",},
    107117: {notice: "出现未知错误，请刷新重试",},
    107118: {notice: "出现未知错误，请刷新重试",},
    107119: {notice: "出现未知错误，请刷新重试",},
    107120: {notice: "出现未知错误，请刷新重试",},
    107121: {notice: "出现未知错误，请刷新重试",},
    107122: {notice: "出现未知错误，请刷新重试",},
    107123: {notice: "出现未知错误，请刷新重试",},
    107124: {notice: "出现未知错误，请刷新重试",},
    107125: {notice: "出现未知错误，请刷新重试",},
    107126: {notice: "出现未知错误，请刷新重试",},
    107127: {notice: "出现未知错误，请刷新重试",},
    107128: {notice: "出现未知错误，请刷新重试",},
    107129: {notice: "出现未知错误，请刷新重试",},
    107130: {notice: "出现未知错误，请刷新重试",},
    107131: {notice: "出现未知错误，请刷新重试",},
    107132: {notice: "出现未知错误，请刷新重试",},
    107133: {notice: "出现未知错误，请刷新重试",},
    107134: {notice: "出现未知错误，请刷新重试",},
    107135: {notice: "出现未知错误，请刷新重试",},
    107136: {notice: "出现未知错误，请刷新重试",},
    107137: {notice: "出现未知错误，请刷新重试",},
    107138: {notice: "出现未知错误，请刷新重试",},
    107139: {notice: "出现未知错误，请刷新重试",},
    107140: {notice: "出现未知错误，请刷新重试",},
    107141: {notice: "出现未知错误，请刷新重试",},
    107142: {notice: "出现未知错误，请刷新重试",},
    107143: {notice: "出现未知错误，请刷新重试",},
    107144: {notice: "出现未知错误，请刷新重试",},
    107145: {notice: "出现未知错误，请刷新重试",},
    107146: {notice: "出现未知错误，请刷新重试",},
    107147: {notice: "出现未知错误，请刷新重试",},
    107148: {notice: "出现未知错误，请刷新重试",},
    107149: {notice: "出现未知错误，请刷新重试",},
    107150: {notice: "出现未知错误，请刷新重试",},
    107151: {notice: "出现未知错误，请刷新重试",},
    107152: {notice: "出现未知错误，请刷新重试",},
    107153: {notice: "出现未知错误，请刷新重试",},

    107170: {notice: ({estimateNumber="10000"})=>message.info(`PPT转化预计消耗${estimateNumber}可用字数，当前字数不足`)},

    109100: {notice: "",},
    109101: {notice: "",},

    201100: {
      notice: "您的AI字数余额不足，您可以充值字数",
      callback: openShopCallback,
    },
    201101: {notice: "系统维护中，暂停服务，有需要请联系客服",},
    201102: {
      notice: (functionName="该功能")=>message.info(`${functionName}超过使用次数，您可以充值会员解锁无限制体验`).then(),
      callback: openShopCallback,
    },
    201103: {
      notice: (blockName="该板块")=>`${blockName}文字超过上限，该功能无法开启。`,
    },
    201104: {
      notice: (functionName="该功能")=>message.info(`${functionName}超过使用次数，您可以充值会员解锁无限制体验`).then(),
      callback: openShopCallback,
    },
    201105: {
      notice: (functionName="该功能")=>message.info(`${functionName}超过使用次数，您可以充值会员解锁无限制体验`).then(),
      callback: openShopCallback,
    },
    201106: {
      notice: (functionName="该功能")=>message.info(`${functionName}超过使用次数，您可以充值会员解锁无限制体验`).then(),
      callback: openShopCallback,
    },
    201107: {notice: "AI正在生成中，请稍后再试",},

    202100: {notice: "免费文件数量已达上限，可升级会员",},
    202101: {notice: "",},
    205100: {notice: "手机号格式不正确",},
    205101: {notice: "",},
    205102: {notice: "",},
    205104: {notice: "",},
    205105: {notice: "",},
    205106: {notice: "今日注册用户已到上限, 请明日再进行尝试",},
    205107: {notice: "",},
    205108: {notice: "????",},
    205109: {notice: "手机号或验证码错误",},
    205110: {notice: "密码需由大小写字母加数字组成",},
    205111: {notice: "无效的优惠券",},
    205112: {notice: "优惠券超过使用次数",},
    208100: {notice: "",},
    208101: {notice: "网络异常，请刷新重试",},
    209100: {notice: "",},
    209101: {notice: "",},

    301100: {notice: "",},
    301101: {notice: "",}, // 请求第三方模型接口超时
    301102: {notice: "",}, // 第三方模型接口调用出错
  });

  /**
   * 默认的错误码处理函数。
   * @type {(function(number, object, object): void)|*}
   */
  const handleErrCode = useCallback((code, callbackParams, noticeParams) => {
    // callbackParams 是有时需要补充给 callback 参数用的
    // 以 Object<code, 参数> 形式展现，方便给具体的 code 对应的 callback 传递特定参数。

    // 同理noticeParams

    if (code === PROCESS_NEEDLESS_CODE || code === 0) return;
    console.log("code", code)

    // 节流判断
    const now = new Date()
    if (handleErrCodeLastTimeMap[code] && now - handleErrCodeLastTimeMap[code] < 1000) {
      console.log("code reject", code)
      return
    }
    console.log("code resolve", code)
    handleErrCodeLastTimeMap[code] = now;

    if (errCodeMap[code]) {
      const {notice, callback} = errCodeMap[code];
      if (notice) {
        if (typeof notice === "string"&&notice !== "出现未知错误，请刷新重试") {
          // TODO 这里有个 Warning: [antd: message] Static function can not consume context like dynamic theme. Please use 'App' component instead.
          message.info(notice).then();
        } else if (typeof notice === "function") {
          if (noticeParams) {
            console.log(noticeParams)
            notice(noticeParams)
          } else {
            notice()
          }
        }
      }

      if (callback) {
        if (callbackParams && callbackParams[code]) {
          callback(callbackParams[code])
        } else {
          callback();
        }
      }
    }
     // eslint-disable-next-line
  }, [errCodeMap]);

  return {errCodeMap, handleErrCode};
}