import {App} from "antd";
import { useMemo, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { wlyAxios } from "../../../composables/chatRequest";
import { useErrCodeHook } from "../../../composables/errCodeHook";

// TODO 以后SetPwdBox组件的内容应该和短信登录、账号登录一起放到LoginModel中
// 之前分开是因为设置密码和登录用的不是一个弹窗，但现在都用的一个弹窗，应该合并到一起。
export function SetPwdBox({onLogin,modalType}) {
  const { handleErrCode } = useErrCodeHook();
  const {message} = App.useApp();
  const signIn = useSignIn();
  const [pwd, setPwd] = useState("");
  const [pwdAgain, setPwdAgain] = useState("");
  const [pwdVisible, setPwdVisible] = useState(false);
  const [pwdAgainVisible, setPwdAgainVisible] = useState(false);
  const checkPwd = useMemo(() => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,16}$/.test(pwd)), [pwd]);

  function onClickSure() {
    if (pwdAgain !== pwd) {
      message.info("两次填写的密码不一致").then();
      return
    }
    if(!checkPwd){
      message.info("密码应至少包含一个大写字母、一个小写字母以及一个数字，长度为6-16位").then(null);
      return
    }
    wlyAxios()
      .post(`/user/password/`, {
        "user_password": pwd
      })
      .then(({code, msg, data}) => {
        /**
         * @type string
         */
        switch (code) {
          case 0:
            const {access_token: token} = data;
            // TODO 这里及时更新LocalStorage，以免在登录时马上跳转到warehouse页面时，使用旧的token获取数据，导致401
            localStorage.setItem("_hongyao", token);
            if (signIn({
              token: token,
              tokenType: "Bearer",
              expiresIn: 14 * 24 * 60,
              authState: JSON.parse(localStorage.getItem("_hongyao_state"))
            })) {
              console.log("rsk signIn");
              message.success("设置密码并登录成功").then(null)
              onLogin()
            } else {
              console.error("重置密码登录发生未知错误");
            }
            break;
          default:
            handleErrCode(code)
            // message.error("请求发生未知错误").then(null)
        }
      })
      .catch(({code})=>handleErrCode(code))
  }

  return (
    <div className="login-model" data-type={modalType}>
      {modalType!=="moblie"&&
      <aside>
         <img alt={""} src={"/images/login_register/blocks_boom.png"} id={"login-register-blocks-boom"}/>
         <img alt={""} src={"/images/login_register/welcome.svg"}
              id={"login-register-welcome"}
              className={"big"}
         />
        <div className={"dont-have"}>Don’t have an account?<br/>Create Account!</div>
         <div className={"shuru"}>
           输入内容主题<br/>
           AI为您打造精准而高效的商业计划
         </div>
        </aside>
      }

        <main>
        {/* <img alt={""} src={"/images/login_register/close_btn.svg"}
             className={"login-register-close-btn"}
             onClick={() => onClickClose()}
        /> */}
          <h1>创意转为商业计划</h1>
          <p>量身定制吸引投资人的商业计划书</p>
          <h2>设置密码</h2>
          <span>密码应至少包含一个大写字母、一个小写字母以及一个数字，长度为6-16位</span>
          <div className={"input-container"} data-setpwd="true">
          <input placeholder="请输入密码 / Password" required value={pwd} className={"password"}
                 type={pwdVisible ? "text" : "password"}
                 aria-label={"password"}
                 onChange={e => setPwd(e.target.value)}
          />
          <span onClick={() => setPwdVisible(!pwdVisible)}>
            {
              pwdVisible
                ? <img alt={""} src={"/images/login_register/open_eye.svg"}/>
                : <img alt={""} src={"/images/login_register/close_eye.svg"}/>
            }
          </span>
          <hr/>
          <input placeholder="请再次输入密码 / Password Again" required value={pwdAgain} className={"password"}
                 type={pwdAgainVisible ? "text" : "password"}
                 aria-label={"password"}
                 onChange={e => setPwdAgain(e.target.value)}
          />
          <span onClick={() => setPwdAgainVisible(!pwdAgainVisible)}>
            {
              pwdAgainVisible
                ? <img alt={""} src={"/images/login_register/open_eye.svg"}/>
                : <img alt={""} src={"/images/login_register/close_eye.svg"}/>
            }
          </span>
          <hr/>
          </div>

        {/* <div className={"policy"}>
          {checkAgreeToggle
            ? <img alt={""} src={"/icons/checkbox_true.svg"} onClick={()=>setCheckAgreeToggle(false)}/>
            : <img alt={""} src={"/icons/checkbox_false.svg"} onClick={()=>setCheckAgreeToggle(true)}/>
          }&nbsp;
          未注册的手机将自动注册。勾选即代表您同意并接受
          <a href={"/about/service"} target={"_blank"} rel={"noreferrer"}>《服务协议》</a>与
          <a href={"/about/privacy"} target={"_blank"} rel={"noreferrer"}>《隐私政策》</a>
          <br/><br/>
          {referralCode && `正在使用邀请码:${referralCode}`}
        </div> */}

        <footer>
          
          <button className="jump" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          console.log("jump")
          signIn({
            token: localStorage.getItem("_hongyao"),
            tokenType: "Bearer",
            expiresIn: 14 * 24 * 60,
            authState: JSON.parse(localStorage.getItem("_hongyao_state"))
          })
        }}>跳过
        </button>
        <button className="signin" onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onClickSure();
          }}>完成
          </button>
          <div>智能互动写作体验</div>
        </footer>
        </main>

    </div>
  );
}