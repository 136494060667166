export const siderMemberList = [
  "价格",
  "每日登录赠送字数",
  "购买赠送字数",
  {"商业计划书生成":["一键AI创建商业计划书","定制版创建商业计划书","PRO版"]},
  {"商业计划书优化":["内容板块创建次数","内容引导总结次数","内容会话次数","内容对话","智能内容分析"]},
  {"产品功能":["AI智能对话次数","AI历史文档数量","我的文档数量","下载文件"]}
]

export const siderFontList = ["购买字数价格"]
  

export const headerList = ["普通用户","3天试用版","会员版","SVIP大会员"]

export const mainMemberItem = [
    [ "0",
      "0",
      "",//空字符串占位还原UI图布局，以下类似
      "5次",
      "5次",
      "2次",
      "",
      "2次",
      "2次",
      "10次",
      "10次",
      "2次",
      "",
      "30",
      "5",
      "5",
      "禁止"
    ],
    [ "0",
      "20万",
      "",
      "10次",
      "10次",
      "10次",
      "",
      "10次",
      "10次",
      "无限",
      "无限",
      "10次",
      "",
      "无限",
      "5",
      "5",
      "无限"
    ],
    [ "1万",
      "5万",
      "",
      "20次/天",
      "20次/天",
      "20次/天",
      "",
      "无限",
      "无限",
      "无限",
      "无限",
      "无限",
      "",
      "无限",
      "无限",
      "无限",
      "无限"
    ],
    [ "1万",
      "5万",
      "",
      "无限",
      "无限",
      "无限",
      "",
      "无限",
      "无限",
      "无限",
      "无限",
      "无限",
      "",
      "无限",
      "无限",
      "无限",
      "无限"
    ]
  ]

export const mainFontItem = [
  ["9.9/1万字","39.9/2万字","59.9/5万字"],
  ["9.9/1万字","",""],
  ["9.9/1万字","99/10万字",""],
  ["无限","无限","无限"],
]

export const member = [
  {
    description:"仅限首次购买",
    type:"vip",
    price:"29.9/3",
    mark:"59.9",
    duration:"3"
  },
  {
    description:"立减40元",
    type:"vip",
    price:"49.9/",
    mark:"89.9",
    duration:"30"
  },
  {
    description:"每日限10个名额",
    type:"svip",
    price:"1228/",
    mark:"2288",
    duration:"365"
  }
]

export const memberList = [
  {
    "字数赠送":[
      {
        introduction:"每日赠送",
        freeVersion:"0",
        vip:"1万",
        // svip:"5万",
      },
      {
        introduction:"购买即赠送",
        freeVersion:"0",
        vip:"5万",
        // svip:"2000万",
      }
    ],
    "商业计划书生成":[
      {
        introduction:"一键AI创建",
        freeVersion:"5次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"专业版创建",
        freeVersion:"5次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"定制版创建",
        freeVersion:"1次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"Pro版升级",
        freeVersion:"2次",
        vip:"无限",
        // svip:"无限",
      }
    ],
    "商业计划书优化":[
      {
        introduction:"内容板块创建",
        freeVersion:"2次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"内容辅助引导",
        freeVersion:"2次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"内容对话",
        freeVersion:"10次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"内容分析",
        freeVersion:"2次",
        vip:"无限",
        // svip:"无限",
      }
    ],
    "产品功能":[
      {
        introduction:"AI对话",
        freeVersion:"30次",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"AI对话历史",
        freeVersion:"5",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"文档数量",
        freeVersion:"5",
        vip:"无限",
        // svip:"无限",
      },
      {
        introduction:"下载文件",
        freeVersion:"无",
        vip:"无限",
        // svip:"无限",
      }
    ],
  }
]

export const fontList = [
  {
    "免费版":[
      {
        font:"1万字",
        price:"¥9.9/万字"
      },
      {
        font:"5万字",
        price:"¥11.98/万字"
      },
      {
        font:"10万字",
        price:"¥10.99/万字"
      },
    ]
  },
  {
    "会员版":[
      {
        font:"1万字",
        price:"¥9.9/万字"
      },
      {
        font:"10万字",
        price:"¥8.9/万字"
      },
      {
        font:"20万字",
        price:"¥6.95/万字"
      },
    ]
  }
]