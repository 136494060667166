import { wlyAxios } from "./composables/chatRequest"

export function reqUserLog(info,isClick,clientId,deviceType,phoneNumber) {
  return new Promise((resolve) => {
    wlyAxios({needToken: false})
      .post(`/bp_custom/init/log/`,{
        "info":info,
        "click":isClick,
        "client_id":clientId,
        "device_type":deviceType,
        "phone_number":phoneNumber
      })
      .then(({code, data})=>resolve([code, data]))
      .catch(({code})=>resolve([code, undefined]))
  })
}