import { createSlice } from "@reduxjs/toolkit";

export const globelMessageSlice = createSlice({
  name: "globalMessage",
  initialState: {
    notifications: "",
    messageHistory: [],
  },
  reducers: {
    // 接收到新消息提醒
    messageReminding: (state, action) => {
      state.notifications = action.payload;
    },
    // 存储消息历史
    saveMessageHistory: (state, action) => {
      state.messageHistory = action.payload;
    },
  },
});

export const { messageReminding, saveMessageHistory } = globelMessageSlice.actions;

export default globelMessageSlice.reducer;
