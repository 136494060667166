/*
 * @Author: SpaceDandy13 liudonglin301@gmail.com
 * @Date: 2023-10-26 19:48:43
 * @LastEditors: SpaceDandy13 liudonglin301@gmail.com
 * @LastEditTime: 2023-11-10 20:19:45
 * @FilePath: \ChatBP-Front\Front-end\src\composables\chatRequest.js
 * @Description: 
 */
// import { message } from "antd";
import axios from "axios";
import { reportErr } from "./reports";
// import {useErrCodeHook} from "./errCodeHook";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;

function defaultErrorHandle() {
  // nothing
}

/**
 * 自动组装token，封装请求，仅在特定状态码下返回成功data；
 * 不管有没有token，都会发送请求，只是有token时，走正常的判断逻辑；
 * 没有token时，走普通的axios请求，但无论什么错误，都将被视为缺少token处理；
 * 如果你需要在没有token时彻底不发请求，应该在调用此函数前判断token，然后不要执行该函数，
 * 而不是在该函数来判断有没有token并希望该函数自动取消请求。
 *
 * 例子 参考定义文件的下文
 *
 * @param handle401Func
 * @param handle422Func
 * @param handle500Func
 * @returns {{msg: string, code: number, data: {}}|boolean|axios.AxiosInstance}
 */
export function newHYAxios({handle401Func=defaultErrorHandle, handle422Func=defaultErrorHandle, handle500Func=defaultErrorHandle}={}) {
  try {
    const token = localStorage.getItem("_hongyao");
    if(!token) {
      // window.alert("获取token失败，请尝试重新登录");  // 以后按需使用
      const ins = axios.create();
      ins.interceptors.response.use(
        ({data}) => data,
        () => {
          return Promise.reject({
            code: -10,
            msg: "获取token失败，请尝试重新登录",
            data: {}
          });
        }
      );
      return ins;
    }
    const xWindowId = sessionStorage.getItem("X-Win-Id");
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Win-Id": xWindowId, // 当sessionStorage还没有"X-Window-Id"时, xWindowId为null, headers不会包含"X-Window-Id"字段
      },
      // timeout: 10000, // (因为现在有长时间接口，暂时废弃) 默认10秒超时，在处理特长时间响应的接口时应当手动设置timeout时间
      validateStatus(status) {
        /**
         * 200 成功
         * 401 token过期
         * 422 请求实体错误
         * 500 服务端崩溃
         */
        return [200, 401, 422, 500].indexOf(status) !== -1
      }
    })

    instance.interceptors.response.use(
      (res)=>{
        const {status, data} = res

        if (status === 200) {
          // data 里面的
          return data
        } else if(status === 401) {
          // 由401回调函数控制
          window.alert(`登录状态过期，请重新登录`)
          // message.error(`登录状态过期，请重新登录`).then()
          localStorage.removeItem("_hongyao")
          setTimeout(()=>{
            window.location.href = '/'
          }, 1000)
          if (handle401Func) {
            handle401Func()
          }
        } else if(status === 422) {
          // 由422回调函数控制
          if (handle422Func) {
            handle422Func()
          }
        } else if(status === 500) {
          if (handle500Func) {
            handle500Func()
          }
        }

        return data;
      },
      (error) => {
        console.error("意料之外的响应", error);
        // message.error("服务异常").then()
        if (error.response) {
          // 响应未经过 validateStatus 验证的
          console.error("服务器发生未知错误")
        } else if (error.request) {
          // The request was made but no response was received
          console.error("客户端请求发生错误");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        return Promise.reject(error);
      }
    )

    return instance
  } catch(err) {
    // 有可能出现访问LocalStorage出错的情况
    window.alert("获取token失败");
    // message.error(`获取token失败`).then();
    return ({
      code: -10,
      msg: "获取token失败",
      data: {}
    })
  }
}


export const NO_TOKEN_CODE = -100;
export const NO_X_WIN_ID_CODE = -110;
export const UNKNOWN_CODE = -200;
export const PROCESS_NEEDLESS_CODE = -300; // 无需后续处理

let wlyAxios401LastTime = new Date(0);
// wlyAxios
// 只有resolve，没有reject
export function wlyAxios({needToken = true, needXWinId = false} = {}) {
  const token = localStorage.getItem("_hongyao");
  const xWindowId = sessionStorage.getItem("X-Win-Id");

  if (needToken && !token) {
    const rejection = ()=>Promise.resolve({code: NO_TOKEN_CODE, data: undefined})
    return ({
      get: rejection,
      post: rejection,
      put: rejection,
      delete: rejection,
    })
  }

  if (needXWinId && !xWindowId) {
    const rejection = ()=>Promise.resolve({code: NO_X_WIN_ID_CODE, data: undefined})
    return ({
      get: rejection,
      post: rejection,
      put: rejection,
      delete: rejection,
    })
  }

  const ins = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: token && `Bearer ${token}`, // 需要token的时候才带token
      "X-Win-Id": xWindowId, // 当sessionStorage还没有"X-Window-Id"时, xWindowId为null, headers不会包含"X-Window-Id"字段
    },
    validateStatus(status) {
      /**
       * 200 成功
       * 401 token过期
       * 422 请求实体错误
       * 500 服务端崩溃
       */
      // return [200, 401, 422, 500].indexOf(status) !== -1;
      return [200, 401].indexOf(status) !== -1;
      // 如果不是上述错误码，将会落到ins.interceptor.response的onRejected回调
    }
  });

  ins.interceptors.response.use(
    ({status, data}) => {
      if (status === 200) {
        // 下面这个data就是{code, msg, data} （如果后端按照正常情况返回的话）
        return data;
      } else if (status === 401) {
        if (token) {
          // 携带了token的请求如果响应401说明token过期，需要重新登录。
          const now = new Date();
          if (now - wlyAxios401LastTime < 1000) {
            return {code: PROCESS_NEEDLESS_CODE, data: undefined};
          }
          wlyAxios401LastTime = now;
          window.alert("登录状态过期，请重新登录");
          // message.error("登录状态过期，请重新登录").then();
          localStorage.removeItem("_hongyao");
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
        return {code: PROCESS_NEEDLESS_CODE, data: undefined};
      } else {
        return {code: UNKNOWN_CODE, data: undefined};
      }
    },
    (error) => {
      try {
        // 获取请求信息
        const requestInfo = {
          url: error.request ? error.request.responseURL : 'unknown',
          method: error.config ? error.config.method : 'unknown',
          headers: error.config ? error.config.headers : 'unknown',
          data: error.config ? error.config.data : 'unknown',
        };
  
        // 获取响应信息（如果存在）
        const responseInfo = error.response ? {
          status: error.response.status,
          headers: error.response.headers,
          data: error.response.data,
        } : null;
  
        // 其他附加信息
        const additionalInfo = {
          error: error.toString(),
          stack: error.stack,
          current_page: window.location.href,
        };
  
        // 根据不同的错误类型记录日志
        if (error.response) {
          console.error("服务器发生未知错误", error);
          reportErr(
            "NETWORK ERROR", `响应状态码未通过 validateStatus 验证的`,
            {requestInfo, responseInfo, additionalInfo}
          );
        } else if (error.request) {
          console.error("客户端请求发生错误", error);
          reportErr(
            "NETWORK ERROR", `客户端请求发生错误，未收到响应`,
            {requestInfo, responseInfo, additionalInfo}
          );
        } else {
          console.error("创建请求时发生错误", error);
          reportErr(
            "NETWORK ERROR", `创建请求时发生错误`,
            {requestInfo, responseInfo, additionalInfo}
          );
        }
      } catch (err) {
        // 捕获未知错误并记录
        reportErr(
          "NETWORK ERROR", `未知错误`,
          {additionalInfo: {error: err.toString(), originalError: error.toString()}}
        );
      }
      return {code: UNKNOWN_CODE, data: undefined};
    }
  );

  return ins;
}

export function reqExample() {
  return new Promise((resolve) => {
    // 关键：只有resolve没有reject，这样reqExample的消费者可以await拿到所有情况
    wlyAxios({needToken: true})
      .get(`/`)
      .then(({code, data}) => {
        resolve([code, data]);
        // 关键： 规范： 先code后data的数组
        // 注意结构： data = {code, msg, data}
      });
  });
}

export async function doSthExample() {
  // const {handleErrCode} = useErrCodeHook(); - 仅在react component 中使用
  const [errCode, res] = await reqExample();
  console.log(errCode, res);
  if (errCode === 0) {
    // 一切正常
    console.log(res);
  } else if (errCode === UNKNOWN_CODE) {
    // 其他情况 ...
  } else {
    // handleErrCode(errCode);
  }
  // ......
}