import {createSlice} from "@reduxjs/toolkit";

const getBlankHistory = () => [
  {id: 0, type: "ai", message: "请输入您的想法"},
  {id: 1, type: "human", message: ""},
  {id: 2, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 3, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 4, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 5, type: "human", message: ""}
];

// 该SLICE的状态仅为 QAProfession这一个组件服务（其他地方可以执行action）
const QAProjectSlice = createSlice({
  name: "QAProject",
  initialState: {
    toggle: "close", // open close
    chatId: "",
    screenStatus: "OUTLINE",   // IDEA | DETAIL | OUTLINE | GEN
    history: getBlankHistory(),
    template: "",
    templateDone: false,
  },
  reducers: {
    open: (state, action) => {
      state.toggle = "open";
      return state;
    },
    openNew: (state, action) => {
      const chatId = action.payload;
      state.toggle = "open";
      state.chatId = chatId;
      state.screenStatus = "IDEA";
      state.history = getBlankHistory();
      state.template = "";
      state.templateDone = false;
      return state;
    },
    close: (state, action) => {
      state.toggle = "close";
      return state;
    },
    setScreenStatus: (state, action) => {
      if (["IDEA", "DETAIL", "GEN"].indexOf(action.payload) === -1) {
        console.error("不支持的screenStatus", action.payload);
      }
      state.screenStatus = action.payload;
      return state;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
      return state;
    },
    setMessage: (state, action) => {
      const {message, historyIndex} = action.payload;
      state.history[historyIndex].message = message;
      return state;
    },
    setMessageWaiting: (state, action) => {
      state.history[2].waiting = action.payload;
      state.history[3].waiting = action.payload;
      state.history[4].waiting = action.payload;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
      return state;
    },
    appendTemplate: (state, action) => {
      // 设置检查chatId是防止上一篇文章的生成内容继续填在此处。
      const {content, chatId} = action.payload;
      if (chatId !== state.chatId) {
        return state;
      }
      state.template = state.template + content;
      return state;
    },
    setTemplateDone: (state, action) => {
      state.templateDone = action.payload;
      return state;
    },
    init: (state) => {
      return {
        toggle: "close", // open close hidden
        chatId: "",
        screenStatus: "IDEA",
        history: getBlankHistory(),
        template: "",
        templateDone: false,
      };
    }
  }
});

export default QAProjectSlice.reducer;

export const {
  open,
  openNew,
  close,
  setScreenStatus,
  setHistory,
  setMessage,
  setMessageWaiting,
  setTemplate,
  appendTemplate,
  setTemplateDone,
  init,
} = QAProjectSlice.actions;