import axios from "axios";

const REPORT_URL = process.env.REACT_APP_REPORT_URL;

export function reportErr(type, message, {requestInfo, responseInfo, clientInfo, additionalInfo}) {
  axios
    .post(REPORT_URL+`/log/tracking/errors/`, {
      type,
      message,
      "user_token": `Bearer ${localStorage.getItem("_hongyao")}`,
      "request_info": requestInfo || {
        // "method": "GET",
        // "url": "/chat/getChatList/",
        // "headers": {
        //   "Content-Type": "application/json",
        //   "Authorization": "Bearer [Token]"
        // },
        // "body": null
      },
      "response_info": responseInfo || {
        // "status": null,
        // "statusText": "Network Error",
        // "headers": null,
        // "data": null
      },
      "client_info": clientInfo || {
        "browser": getBrowserTypeMethod1() + " " + getBrowserTypeMethod2(),
        "os": getUserOsInfo(),
        "device": "Desktop"
      },
      "additional_info": additionalInfo || {
        // "current_page": window.location || "",
        // "action": "Fetching user profile data"
      },
      "timestamp": (new Date()).toISOString(),
    })
    .then()
    .catch((err) => {
      console.error("汇报异常", err);
    });
}


function getUserOsInfo() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Windows NT 10.0") !== -1) return "Windows 10";
  if (userAgent.indexOf("Windows NT 6.2") !== -1) return "Windows 8";
  if (userAgent.indexOf("Windows NT 6.1") !== -1) return "Windows 7";
  if (userAgent.indexOf("Windows NT 6.0") !== -1) return "Windows Vista";
  if (userAgent.indexOf("Windows NT 5.1") !== -1) return "Windows XP";
  if (userAgent.indexOf("Windows NT 5.0") !== -1) return "Windows 2000";
  if (userAgent.indexOf("Mac") !== -1) return "Mac/iOS";
  if (userAgent.indexOf("X11") !== -1) return "UNIX";
  if (userAgent.indexOf("Linux") !== -1) return "Linux";
  return "Other";
}


// 获取浏览器类型
function getBrowserTypeMethod1() {
  let ua = navigator.userAgent.toLocaleLowerCase();
  let browserType = "";
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    browserType = "IE";
  } else if (ua.match(/firefox/) != null) {
    browserType = "firefox";
  } else if (ua.match(/ucbrowser/) != null) {
    browserType = "UC";
  } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
    browserType = "opera";
  } else if (ua.match(/bidubrowser/) != null) {
    browserType = "baidu";
  } else if (ua.match(/metasr/) != null) {
    browserType = "sougou";
  } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
    browserType = "QQ";
  } else if (ua.match(/maxthon/) != null) {
    browserType = "maxthon";
  } else if (ua.match(/chrome/) != null) {
    // var is360 = _mime('type', 'application/vnd.chromium.remoting-viewer')
    // if (is360) {
    //   browserType = '360'
    // } else {
    //   browserType = 'chrome'
    // }
    browserType = "chrome";
  } else if (ua.match(/safari/) != null) {
    browserType = "Safari";
  } else {
    browserType = "others";
  }
  return browserType;
}

// https://www.jianshu.com/p/e633ffeb8d93
function getBrowserTypeMethod2() {
  var u = navigator.userAgent;

  var bws = [{
    name: "sgssapp",
    it: /sogousearch/i.test(u)
  }, {
    name: "wechat",
    it: /MicroMessenger/i.test(u)
  }, {
    name: "weibo",
    it: !!u.match(/Weibo/i)
  }, {
    name: "uc",
    it: !!u.match(/UCBrowser/i) || u.indexOf(" UBrowser") > -1
  }, {
    name: "sogou",
    it: u.indexOf("MetaSr") > -1 || u.indexOf("Sogou") > -1
  }, {
    name: "xiaomi",
    it: u.indexOf("MiuiBrowser") > -1
  }, {
    name: "baidu",
    it: u.indexOf("Baidu") > -1 || u.indexOf("BIDUBrowser") > -1
  }, {
    name: "360",
    it: u.indexOf("360EE") > -1 || u.indexOf("360SE") > -1
  }, {
    name: "2345",
    it: u.indexOf("2345Explorer") > -1
  }, {
    name: "edge",
    it: u.indexOf("Edge") > -1
  }, {
    name: "ie11",
    it: u.indexOf("Trident") > -1 && u.indexOf("rv:11.0") > -1
  }, {
    name: "ie",
    it: u.indexOf("compatible") > -1 && u.indexOf("MSIE") > -1
  }, {
    name: "firefox",
    it: u.indexOf("Firefox") > -1
  }, {
    name: "safari",
    it: u.indexOf("Safari") > -1 && u.indexOf("Chrome") === -1
  }, {
    name: "qqbrowser",
    it: u.indexOf("MQQBrowser") > -1 && u.indexOf(" QQ") === -1
  }, {
    name: "qq",
    it: u.indexOf("QQ") > -1
  }, {
    name: "chrome",
    it: u.indexOf("Chrome") > -1 || u.indexOf("CriOS") > -1
  }, {
    name: "opera",
    it: u.indexOf("Opera") > -1 || u.indexOf("OPR") > -1
  }];

  for (var i = 0; i < bws.length; i++) {
    if (bws[i].it) {
      return bws[i].name;
    }
  }
  return "other";
}