export function makeUUID(chatId, guideId, historyIdx) {
  return JSON.stringify({
    c: chatId,
    g: guideId,
    h: historyIdx
  })
}

export function destructUUID(uuid) {
  const {c: chatId, g: guideId, h:historyIdx} = JSON.parse(uuid);
  return {chatId, guideId, historyIdx}
}

/**
 *
 * @param cI chapterIndex
 * @param sI sectionIndex
 * @param sSI subSectionIndex
 * @param pI paragraphIndex
 * @returns {string}
 */
export function makeProDocParagraphUUID(cI, sI, sSI, pI) {
  return JSON.stringify({
    c: cI,
    s: sI,
    ss: sSI,
    p: pI
  });
}

export function destructProDocParagraphUUID(uuid) {
  const {c: cI, s: sI, ss: sSI, p: pI} = JSON.parse(uuid);
  return {cI, sI, sSI, pI};
}