/**
 * 转换string成html， \n 标记 <br/>
 * @param {string} text
 * @returns {*|string}
 */
export function parseBackSlashNtoBr(text) {
  if (typeof(text)!=='string') {
    return text
  }
  const textArray = text.split("\n")
  return textArray.join(`<br/>`)
}