
import { QRCode, Space, Spin, App} from "antd";
import { useEffect, useRef, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../common/ModalWrapper";
import { useErrCodeHook } from "../../composables/errCodeHook";
import { paySuccessed } from "../../reducers/paySlice";
import { closeShop, setRechargeMember } from "../../reducers/shopSlice";
import { setUserInfo } from "../../reducers/userSlice";
import { reqUserInfo } from "../Header/network";
import FontList from "./FontList";
import MemberList from "./MemberList";
import "./Recharge.css";
import { useMemberProductList, useProductList } from "./hook";
import { reqAliCodeUrl, reqWxCodeUrl } from "./network";

const Recharge = () => {
  const user = useAuthUser();
  const dispatch = useDispatch();
  const {message} = App.useApp();
  const {handleErrCode} = useErrCodeHook();
  const data = useSelector(state => state.pay.paySuccess);
  const userLevel = useSelector(s=>s.user.userInfo?.premium_level)
  const [rechargeType,setRechargeType] = useState("MEMEBER");
  const rechargeMember = useSelector(s=>s.shop.rechargeMember);
  const [selected, setSelected] = useState(0);
  const [wxQRcode, setWxQRcode] = useState("");
  const [aliQRcodeUrl, setAliQRcodeUrl] = useState(null);
  const {list: products, waiting} = useProductList();//字数商品列表
  const {list:memberProducts, waiting:memberWaiting} = useMemberProductList();//会员商品列表
  const [currentProducts,setCurrentProducts] = useState(memberProducts);
  const [currentProduct,setCurrentProduct] = useState(null); //现在点击的商品
  const [comboPrice, setComboPrice] = useState();//套餐价格
  const [discounts, setDiscounts] =useState();//一共优惠多少
  const [canClick, setCanClick] = useState(true);//控制是否可点击，避免频繁点击，二维码对应套餐错误
  
  const timerRef = useRef(null);
  const [countdown, setCountdown] = useState();
  const [timerRunning, setTimerRunning] = useState(false);
  const [paymentType, setPaymentType] = useState("wxpay");
  useEffect(() => {
    if (data) {
      message.open({
        type: "success",
        content: data,
      });
      dispatch(closeShop())
      dispatch(paySuccessed(""));
      updateInfo();
    }
    // eslint-disable-next-line
  }, [data,dispatch]);
  
  // TODO:定时器是假的，每次选择都会重置时间
  const startCountdown = (seconds) => {
    if (timerRunning) {
      clearInterval(timerRef.current);
    }

    setCountdown(seconds);
    setTimerRunning(true);

    timerRef.current = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);

      if (countdown === 0) {
        clearInterval(timerRef.current);
        setTimerRunning(false);
      }
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line
  }, []);

  function timeString(duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;
    const timeString = formatTime(hours) + ":" + formatTime(minutes) + ":" + formatTime(seconds);
    return timeString; 
  }
  
  function formatTime(time) {
    return time < 10 ? "0" + time : time;
  }

  //设置默认选择列表第一个商品
  useEffect(()=>{
    if(selected===0 && currentProducts){
      const filterProducts = currentProducts.filter(({availableLevel})=>{
        if(availableLevel){
          if(userLevel === "Level 0"){
            return availableLevel === userLevel
          }else{
            return availableLevel !== "Level 0"
          }
        }else{
          return true
        }
      })
      let shouldBreak = false;
      filterProducts.forEach(({pNo,canBeBuy,discounts,price},index)=>{
        if(selected===index&&canBeBuy){
          handleSelected({pNo,canBeBuy,discounts,price,index})
          shouldBreak = true;
        }else if(index>=selected&&canBeBuy&&!shouldBreak){
          handleSelected({pNo,canBeBuy,discounts,price,index})
          shouldBreak = true;
        }
      })
    }
    // eslint-disable-next-line
  },[currentProducts])

  useEffect(()=>{
    if(rechargeMember){
      setRechargeType("MEMBER");
    }
  },[rechargeMember])

  useEffect(()=>{
    switch(rechargeType){
      case "FONT":
        setCurrentProducts(products);
        break;
      case "MEMBER":
        setCurrentProducts(memberProducts);
        break;
      default:
    }
  },[products,memberProducts,rechargeType])

  useEffect(()=>{
    setSelected(0)
  },[rechargeType])

  const handleChangeType = (e,type) => {
    e.stopPropagation();
    e.preventDefault();
    
    if(type === "MEMBER"){
      setRechargeType(type)
      setWxQRcode(null);
    }
    if(type === "FONT"){
      setRechargeType(type)
      setWxQRcode(null);
    }
  }

  async function updateInfo() {
    const [code,data] = await reqUserInfo();
    handleErrCode(code);
    if(code === 0) {
      dispatch(setUserInfo(data.user_info));
    }
  }

  useEffect(() => {
    if (currentProduct) {
      handleSelected(currentProduct);
    }
    // eslint-disable-next-line
  }, [paymentType]);

  async function handleSelected({pNo,canBeBuy,discounts,price,index}){
    if(canBeBuy){
      setSelected(index);
      setComboPrice(price)
      setDiscounts(discounts)
      startCountdown(2 * 60 * 60);
      setCurrentProduct({pNo,canBeBuy,discounts,price,index})
      setWxQRcode(null);
      setAliQRcodeUrl(null);
      if (paymentType === "wxpay") {
        const [code, data] = await reqWxCodeUrl(pNo, setCanClick);
        handleErrCode(code);
        if (code === 0) {
          const { codeUrl } = data;
          setWxQRcode(codeUrl);
        }
      } else {
        const [code, data] = await reqAliCodeUrl(pNo, setCanClick);
        handleErrCode(code);
        if (code === 0) {
          const { codeUrl } = data;
          setAliQRcodeUrl(codeUrl);
        }
      }
      setCanClick(true);
    } else {
      message.open({
        type: "warning",
        content: '不能再次购买',
      });
    }

  };

  return (
    <ModalWrapper classes={["recharge-pay"]}>
      <div className="recharge-pay">
        <main data-recharge={rechargeType==="MEMBER"?true:false}>
          <header>
            <img src="/icons/common/user_avatar.svg" alt=""></img>
            <div>
              <span>
                {user()?.username.substring(0, 3) + '****' + user()?.username.substring(7)}
                {
                  userLevel==="Level 0"&&<img src="/icons/recharge/diamond.svg" alt=""></img>
                }
                {
                  userLevel==="Level 1"&&<img src="/icons/recharge/diamond_yellow.svg" alt=""></img>
                }
                {
                  userLevel==="Level 2"&&<img src="/icons/recharge/diamond_blue.svg" alt=""></img>
                }
              </span>
              <span>升级享全部会员权益</span>
            </div>
          </header>
          <main>
            <header data-recharge={rechargeType==="MEMBER"?true:false}>
              <section onClick={e=>canClick&&handleChangeType(e,"MEMBER")} style={rechargeType==="MEMBER"?({backgroundImage:"url(/images/vip_recharge1.svg)"}):null}>
                <span>会员充值</span>
                <span>3天体验版/会员</span>
              </section>
              <div></div>
              <section disable={!canClick} onClick={e=>canClick&&handleChangeType(e,"FONT")} style={rechargeType==="FONT"?({backgroundImage:"url(/images/vip_recharge2.svg)"}):null}>
                <span>字数充值</span>
                <span>
                  {
                    userLevel==="Level 0"&&<span>普通用户</span>
                  }
                  {
                    userLevel!=="Level 0"&&<span>会员</span>
                  }
                  
                </span>
                
              </section>
            </header>
            {
              (waiting || memberWaiting) 
              ? <div><Spin></Spin></div>
              : <>
                  <section data-recharge={rechargeType==="MEMBER"?true:false}>
                    <div>
                    {
                      currentProducts

                      // 原来的
                        // .filter((item) => {
                        //   // 检查是否存在 availableLevel
                        //   if (item.availableLevel) {
                        //     // 如果存在，则筛选出与用户等级相匹配的数据
                        //     return userLevel==="Level 0"?item.availableLevel === userLevel:item.availableLevel!=="Level 0";
                        //   } else {
                        //     // 如果不存在 availableLevel，则保留所有数据
                        //     return true;
                        //   }
                        // })


                        //筛选掉svip商品
                        .filter((item) => {
                          // 检查是否存在 availableLevel
                          if (item.availableLevel) {
                            // 如果存在，则筛选出与用户等级相匹配的数据
                            return userLevel === "Level 0"?item.availableLevel === userLevel:item.availableLevel!=="Level 0" && item.title !== "SVIP大会员";
                          } else {
                            // 如果不存在 availableLevel，则保留所有数据，除了 title 为 "SVIP大会员" 的数据
                            return item.title !== "SVIP大会员";
                          }
                        })
                        .map(({ pNo, price,remarks, title, canBeBuy,duration,discounts,tokenCount,onlyBuyOnce},index) => {
                          return (
                            <div key={`${price}_${index}`} onClick={e=>{ e.preventDefault(); canClick&&handleSelected({pNo,canBeBuy,discounts,price,index})}} data-selected={selected===index?true:false} style={{cursor:canBeBuy?"pointer":"not-allowed"}}>
                              {
                                onlyBuyOnce===1&&<span>{canBeBuy===1?"仅限首次购买":"已购买"}</span>
                              }
                              {
                                (title==="会员版"&&discounts&&onlyBuyOnce===0)&&<span>{canBeBuy?`立减${discounts}元`:"已购买"}</span>
                              }
                              {
                                (title==="SVIP大会员"&&onlyBuyOnce===0)&&<span>{canBeBuy?"每日限10个名额":"已购买"}</span>
                              }
                              {
                                title==="SVIP大会员" ? rechargeType==="MEMBER"&&<img src="/icons/recharge/svip_diamon.svg" alt=""></img>:rechargeType==="MEMBER"&&<img src="/icons/recharge/vip_diamon.svg" alt=""></img> 
                              }
                              <main>
                                {
                                 rechargeType==="MEMBER" &&
                                  <div>
                                    <span>￥</span>
                                    <span>{price}/</span>
                                    {duration===3&&<span><span>3</span>天</span>}
                                    {duration===30&&<span>月</span>}
                                    {duration===365&&<span>年</span>}
                                  </div>
                                }
                                {
                                 rechargeType==="FONT" &&
                                  <div>
                                    <span></span>
                                    <span>{tokenCount}万字</span>
                                  </div>
                                }
                                <span data-recharge={rechargeType==="MEMBER"?true:false}>￥{rechargeType==="MEMBER"?remarks:price}</span>
                              </main>
                              {
                                remarks === "59.90" &&
                                <div>
                                  <img src="/icons/recharge/gift.svg" alt=""></img>
                                  <span>赠送20万使用字数</span>
                                </div>
                              }
                              {
                                remarks === "2288" &&
                                <div>
                                  <img src="/icons/recharge/gift.svg" alt=""></img>
                                  <span>赠送2000万使用字数</span>
                                </div>
                              }
                              {
                                remarks === "1078" &&
                                <div>
                                  <img src="/icons/recharge/gift.svg" alt=""></img>
                                  <span>赠送150万使用字数</span>
                                </div>
                              }
                              {
                                rechargeType==="FONT" &&
                                <p data-selected={selected===index?true:false}>低至￥{(price/tokenCount).toFixed(2)}元/万字</p>
                              }
                            </div>
                          )
                        })
                    }   
                    {rechargeType==="MEMBER"&&<span>VIP赠送的字数与VIP有效期同步，VIP过期后额外赠送的字数清零</span>}              
                    </div>
                    {rechargeType==="FONT" &&
                    <ul>
                      <li><img src="/icons/recharge/data_deside.svg" alt=""></img><span>数据赋能决策与创新</span></li>
                      <li><img src="/icons/recharge/support.svg" alt=""></img><span>创业启动的支持</span></li>
                      <li><img src="/icons/recharge/plan.svg" alt=""></img><span>定制商业计划</span></li>
                    </ul>}
                  </section>
                  <footer data-recharge={rechargeType==="MEMBER"?true:false}>
                    {
                      rechargeType==="MEMBER"&&<MemberList></MemberList>
                    }
                    {
                      rechargeType==="FONT"&&<FontList></FontList>
                    }
                  </footer>
                </>
            }
          </main>
        </main>
        <aside data-recharge={rechargeType==="MEMBER"?true:false}>
          {
            rechargeType==="MEMBER"
            ? <img src="/icons/recharge/line.svg" alt=""></img>
            : <img src="/icons/recharge/line_font.svg" alt=""></img>
          }
          <img src="/icons/recharge/close.svg" alt="" className="close" onClick={(e) => {e.preventDefault();dispatch(closeShop());
          dispatch(setRechargeMember(false));}}></img>
          <header>
            <span>支付</span>
            <span>￥<span>{comboPrice}</span></span>
            {discounts&&<div>已优惠￥{discounts}元<img src="/icons/recharge/warning.svg" alt=""></img></div>}
          </header>
          <main>
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <Space wrap >
                {paymentType === "wxpay" && wxQRcode ? (
                  <QRCode value={wxQRcode} size={152} style={{ padding: "10px" }} />
                ) : paymentType === "alipay" && aliQRcodeUrl ? (
                  <iframe 
                    src={aliQRcodeUrl} 
                    width="159" 
                    height="159" 
                    style={{ 
                      padding: "10px", 
                      border: "none", 
                      display: 'block', 
                      margin: '0 auto', 
                      overflow: 'hidden'
                    }} 
                    title="Alipay QR Code"
                  ></iframe>
                ) : (
                <QRCode value="https://ant.design/" status="loading" size={152} style={{padding:"10px"}}/>
                )}
              </Space>
            </div>
            {
              paymentType==="wxpay"?
              <span><img src="/icons/recharge/wxpay.svg" alt=""></img>微信支付</span>
              :
              <span style={{left:"79px"}} ><img src="/icons/recharge/alipay.svg" alt=""></img>支付宝支付</span>
            }
            <span><img src="/icons/recharge/time.svg" alt=""></img>支付剩余时间：{timeString(countdown)}</span>
            <span onClick={() => {
              if (canClick) {
                const newPaymentType = paymentType === "wxpay" ? "alipay" : "wxpay";
                setPaymentType(newPaymentType);
              }
            }}>
              <img src={`/icons/recharge/${paymentType === "wxpay" ? "alipay" : "wxpay"}.svg`} alt=""></img>
              {paymentType === "wxpay" ? "切换支付宝支付" : "切换微信支付"}
            </span>
          </main>
          <footer>
            使用支付宝/微信扫码支付,支付表示您已阅读同意
            <span>《服务条款》</span>
          </footer>
        </aside>
      </div>
    </ModalWrapper>
    
  )
}

export default Recharge;
