import React, { useEffect, useMemo, useState } from "react";
import "./../QA.css";
import "./loadingModel.css";

export default function LoadingModal({
                                       percent, footer,modalType="", onClickClose = () => {
  }
                                     }) {
  const showPercent = useMemo(() => {
    if (percent === undefined || typeof (percent) !== "number") {
      return 1;
    } else if (percent < 0) {
      return 0;
    } else if (percent > 100) {
      return 100;
    }
    return percent.toFixed(0);
  }, [percent]);

  const [timeLeft, setTimeLeft] = useState(200);

  useEffect(() => {
    const timer = setInterval(()=>{
      setTimeLeft(t=> {
        return t > 0 ? --t : 0;
      });
    }, 1000)
    return ()=>clearInterval(timer)
  }, [timeLeft]);

  return (<>
    <div className={"loading-modal"} data-type={modalType}>
      <div className={"container"} data-type={modalType}>
        {modalType!=="mobile"&&
          <header>
            <button className={"close"}
                    onClick={onClickClose}>×
            </button>
          </header>
        }
        <main className={"loading-modal-main"}>
            {/* <div className={"time-left"}>{showPercent >= 100 ? 0 : timeLeft}s 剩余时间<img alt={""} src={"/icons/clock.svg"}/></div> */}
            {showPercent >= 100 ? <p>生成完成</p> : <p>正在生成商业计划书</p>}
            <div className={"percent-to-complete"}>{showPercent}% to Complete</div>
            <div className={"progress-bar-out"}>
              <div className={"progress-bar-in-dark"}>
                <div className={"progress-bar-in"}
                     style={{
                       transform: `translateX(calc(-100% + ${showPercent}%))`
                     }}
                />
              </div>
            </div>
        </main>
        <footer>
          {footer}
        </footer>
      </div>
    </div>
  </>);
}