import {createSlice} from "@reduxjs/toolkit";

export const makeEmptyParagraph = () => ({
  paragraphId: "", // TODO 这个由前端创建的ID如何与后端保持一致？
  paragraphName: "",
  paragraphContent: {
    slateContent: [{ type: "paragraph", children: [{ text: "" }] }], 
    tableContent: {rows:[],headers:[]}
  },
});

export const makeEmptySubSection = () => ({
  subSectionId: "",
  subSectionName: "",
  paragraphs: [
    // makeEmptyParagraph()
  ]
});

export const makeEmptySection = () => ({
  sectionId: "",
  sectionName: "",
  questionnaireId: "",
  status: "NOT_STARTED", // NOT_STARTED | QN_DONE | IN_PROGRESS | DONE
  editing: false,
  folding: false, // 是否折叠
  subSections: [
    // makeEmptySubSection()
  ]
});

export const makeEmptyChapter = ()=>({
  chapterId: "",
  chapterName: "",
  sections: [
    // makeEmptySection()
  ]
});

export const makeEmptyDoc = () => ({
  docId: "",
  docName: "",
  loading: true,
  activeEditor:{
    activeEditorId:null,
    activeEditorList:[],
    deleteEditorList:[]
  },
  isUndo:false,
  isRedo:false,
  chapters: [
    // makeEmptyChapter()
  ],
  canCardToEdit:[],//标识该章节是否全部完成（index对应cI）
  pptChapters:[] //存储ppt生成需要的章节总结
});

const proDocSlice = createSlice({
  name: "proDoc",
  initialState: makeEmptyDoc(),
  // initialState: {...makeEmptyDoc(), docId: 'docId'}, 调试用
  reducers: {
    // 命名注意：单个I指代的是index，Id指代的才是id
    // 统一命名：cI=chapterIndex, sI=sectionIndex, sSI=subSectionIndex, pI=paragraphIndex
    // 统一命名：cId=chapterId, sId=sectionId, sSId=subSectionId, pId=paragraphId
    proDocUpdateParagraphContent: (state, action) => {
      const {cI, sI, sSI, pI, content} = action.payload;
      const p = state?.chapters[cI]?.sections[sI]?.subSections[sSI]?.paragraphs[pI];
      if (p === undefined) {
        console.log("paragraph not found, payload : ", action.payload);
        return state;
      }
      if(typeof content === "string") {
        p.paragraphContent.slateContent = [{ type: "paragraph", children: [{ text: content }] }];
      }else if((typeof content === "object" && content.hasOwnProperty("rows")) || Object.keys(content).length === 0) {
        p.paragraphContent.tableContent = content;
      }else{
        p.paragraphContent.slateContent = content;
      }
      
      return state;
    },
    // 插入段落，请使用makeParagraph函数创建的段落来插入。
    proDocInsertParagraph: (state, action) => {
      const {cI, sI, sSI, pI, paragraph} = action.payload;
      const subSection = state?.chapters[cI]?.sections[sI]?.subSections[sSI];
      if (subSection === undefined) {
        console.log("subSection not found, payload : ", action.payload);
        return state;
      }
      if (pI < 0 || subSection.paragraphs.length < pI) {
        console.log("paragraph index out of range, payload : ", action.payload);
        return state;
      }
      subSection.paragraphs = [...subSection.paragraphs.slice(0, pI), paragraph, ...subSection.paragraphs.slice(pI)];
      return state;
    },
    //删除段落
    proDocDeleteParagraph: (state, action) => {
      const {cI, sI, sSI, pI} = action.payload;
      
      const subSection = state?.chapters[cI]?.sections[sI]?.subSections[sSI];
      if (subSection === undefined) {
        console.log("subSection not found, payload : ", action.payload);
        return state;
      }
      if (pI < 0 || subSection.paragraphs.length < pI) {
        console.log("paragraph index out of range, payload : ", action.payload);
        return state;
      }
      if(pI > 0 && pI < subSection.paragraphs.length - 1 ){//处理处于中间的段落
        subSection.paragraphs = [...subSection.paragraphs.slice(0, pI), ...subSection.paragraphs.slice(pI + 1,subSection.paragraphs.length)]
      }else if(pI === subSection.paragraphs.length - 1) {
        subSection.paragraphs = [...subSection.paragraphs.slice(0, pI)]
      }
      return state;
    },
    proDocSetDocId: (state, action)=>{
      state.docId = action.payload;
      return state;
    },
    proDocInitDocWithDocIdAndName: (state, action) => {
      const {docId, docName} = action.payload;
      const newDoc = makeEmptyDoc();
      newDoc.docId = docId;
      newDoc.docName = docName;
      return newDoc;
    },
    proDocInitDocWithDocId: (state, action) => {
      const newDoc = makeEmptyDoc();
      newDoc.docId = action.payload;
      return newDoc;
    },
    proDocUpdateDoc: (state, action) => {
      state = action.payload;
      return state;
    },
    proDocSetDocLoading: (state, action) => {
      state.loading = action.payload;
      return state;
    },
    proDocSetSectionStatus: (state, action) => {
      const {cI, sI, status} = action.payload;
      const s = state?.chapters[cI]?.sections[sI];
      if (s === undefined) {
        console.log("section not found, payload : ", action.payload);
        return state;
      }
      if (["NOT_STARTED", "QN_DONE", "IN_PROGRESS", "DONE"].indexOf(status) === -1) {
        console.error("WRONG STATUS, payload : ", action.payload);
        return state;
      }
      s.status = status;
      return state;
    },
    proDocAppendParagraphContent: (state, action) => {
      const {cI, sI, sSI, pI, content} = action.payload;
      const paragraph = state?.chapters[cI]?.sections[sI]?.subSections[sSI]?.paragraphs[pI];
      if (paragraph === undefined) {
        if (!(pI === 0)) {
          console.log("paragraph not found, payload : ", action.payload);
          return state;
        }
        const sS = state?.chapters[cI]?.sections[sI]?.subSections[sSI];
        if (sS === undefined) {
          console.log("subSection not found, payload : ", action.payload);
          return state;
        } else {
          sS.paragraphs = [makeEmptyParagraph()]
          sS.paragraphs[0].paragraphContent.slateContent[0].children[0].text = content;
          return state;
        }
      }
      paragraph.paragraphContent.slateContent[0].children[0].text += content;
      return state;
    },
    proDocSetSectionEditing: (state, action) => {
      const {cI, sI, editing} = action.payload;
      const s = state?.chapters[cI]?.sections[sI];
      if (s === undefined) {
        console.log("section not found, payload : ", action.payload);
        return state;
      }
      s.editing = editing;
      return state;
    },
    proDocSetSectionFolding: (state, action) => {
      const {cI, sI, folding} = action.payload;
      const s = state?.chapters[cI]?.sections[sI];
      if (s === undefined) {
        console.log("section not found, payload : ", action.payload);
        return state;
      }
      s.folding = folding;
      return state;
    },
    proDocSetActiveEditorId: (state, action) => {
      state.activeEditor.activeEditorId = action.payload;
    },
    proDocSetActiveEditorList: (state, action) => {
      state.activeEditor.activeEditorList.push(action.payload);
      return state;
    },
    proDocDeleteActiveEditorList:  (state) => {
      state.activeEditor.deleteEditorList.push(state.activeEditor.activeEditorList[state.activeEditor.activeEditorList.length - 1]);
      state.activeEditor.activeEditorList = state.activeEditor.activeEditorList.slice(0, -1);   
      return state;
    },
    proDocRestoreActiveEditorList:  (state) => {
      if(state.activeEditor.deleteEditorList.length === 0) return state;
      state.activeEditor.activeEditorList.push(state.activeEditor.deleteEditorList[state.activeEditor.deleteEditorList.length - 1]);
      state.activeEditor.deleteEditorList = state.activeEditor.deleteEditorList.slice(0, -1);
      return state;
    },
    proDocInitActiveEditor: (state) => {
      state.activeEditor = {
        activeEditorId:null,
        activeEditorList:[]
      };
      return state
    },
    proDocInitSubSections: (state, action) => {
      const { cI, sI} = action.payload;
      if (state && state.chapters && state.chapters[cI] && state.chapters[cI].sections &&
          state.chapters[cI].sections[sI] && state.chapters[cI].sections[sI].subSections) {
          
          state.chapters[cI].sections[sI].subSections = [];
      }
      
      return state;
    },
    proDocSetIsUndo: (state,action) => {
      state.isUndo = action.payload;
      return state;
    },
    proDocSetIsRedo: (state,action) => {
      state.isRedo = action.payload;
      return state;
    },
    proDocReset:(state,action) =>{
      state.chapters = action.payload;
      return state
    },
    proDocUpdateSubSectionTitle: (state, action) => {
      const { cI, sI, sSI,  subSectionTitleValue } = action.payload;
      if (state && state.chapters && state.chapters[cI] && state.chapters[cI].sections &&
        state.chapters[cI].sections[sI] ) {
          state.chapters[cI].sections[sI].subSections[sSI].subSectionName = subSectionTitleValue; 
      }
      
      return state;
    },
    proSetCanCardToEdit: (state,action) =>{
      state.canCardToEdit = action.payload;
      return state
    },
    swapParagraph: (state,action) =>{
      const [sourceIndex, destinationIndex, dragCId,dragSectionId,dragSubSectionId] = action.payload;
      
      return {
        ...state,
        chapters: state.chapters.map((chapter) => {
          if (chapter.chapterId !== parseInt(dragCId)) return chapter;
          return {
            ...chapter,
            sections: chapter.sections.map((section) => {
              if (section.sectionId !== parseInt(dragSectionId)) return section;
              return {
                ...section,
                subSections: section.subSections.map((subSection) => {
                  if (subSection.subSectionId !== parseInt(dragSubSectionId)) return subSection;
                  const updatedParagraphs = Array.from(subSection.paragraphs);
                  const [removed] = updatedParagraphs.splice(sourceIndex, 1);
                  updatedParagraphs.splice(destinationIndex, 0, removed);
                  return {
                    ...subSection,
                    paragraphs: updatedParagraphs
                  };
                })
              };
            })
          };
        })
      };
    },
    setPptChapters: (state,action) =>{
      state.pptChapters = action.payload;
      return state
    },
    setPptChaptersRename: (state, action) => {
      const { chapterId, sectionId, subSectionId, renameType, renameValue, paragraphIndex } = action.payload;
  
      state.pptChapters = state.pptChapters.map((chapter) => {
          if (chapter.chapterId !== parseInt(chapterId)) return chapter;
  
          // 处理 TITLE 重命名
          if (renameType === "TITLE") {
              if (subSectionId) {
                  // 修改 subSectionName
                  return {
                      ...chapter,
                      sections: chapter.sections.map((section) => {
                          if (section.sectionId !== parseInt(sectionId)) return section;
  
                          return {
                              ...section,
                              subSections: section.subSections.map((subSection) => {
                                  if (subSection.subSectionId !== parseInt(subSectionId)) return subSection;
                                  return {
                                      ...subSection,
                                      subSectionName: renameValue
                                  };
                              })
                          };
                      })
                  };
              } else if (sectionId) {
                  // 修改 sectionName
                  return {
                      ...chapter,
                      sections: chapter.sections.map((section) => {
                          if (section.sectionId !== parseInt(sectionId)) return section;
  
                          return {
                              ...section,
                              sectionName: renameValue
                          };
                      })
                  };
              }
          }

          // 处理 BLOCKTITLE 更新
          if (renameType === "BLOCKTITLE") {
            return {
                ...chapter,
                sections: chapter.sections.map((section) => {
                    if (section.sectionId !== parseInt(sectionId)) return section;

                    return {
                        ...section,
                        subSections: section.subSections.map((subSection) => {
                            if (subSection.subSectionId !== parseInt(subSectionId)) return subSection;
                            return {
                                ...subSection,
                                pageContents:subSection.pageContents.map((pageContent,index) => {
                                  if (index !== parseInt(paragraphIndex)) return pageContent;
                                  return {
                                    ...pageContent,
                                    blockTitle: renameValue
                                  };
                                })
                            };
                        })
                    };
                })
            };
        }
  
        // 处理 CONTENT 更新
        if (renameType === "CONTENT") {
            return {
                ...chapter,
                sections: chapter.sections.map((section) => {
                    if (section.sectionId !== parseInt(sectionId)) return section;
  
                    return {
                        ...section,
                        subSections: section.subSections.map((subSection) => {
                            if (subSection.subSectionId !== parseInt(subSectionId)) return subSection;
                            return {
                                ...subSection,
                                pageContents:subSection.pageContents.map((pageContent,index) => {
                                  if (index !== parseInt(paragraphIndex)) return pageContent;
                                  return {
                                    ...pageContent,
                                    blockContent: renameValue
                                  };
                                })
                            };
                        })
                    };
                })
            };
        }
  
        return chapter;
      });
    },
    initPptChapters: (state) => {
      return { ...state, pptChapters: [] };
    },
    swapPptSubSections: (state, action) => {
      const [sourceIndex, destinationIndex, dragCId, dragSectionId] = action.payload;
      
      return {
        ...state,
        pptChapters: state.pptChapters.map((chapter) => {
          if (chapter.chapterId !== parseInt(dragCId)) return chapter;
          return {
            ...chapter,
            sections: chapter.sections.map((section) => {
              if (section.sectionId !== parseInt(dragSectionId)) return section;
              const subSections = [...section.subSections];
              
              const [movedSubSection] = subSections.splice(sourceIndex, 1);
              subSections.splice(destinationIndex, 0, movedSubSection);
              
              return {
                ...section,
                subSections: subSections
              };
            })
              
          };
        })
      };
    },
    swapPptPageContents: (state,action) =>{
      const [sourceIndex, destinationIndex, dragCId,dragSectionId,dragSubSectionId] = action.payload;
      
      return {
        ...state,
        pptChapters: state.pptChapters.map((chapter) => {
          if (chapter.chapterId !== parseInt(dragCId)) return chapter;
          return {
            ...chapter,
            sections: chapter.sections.map((section) => {
              if (section.sectionId !== parseInt(dragSectionId)) return section;
              return {
                ...section,
                subSections: section.subSections.map((subSection) => {
                  if (subSection.subSectionId !== parseInt(dragSubSectionId)) return subSection;
                  const updatedPageContents = Array.from(subSection.pageContents);
                  const [removed] = updatedPageContents.splice(sourceIndex, 1);
                  updatedPageContents.splice(destinationIndex, 0, removed);
                  return {
                    ...subSection,
                    pageContents: updatedPageContents
                  };
                })
              };
            })
          };
        })
      };
    },
  
  }
});

export default proDocSlice.reducer;

export const {
  proDocUpdateParagraphContent,
  proDocInsertParagraph,
  proDocDeleteParagraph,
  proDocSetDocId,
  proDocInitDocWithDocIdAndName,
  proDocInitDocWithDocId,
  proDocUpdateDoc,
  proDocSetDocLoading,
  proDocSetSectionStatus,
  proDocAppendParagraphContent,
  proDocSetSectionEditing,
  proDocSetSectionFolding,
  proDocSetActiveEditorId,
  proDocSetActiveEditorList,
  proDocDeleteActiveEditorList,
  proDocInitActiveEditor,
  proDocInitSubSections,
  proDocSetIsUndo,
  proDocSetIsRedo,
  proDocReset,
  proDocRestoreActiveEditorList,
  proDocUpdateSubSectionTitle,
  proSetCanCardToEdit,
  swapParagraph,
  setPptChapters,
  setPptChaptersRename,
  initPptChapters,
  swapPptSubSections,
  swapPptPageContents
} = proDocSlice.actions;