/*
 * @Author: SpaceDandy13 liudonglin301@gmail.com
 * @Date: 2023-10-30 17:54:18
 * @LastEditors: SpaceDandy13 liudonglin301@gmail.com
 * @LastEditTime: 2023-10-30 17:59:38
 * @FilePath: \ChatBP-Front\Front-end\src\App.js
 * @Description: 
 */
import { ConfigProvider } from 'antd';
import {App as antdApp} from "antd";
import zhCN from 'antd/locale/zh_CN';
import React, {Suspense, useCallback} from "react";
// import React, { useCallback } from "react";
import { RequireAuth, useIsAuthenticated } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import AnalyzeWrapper from "./AnalyzeWrapper";
import "./App.css";
import Recharge from "./components/user/Recharge";
import { WS_URL } from "./composables/chatRequest";
import { WebsocketInterruptContext, interruptFunc } from "./context/AppContext";
// import RXHY from "./page";
// import Contact from './page/about/Contact';
// import Privacy from './page/about/Privacy';
// import Service from './page/about/Service';
// import BP from "./page/bp";
// import Error404Page from "./page/error/404";
// import Pro from "./page/pro/Pro";
// import ProDashboard from "./page/pro/dashboard";
// import ProDoc from "./page/pro/doc";
// import User from "./page/user";
// import WareHouse from "./page/warehouse";
import { appendTemplate as appendQABeginnerTemplate, appendTemplatePro as appendQABeginnerTemplatePro } from "./reducers/QABeginnerSlice";
import { appendTemplate as appendQAProfessionTemplate } from "./reducers/QAProfessionSlice";
import { appendHistoryMsg } from "./reducers/chatSlice";
import { appendCreativeText } from "./reducers/creativePopupSlice";
import { appendMessage } from "./reducers/globalChatSlice";
import { messageReminding } from "./reducers/globelMessageSlice";
import { paySuccessed } from "./reducers/paySlice";
import { proDocAppendParagraphContent } from "./reducers/proDocSlice";
import { setWordCountLeft } from "./reducers/userSlice";
import { destructProDocParagraphUUID, destructUUID } from "./utils/websocketUUID";
import MobilePage from './mobile-page';
// // 懒加载组件
const RXHY = React.lazy(() => import("./page"));
const WareHouse = React.lazy(() => import("./page/warehouse"));
const BP = React.lazy(() => import("./page/bp"));
const User = React.lazy(() => import("./page/user"));
const Service = React.lazy(() => import('./page/about/Service'));
const Privacy = React.lazy(() => import('./page/about/Privacy'));
const Contact = React.lazy(() => import('./page/about/Contact'));
const Error404Page = React.lazy(() => import("./page/error/404"));
const ProDoc = React.lazy(() => import("./page/pro/doc"));
const Pro = React.lazy(() => import("./page/pro/Pro"));
const ProDashboard = React.lazy(() => import("./page/pro/dashboard"));

function App() {
  const dispatch = useDispatch();
  const {modalToggle: shopToggle} = useSelector(s => s.shop);
  const isAuthed = useIsAuthenticated()();
  const screenStatus = useSelector(s=>s.QABeginner.screenStatus)
  const userDevice = localStorage.getItem("device")
  const {message} = antdApp.useApp();
  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (isAuthed) {
        console.log("authed in")
        resolve(`${WS_URL}`);
      } else {
        console.log("not authed")
      }
    });
  }, [isAuthed]);
  // const {chatId} = useParams();
  // console.log("chatId", chatId);
  
  const {sendJsonMessage} = useWebSocket(getSocketUrl, {
    onOpen: () => {
      if(isAuthed) {
        sendJsonMessage({
          Authorization: `Bearer ${localStorage.getItem(`_hongyao`)}`
        });
        console.log("WebSocket connection established.");
      }
    },
    onClose: () => {
      console.log("WebSocket Close");
    },
    onError: () => {
      console.log("WebSocket Error");
    },
    onMessage: (msgEvent) => {
      // console.log("WebSocket Message");
      let data;

      try {
        data = JSON.parse(msgEvent.data);
      } catch (err) {
        console.error("webSocket接收到非JSON数据");
      }
      const {api, uuid, content, balance} = data;
      // console.log(api, uuid, content, balance);
      if (balance !== undefined && balance!=='') {
        // 注意不能用if(balance){}; 因为balance有时候为0，需要展示出来
        dispatch(setWordCountLeft(balance))
      }
      if (api === `POST /chat/userDialogue/`) {
        const {chatId, guideId, historyIdx:hIdx} = destructUUID(uuid);
        dispatch(appendHistoryMsg({chatId, guideId, msg: content, hIdx}));
      } else if (api === `POST /chat/createGuideChat/`) {
        console.log("content:", content)
        // 新建一个guideChat -> 此时后端会输出一段话以引导
        const {chatId, guideId, historyIdx:hIdx} = destructUUID(uuid)
        dispatch(appendHistoryMsg({chatId, guideId, hIdx, msg: content}))
      } else if (api === `POST /chat/guideChat/`) {
        // 引导中发送内容
        const {chatId, guideId, historyIdx:hIdx} = destructUUID(uuid)
        dispatch(appendHistoryMsg({chatId, guideId, hIdx, msg: content}))
      } else if (api === `POST /chat/keywordOptimization/`) {
        // 关键词分析
        const {chatId, guideId, historyIdx:hIdx} = destructUUID(uuid)
        dispatch(appendHistoryMsg({chatId, guideId, msg: content, hIdx}));
      } else if (api === `POST /chat/generateBusinessPlan/`) {
        dispatch(appendQAProfessionTemplate({content, chatId: uuid}))
      // } else if(api === `/chat/beginnerEditionGenerateBusinessPlan/`) {
      //   dispatch(appendQABeginnerTemplate({content, chatId: uuid}))
      } else if(api === `POST /chat/generateTemplateFromOutline/`) {
        dispatch(appendQABeginnerTemplate({content, chatId: uuid}))
      } else if(api === `POST /chat/smartVersionFillContent/`) {
        if(screenStatus==="PRO")
        dispatch(appendQABeginnerTemplatePro({content, chatId: uuid}))
      } else if(api === `POST /chat/smartFillSurplusContent/`) {
        if(screenStatus==="PRO")
        dispatch(appendQABeginnerTemplatePro({content, chatId: uuid}))
      } else if (api === `POST /chat/plateGuiding/`) {
        // 后端没有被下面这个api换到/chat/createGuideChat/，而只是沿用了以前的内容
        const {chatId, guideId, historyIdx:hIdx} = destructUUID(uuid)
        dispatch(appendHistoryMsg({chatId, guideId, hIdx, msg: content}))
      } else if (api === `POST /chat/contentProcessing/`) {
        const {hIdx, cIdx} = JSON.parse(uuid)
        dispatch(appendCreativeText({
          historyIdx: hIdx,
          creativeIdx: cIdx,
          text: content
        }))
      } else if (api === `POST /chat/sendWinId/`) {
        console.log("POST /chat/sendWinId/", content)
        sessionStorage.setItem(`X-Win-Id`, content)
      } else if (api === `POST /wechat_pay/notify/`) {
        console.log("/wechat_pay/notify/", content);
        dispatch(paySuccessed(content));
      }else if (api === `POST /aliPay/notify/`) {
        console.log("/aliPay/notify/", content);
        dispatch(paySuccessed(content));
      }else if (api === `POST /chat/throughSummaryAnalysisCreateGuideChat/`) {
        const {chatId, guideId, historyIdx} = destructUUID(uuid);
        dispatch(appendHistoryMsg({chatId, guideId, historyIdx, msg: content}));
      }else if (api === `POST /backend_system/user/globalMessage/`){
        dispatch(messageReminding(content));
        message.open({
          type:"success",
          content:content
        })
      } else if (api === `POST /chat/userGlobalDialogue/`) {
        const {chatId: ugdsNo, historyIdx} = destructUUID(uuid);
        dispatch(appendMessage([ugdsNo, historyIdx, content, "ai"]));
      } else if (api === `POST /bp_custom/content/generate/`) {
        const {cI, sI, sSI, pI} = destructProDocParagraphUUID(uuid);
        dispatch(proDocAppendParagraphContent({cI, sI, sSI, pI, content}));
      }
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    // shouldReconnect: ()=>isAuthed,
    reconnectInterval: 1000,
    shouldReconnect: ()=> {
      console.log("judge should Reconnect")
      return true;
    },
    heartbeat: {
      // message: JSON.stringify({"ping": Math.random()}),// random失效，因为只会使用第一次生成的随机值
      message: JSON.stringify({"ping": "pong-msg"}),
      returnMessage: JSON.stringify({"pong": "pong-msg"}),
      interval: 10*1000,
    },
  });

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: "#0a7bff",
        },
      }}
    >
      <WebsocketInterruptContext.Provider value={{interruptFunc}}>
        <Suspense>
          <BrowserRouter>
            <AnalyzeWrapper>
            {shopToggle &&
              <Recharge></Recharge>
            }
            <Routes>
              {/* <Route path="/" element={<RXHY/>}></Route> */}
              
              {
                userDevice&&userDevice === "Mobile"?<Route path="/" element={<MobilePage/>}></Route>:<Route path="/" element={(isAuthed && localStorage.getItem("_hongyao") )? <WareHouse/> : <RXHY/>}></Route>
              }

              <Route path="/bp" element={
                <RequireAuth loginPath={'/'}>
                  <BP/>
                </RequireAuth>
              }/>

              <Route path="/warehouse" element={
                <RequireAuth loginPath={'/'}>
                  <WareHouse/>
                </RequireAuth>
              }/>
              <Route path="/warehouse/:option" element={
                <RequireAuth loginPath={'/'}>
                  <WareHouse/>
                </RequireAuth>
              }/>

              <Route path="/bp/:chatId" element={
                <RequireAuth loginPath={'/'}>
                  <BP/>
                </RequireAuth>
              }/>

              <Route path="/pro/questionnaire/:docId/:cId/:sId" element={<Pro/>}/>
              <Route path="/pro/doc/:docId/:cId" element={<ProDoc/>} />
              <Route path="/pro/doc/:docId/:cId/:sId" element={<ProDoc/>} />
              {/*指定sId的时候意味着要去调用并生成指定Section的内容*/}
              <Route path="/pro/dashboard/:docId" element={<ProDashboard/>}/>

              <Route path={'/user'} element={
                <RequireAuth loginPath={'/'}>
                  <User/>
                </RequireAuth>
              }/>
              <Route path={'/user/:sectionKey'} element={
                <RequireAuth loginPath={'/'}>
                  <User/>
                </RequireAuth>
              }/>

              <Route path={'/about/service'} element={<Service/>}/>

              <Route path={'/about/privacy'} element={<Privacy/>} />

              <Route path={'/about/contact'} element={<Contact/>} />

              <Route path={"/404"} element={<Error404Page/>}/>
              <Route path={"*"} element={<Error404Page/>}/>


            </Routes>
            </AnalyzeWrapper>
          </BrowserRouter>
        </Suspense>
      </WebsocketInterruptContext.Provider>
    </ConfigProvider>
  )
}

export default App;
