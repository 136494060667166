import { createSlice } from "@reduxjs/toolkit";
import { getNewIdFromArray } from "../components/createDoc/utils";

const getBlankHistory = (message) => [
  {id: 0, type: "ai", message: "请输入您的想法"},
  {id: 1, type: "human", message: message?message:""},
  {id: 2, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 3, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 4, type: "ai", message: "正在生成想法...", waiting: true},
  {id: 5, type: "human", message: ""}
];

// eslint-disable-next-line
const _outline = [
  {
    "heading_one": "引言",
    "heading_two": [
      "背景介绍",
      "创业教育机构的重要性"
    ],
    "id": "7630",
    "title": "引言",
    "children": [
      {
        "id": "6500",
        "title": "背景介绍"
      },
      {
        "id": "6388",
        "title": "创业教育机构的重要性"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的初衷与愿景",
    "heading_two": [
      "培训班和经验分享会",
      "一对一指导和辅导"
    ],
    "id": "9426",
    "title": "创业教育机构的初衷与愿景",
    "children": [
      {
        "id": "5290",
        "title": "培训班和经验分享会"
      },
      {
        "id": "3923",
        "title": "一对一指导和辅导"
      }
    ]
  },
  {
    "heading_one": "商业计划书的重要性",
    "heading_two": [
      "商业计划书编写培训班",
      "商业计划书的质量和吸引力提升"
    ],
    "id": "8934",
    "title": "商业计划书的重要性",
    "children": [
      {
        "id": "1211",
        "title": "商业计划书编写培训班"
      },
      {
        "id": "3570",
        "title": "商业计划书的质量和吸引力提升"
      }
    ]
  },
  {
    "heading_one": "与投资人对接",
    "heading_two": [
      "投资人的角色和需求",
      "创业者与投资人的沟通"
    ],
    "id": "7654",
    "title": "与投资人对接",
    "children": [
      {
        "id": "7013",
        "title": "投资人的角色和需求"
      },
      {
        "id": "8714",
        "title": "创业者与投资人的沟通"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的运营模式",
    "heading_two": [
      "导师的选拔和培养",
      "课程设置和教学方法"
    ],
    "id": "3997",
    "title": "创业教育机构的运营模式",
    "children": [
      {
        "id": "7084",
        "title": "导师的选拔和培养"
      },
      {
        "id": "4481",
        "title": "课程设置和教学方法"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的市场前景",
    "heading_two": [
      "创业者的需求和市场规模",
      "竞争对手分析"
    ],
    "id": "4416",
    "title": "创业教育机构的市场前景",
    "children": [
      {
        "id": "5712",
        "title": "创业者的需求和市场规模"
      },
      {
        "id": "4011",
        "title": "竞争对手分析"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的盈利模式",
    "heading_two": [
      "收费标准和收入来源",
      "成本控制和利润预测"
    ],
    "id": "2372",
    "title": "创业教育机构的盈利模式",
    "children": [
      {
        "id": "2210",
        "title": "收费标准和收入来源"
      },
      {
        "id": "4639",
        "title": "成本控制和利润预测"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的风险与挑战",
    "heading_two": [
      "市场竞争和变化风险",
      "导师资源和人才培养风险"
    ],
    "id": "1996",
    "title": "创业教育机构的风险与挑战",
    "children": [
      {
        "id": "2379",
        "title": "市场竞争和变化风险"
      },
      {
        "id": "5949",
        "title": "导师资源和人才培养风险"
      }
    ]
  },
  {
    "heading_one": "创业教育机构的发展策略",
    "heading_two": [
      "品牌推广和市场拓展",
      "合作伙伴和资源整合"
    ],
    "id": "5995",
    "title": "创业教育机构的发展策略",
    "children": [
      {
        "id": "3896",
        "title": "品牌推广和市场拓展"
      },
      {
        "id": "3779",
        "title": "合作伙伴和资源整合"
      }
    ]
  },
  {
    "heading_one": "结论",
    "heading_two": [
      "创业教育机构的价值和意义",
      "未来发展展望"
    ],
    "id": "1704",
    "title": "结论",
    "children": [
      {
        "id": "2962",
        "title": "创业教育机构的价值和意义"
      },
      {
        "id": "3380",
        "title": "未来发展展望"
      }
    ]
  }
]

// 该SLICE的状态仅为 QAProfession这一个组件服务（其他地方可以执行action）
const QABeginnerSlice = createSlice({
  name: "QABeginner",
  initialState: {
    toggle: "close", // open close hidden-暂时不使用hidden
    chatId: "",
    screenStatus: "IDEA",   // IDEA | DETAIL | OUTLINE | GEN | PRO
    history: getBlankHistory(),
    outline: [],
    outlineDone: false,
    template: "",
    templateDone: false,
    templatePro: "",
    templateProDone: false,
    continueProPlanFull: false
  },
  reducers: {
    open: (state, action) => {
      state.toggle = "open";
      return state;
    },
    openNew: (state, action) => {
      const {chatId,message} = action.payload;
      state.toggle = "open";
      state.chatId = chatId;
      state.screenStatus = "IDEA";
      state.history = getBlankHistory(message);
      state.outline = [];
      state.outlineDone = false;
      state.template = "";
      state.templateDone = false;
      state.templatePro = "";
      state.templateProMiddleDone = false;
      state.templateProDone = false;
      state.continueProPlanFull = false;
      return state;
    },
    close: (state, action) => {
      state.toggle = "close";
      return state;
    },
    init: (state) => {
      return {
        toggle: "close", // open close
        chatId: "",
        screenStatus: "IDEA",
        history: getBlankHistory(),
        outline: [],
        outlineDone: false,
        template: "",
        templateDone: false,
        templatePro: "",
        templateProMiddleDone: false,
        templateProDone: false,
        continueProPlanFull: false,
      };
    },
    setScreenStatus: (state, action) => {
      if (["IDEA", "DETAIL", "OUTLINE", "GEN", "PRO"].indexOf(action.payload) === -1) {
        console.error("不支持的screenStatus", action.payload);
      }
      state.screenStatus = action.payload;
      return state;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
      return state;
    },
    setMessage: (state, action) => {
      const {message, historyIndex} = action.payload;
      if(historyIndex === 1 && message.length>100) {
        // antdMessage.info("想法内容字数不可以超过100个字").then();
        return state;
      } else if (message.length>300 && [2, 3, 4].indexOf(historyIndex) !== -1 ){
        // antdMessage.info("想法补充内容字数不可以超过300个字").then();
        return state;
      }
      state.history[historyIndex].message = message;
      return state;
    },
    setMessageWaiting: (state, action) => {
      state.history[2].waiting = action.payload;
      state.history[3].waiting = action.payload;
      state.history[4].waiting = action.payload;
    },
    setOutline: (state, action) => {
      state.outline = action.payload;
      return state;
    },
    pushOutlineH2: (state, action) => {
      const [h1Index, title] = action.payload;
      // payload是一个数组，使用方式参考：dispatch(pushOutlineH2([index, "heading_two"]))
      console.log("h1Index, title", h1Index, title)
      state.outline[h1Index].children.push({
        id: getNewIdFromArray(state.outline[h1Index].children),
        title: title
      });
      return state;
    },
    swapOutlineH2: (state, action) => {
      const [h1Index, h2Index1, h2Index2] = action.payload;
      console.log("swapOutlineH2", h1Index, h2Index1, h2Index2)
      const t = state.outline[h1Index].children[h2Index1];
      state.outline[h1Index].children[h2Index1] = state.outline[h1Index].children[h2Index2];
      state.outline[h1Index].children[h2Index2] = t;
      return state;
    },
    setOutlineH1: (state, action) => {
      const [h1Index, title] = action.payload;
      state.outline[h1Index].title = title;
      return state;
    },
    setOutlineH2: (state, action) => {
      const [h1Index, h2Index, title] = action.payload;
      state.outline[h1Index].children[h2Index].title = title;
      return state;
    },
    deleteOutlineH1: (state, action) => {
      const [h1Index] = action.payload;
      state.outline.splice(h1Index, 1);
      return state
    },
    deleteOutlineH2: (state, action) => {
      const [h1Index, h2Index] = action.payload;
      state.outline[h1Index].children.splice(h2Index, 1);
      return state;
    },
    setOutlineDone: (state, action) => {
      state.outlineDone = action.payload;
      return state;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
      return state;
    },
    appendTemplate: (state, action) => {
      // 设置检查chatId是防止上一篇文章的生成内容继续填在此处。
      const {content, chatId} = action.payload;
      if (chatId !== state.chatId) {
        return state;
      }
      state.template = state.template + content;
      return state;
    },
    appendTemplatePro: (state, action) => {
      // 设置检查chatId是防止上一篇文章的生成内容继续填在此处。
      const {content, chatId} = action.payload;
      if (chatId !== state.chatId) {
        return state;
      }
      state.templatePro = state.templatePro + content;
      return state;
    },
    setTemplateDone: (state, action) => {
      state.templateDone = action.payload;
      return state;
    },
    setTemplateProDone: (state, action) => {
      state.templateProDone = action.payload;
      return state;
    },
    setTemplateProMiddleDone: (state, action) => {
      state.templateProMiddleDone = action.payload;
      return state;
    },
    //前面两个标题对应板块的响应回来的内容标识，为继续生成内容做记录
    setContinueProPlanFull: (state, action) => {
      const {continueProPlanFull} = action.payload
      state.continueProPlanFull = continueProPlanFull
      return state;
    }
  }
});

export default QABeginnerSlice.reducer;

export const {
  open,
  openNew,
  close,
  init,
  setScreenStatus,
  setHistory,
  setMessage,
  setMessageWaiting,
  setOutline,
  pushOutlineH2,
  swapOutlineH2,
  setOutlineH1,
  setOutlineH2,
  deleteOutlineH1,
  deleteOutlineH2,
  setOutlineDone,
  setTemplate,
  appendTemplate,
  appendTemplatePro,
  setTemplateDone,
  setTemplateProMiddleDone,
  setTemplateProDone,
  setContinueProPlanFull
} = QABeginnerSlice.actions;