import { useCallback, useEffect, useRef, useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { wlyAxios } from "../../../composables/chatRequest";
import { useErrCodeHook } from "../../../composables/errCodeHook";
import { setWordCountLeft } from "../../../reducers/userSlice";

export function useWordCount() {
  const {handleErrCode} = useErrCodeHook();
  const [count, setCount] = useState(0);
  const data = useSelector(state => state.pay.paySuccess);
  const isAuthed = useIsAuthenticated()();
  const dispatch = useDispatch();

  const reqCount = useCallback(()=>{
    if(isAuthed && localStorage.getItem("_hongyao")) {
      wlyAxios()
        .get(`/chat/getUserWordBalance/`)
        .then(({code, data})=>{
          if (code === 0) {
            setCount(data)
            dispatch(setWordCountLeft(data))
          } else {
            handleErrCode(code);
          }
        })
        .catch(({code})=>{
          // TODO 登录的那一瞬间，会执行该wlyAxios请求，但是token似乎还没有存到LocalStorage中，导致报 NO_TOKEN_CODE 错误码
          handleErrCode(code);
        })
    } else {
      setCount(0)
    }
  }, [handleErrCode, isAuthed,dispatch])

  useEffect(() => {
    reqCount();

    let timer;
    const handleVisibilityChange = () => {
      if (document.hidden) {//当页面不可见时（用户未关闭页面，但不在该页面停留时，停止发字数请求）
        clearInterval(timer);
      } else {
        reqCount();
        timer = setInterval(reqCount, 60 * 1000);
      }
    };

    if (!document.hidden) {
      timer = setInterval(reqCount, 60 * 1000);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timer);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [reqCount,data]);

  const refreshCountLastTimeRef = useRef(new Date(0));
  const refreshCount = useCallback(() => {
    const now = new Date();
    if (now - refreshCountLastTimeRef.current < 1000) return;
    refreshCountLastTimeRef.current = now;
    reqCount();
  }, [reqCount]);

  return {count, refreshCount}
}