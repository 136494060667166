import {createSlice} from "@reduxjs/toolkit";

const init = []

export const headerMenuSlice = createSlice({
  name: "headerMenu",
  initialState: init,
  reducers: {
    setHeaders: (state, action) => {
      // Redux Toolkit 允许我们在 reducers 写 "可变" 逻辑。它
      state = [
        ...action.payload
      ]
      return state
    },
  }
});

export const {setHeaders} = headerMenuSlice.actions;

export default headerMenuSlice.reducer;