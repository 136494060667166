import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from '../reducers';
import QABeginnerSlice from "../reducers/QABeginnerSlice";
import QAProfessionSlice from "../reducers/QAProfessionSlice";
import QAProjectSlice from "../reducers/QAProjectSlice";
import chatReducer from "../reducers/chatSlice";
import creativePopupSlice from "../reducers/creativePopupSlice";
import fromQT32Slice from "../reducers/fromQT32Slice";
import globalChatSlice from "../reducers/globalChatSlice";
import globelMessageSlice from "../reducers/globelMessageSlice";
import headerMenuSliceReducer from "../reducers/headerMenuSlice";
import keyWordSlice from "../reducers/keyWordSlice";
import loginRegisterSlice from "../reducers/loginRegisterSlice";
import paySlice from "../reducers/paySlice";
import proDocSlice from "../reducers/proDocSlice";
import proPreQuestionSlice from "../reducers/proPreQuestionSlice";
import shopSlice from "../reducers/shopSlice";
import slateSlice from "../reducers/slateSlice";
import tourSlice from "../reducers/tourSlice";
import userReducer from "../reducers/userSlice";

const store = configureStore({
  reducer: {
    chat: chatReducer,
    user: userReducer,
    headerMenu: headerMenuSliceReducer,
    keyword: keyWordSlice,
    creativePopup: creativePopupSlice,
    pay: paySlice,
    slate:slateSlice,
    globalMessage:globelMessageSlice,
    globalChat: globalChatSlice,
    fromQT32: fromQT32Slice,
    QAProfession: QAProfessionSlice,
    QABeginner: QABeginnerSlice,
    QAProject: QAProjectSlice,
    tour: tourSlice,
    shop: shopSlice,
    loginRegister: loginRegisterSlice,
    proDoc: proDocSlice,
    proPreQuestion:proPreQuestionSlice
  },
  // reducer: rootReducer,
});

export default store;
