import { wlyAxios } from "../../composables/chatRequest";

export const reqProductList = () => {
  return new Promise((resolve) => {
    wlyAxios({needToken:false})
      .get(`/user/products/onSaleList/`)
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]))
  });
}

export const reqMemberProductList = () => {
  return new Promise((resolve) => {
    wlyAxios({needToken:false})
      .get(`/user/products/premium/onSaleList/`)
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]))
  })
}

export const reqWxCodeUrl = (pNo,setCanClick) => {
  return new Promise((resolve) => {
    setCanClick(false);
    wlyAxios()
      .get(`/wechat_pay/transactions/?p_no=${pNo}&uuid=${"1234"}`)
      .then(({code, msg, data}) => resolve([code, {codeUrl: data.code_url}]))
      .catch(({code}) => resolve([code, undefined]))
  });
}

export const reqAliCodeUrl = (pNo) => {
  return new Promise((resolve) => {
    wlyAxios()
      .get(`/aliPay/transactions/?p_no=${pNo}&uuid=${"4321"}`)
      .then(({code, msg, data}) => resolve([code, {codeUrl: data.code_url}]))
      .catch(({code}) => resolve([code, undefined]))
  });
}