import { memberList } from "./data";

const MemberList = () => {
  return (
    <div className="member-list">
      <header>
        <span><img src="/icons/recharge/function_introduction.svg" alt=""></img></span>
        <span><img src="/icons/recharge/free_version.svg" alt=""></img></span>
        <span><img src="/icons/recharge/vip.svg" alt=""></img></span>
        {memberList.some((item)=>Object.keys(item).some((key)=>item[key].some(({sivp})=>sivp!==undefined)))&&<span><img src="/icons/recharge/svip.svg" alt=""></img></span>}
      </header>
      <ul>
        {memberList.map((item, index) => {
          return Object.keys(item).map((key) => {
            return( 
              <li key={`${index}_${key}`}>
                  <span>{key}</span>
                  <div>
                  {
                    item[key].map(({ introduction, freeVersion, vip, svip }, idx) => (
                      <div key={`${index}_${idx}`}>
                        <span>{introduction}</span>
                        <span>{freeVersion}</span>
                        <span>{vip==="无限"?<img src="/icons/recharge/infinite.svg" alt=""></img>:vip}</span>
                        {svip&&<span>{svip==="无限"?<img src="/icons/recharge/infinite.svg" alt=""></img>:svip}</span>}
                      </div>
                    ))
                  }
                  </div>
                
              </li>
            )
          });
        })}
        <footer>
          <span>VIP尊享<span>12</span>项专属特权</span>
        </footer>
      </ul> 
    </div>
  )
}

export default MemberList;