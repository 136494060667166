import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../composables/chatRequest';
import { reqUserLog } from '../network';
import { setUserHomeVisit } from '../reducers/userSlice';

//节流
export function useThrottle(delay = 1000) {
    const lastInvocationTime = useRef(Date.now());

    return useCallback((func) => {
        return (...args) => {
            const now = Date.now();
            if (now - lastInvocationTime.current >= delay) {
                lastInvocationTime.current = now;
                func(...args);
            }
        };
    }, [delay]);
}

//防抖
export function useDebounce(delay = 1000) {
  const timeoutRef = useRef();

  return useCallback((func) => {
      return (...args) => {
          // 如果已存在定时器，清除之前的定时器
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
          // 设置新的定时器
          timeoutRef.current = setTimeout(() => {
              func(...args);
          }, delay);
      };
  }, [delay]); // 当delay变化时重新创建防抖函数
}

//检测用户在首页的操作数据
export function useEventTracking(isHome) {
    const [userHomeVisitList, setUserHomeVisitList] = useState([]);
    const userHomeVisit = useSelector(s=>s.user.userHomeVisit)
    const [firstFinishLoad, setfirstFinishLoad] = useState();
    const [hasPageLoad,setHasPageLoad] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{
      setfirstFinishLoad()
      setHasPageLoad()
    },[])//页面挂载时初始化状态

    const trackEvent = (category, action, label, value) => {
      setUserHomeVisitList(prevList => {
        const newEvent = value !== undefined 
          ? ['_trackEvent', category, action, label, value] 
          : ['_trackEvent', category, action, label];
        const index = prevList.findIndex(event =>
          event[1] === category && event[2] === action && event[3] === label
        );
  
        if (index !== -1) {
          const updatedList = [...prevList];
          updatedList[index] = newEvent;
          return updatedList;
        } else {
          return [...prevList, newEvent];
        }
      });
    };
  
    let pageLoadTime = 0;
  
    const recordPageLoadTime = () => {
      pageLoadTime = performance.now();
      trackEvent('ClientPageLoad', '页面加载时间', '', `${(pageLoadTime / 1000).toFixed(2)}秒`);
    };
  
    const recordPageStayTime = () => {
      const stayTime = performance.now() - pageLoadTime;
      trackEvent('PageStay', '用户停留时间', '', `${(stayTime / 1000).toFixed(2)}秒`);
    };
  
    const recordScrollDepth = () => {
      const scrollDepth = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollDepth / documentHeight) * 100;
      trackEvent('Scroll', '页面浏览当前深度百分比', '', scrollPercentage);
    };
  
    let maxScrollPercentage = 0;
    const recordScrollMaxDepth = () => {
      const scrollDepth = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPercentage = (scrollDepth / documentHeight) * 100;
      if (scrollPercentage > maxScrollPercentage) {
        maxScrollPercentage = scrollPercentage;
        trackEvent('Scroll', '页面浏览最大深度百分比', '', maxScrollPercentage);
      }
    };
  
    const recordDeviceType = () => {
      const deviceType = /Mobi|Android|iPhone/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
      trackEvent('Device', '设备类型', deviceType);
      // 记录屏幕分辨率
      const screenWidth = window.screen.width * window.devicePixelRatio;
      const screenHeight = window.screen.height * window.devicePixelRatio;
      trackEvent('Screen', '屏幕分辨率', `${screenWidth}x${screenHeight}`);
      //移动设备不弹新手引导
      if (deviceType === "Mobile" && !userHomeVisitList.some(item => item.includes("Device"))) {
        localStorage.setItem("tour_bp_finished", "true")
        localStorage.setItem("tour_for_guide_finished", "true")
        localStorage.setItem("tour_warehouse_create_doc_finished", "true")
        localStorage.setItem("hasSeenTour", "true");
      }
    };
  
    const recordBrowserInfo = () => {
      const browserInfo = navigator.userAgent;
      trackEvent('Browser', '浏览器类型和版本', browserInfo);
    };
  
    const recordPlatformInfo = () => {
      const platform = navigator.platform;
      trackEvent('Platform', '操作系统', platform);
    };
  
    const recordPerformanceMetrics = () => {
    //   const loadTime = performance.now();
      const tti = performance.timing.domInteractive - performance.timing.navigationStart;
    //   trackEvent('Performance', '页面加载时间', '', loadTime);
      trackEvent('Performance', '最后一次点击按钮弹出弹窗的时间', '', `${(tti / 1000).toFixed(2)}秒`);
    };
  
    const recordVisitTime = () => {
      function convertToBeijingTime(utcTimeString) {
        const utcDate = new Date(utcTimeString);
        const beijingOffset = 8 * 60; // 北京时间与 UTC 的时差，以分钟为单位
        const beijingDate = new Date(utcDate.getTime() + beijingOffset * 60 * 1000);
  
        return beijingDate.toISOString().replace('T', ' ').slice(0, 19);
      }
      const visitTime = new Date().toISOString();
      const beijingTime = convertToBeijingTime(visitTime);
      trackEvent('User', '访问时间', '', beijingTime);
    };
  
    const recordReferrer = () => {
      const referrer = document.referrer;
      trackEvent('User', '用户来源', referrer);
    };
  
    const recordContentClick = (event) => {
      const target = event.target;
      const stayTime = performance.now() - pageLoadTime;
      trackEvent('PageStay', '用户访问到最后一次点击弹窗的停留时间', '', `${(stayTime / 1000).toFixed(2)}秒`); 
      if (target.classList.contains('content-link')) {
        trackEvent('Content', 'Click', target.innerText || target.href);
      }
      if (target.tagName === 'BUTTON' || target.className === "user-icon") {
        trackEvent('Button', 'Click', target.innerText || target.id || 'Unknown Button');
      }
    };
  
    const debounce = (func, wait) => {
      let timeout;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
      };
    };
  
    const handleBeforeUnload = (event) => {
      const blob = new Blob([JSON.stringify(userHomeVisit)], { type: 'application/json' });
    
      // 使用 sendBeacon 发送数据
      navigator.sendBeacon(`${BASE_URL}/bp_custom/init/log/`, blob);

    };
  
    useEffect(() => {
      if (isHome) {
        const handleLoad = () => {
          recordPageLoadTime();
          recordPerformanceMetrics();
          recordDeviceType();
          recordBrowserInfo();
          recordPlatformInfo();
          recordVisitTime();
          recordReferrer();
        };
  
        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', debounce(recordScrollDepth, 200));
        window.addEventListener('scroll', debounce(recordScrollMaxDepth, 200));
        document.addEventListener('click', recordContentClick);

        recordDeviceType();
        recordBrowserInfo();
        recordPlatformInfo();
        recordVisitTime();
        recordReferrer();
  
        return () => {
          window.removeEventListener('load', handleLoad);
          window.removeEventListener('beforeunload', handleBeforeUnload);
          window.removeEventListener('scroll', debounce(recordScrollDepth, 200));
          window.removeEventListener('scroll', debounce(recordScrollMaxDepth, 200));
          document.removeEventListener('click', recordContentClick);
        };
      }
      // eslint-disable-next-line
    }, [isHome]);

  useEffect(() => {
    const intervalId = setInterval(recordPageStayTime, 3000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [pageLoadTime]);
  
  useEffect(() => {
    if (userHomeVisitList.length > 0 && isHome) {
      const isClickList = userHomeVisitList.map(item => item.includes("Button"));
      const isClick = isClickList.some(item => item);
      const currentClientId = localStorage.getItem("client_id")
      let clientId = ""
      const deviceType = userHomeVisitList.find(item=>item.includes("Device"))?userHomeVisitList.find(item=>item.includes("Device"))[3]:""
      if(currentClientId){
        clientId = currentClientId
      }else{
        clientId = generateRandomId();
        localStorage.setItem("client_id",clientId);
      }
      if(!firstFinishLoad){
        userHomeVisitList.push([
          "_trackEvent",
          "PageLoad",
          "第一次渲染内容页面加载时间",
          "",
          `${(performance.now() / 1000).toFixed(2)}秒`
        ])
        reqUserLog(userHomeVisitList,isClick,clientId,deviceType,null)
        setfirstFinishLoad(true)
      }
      if(!hasPageLoad&&userHomeVisitList.find(item=>item.includes("ClientPageLoad"))){
        reqUserLog(userHomeVisitList,isClick,clientId,deviceType,null)
        setHasPageLoad(true)
      }

      let phoneNumber = null;
      const phoneInput = document.querySelector('.phone');
      // 确保输入框存在
      if (phoneInput) {
        // 通过 value 属性获取输入框的值
        phoneNumber = phoneInput.value;
      }
      const data = {
        "info": userHomeVisitList,
        "click": isClick,
        "client_id": clientId,
        "device_type": deviceType,
        "phone_number":phoneNumber,
      };
      dispatch(setUserHomeVisit(data));
      
      window.addEventListener('beforeunload', handleBeforeUnload);
      
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
    // eslint-disable-next-line
  }, [userHomeVisitList,hasPageLoad,firstFinishLoad]);
}

  export const generateRandomId = () => {
    return crypto.getRandomValues(new Uint8Array(16)).map(b => b.toString(16).padStart(2, '0')).join('');
  };