import { useEffect, useState } from "react";
import { useErrCodeHook } from "../../composables/errCodeHook";
import { reqMemberProductList, reqProductList } from "./network";

export const useProductList = ()=> {
  const {handleErrCode} = useErrCodeHook();
  const [list, setList] = useState([]);
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    reqProductList().then(([code, data]) => {
      if (code !== 0) {
        handleErrCode(code);
        return
      }
      setWaiting(false)
      setList(data.map(({title,token_count, p_no, price, description, only_buy_once, can_be_buy, available_level}) => ({
        "tokenCount": token_count /10000,
        "pNo": p_no,
        "title": title,
        "price": price % 100===0 ? `${(price / 100).toString().split(".")[0]}`:`${(price / 100).toFixed(2)}`, // 转换为分
        "description": description,
        "onlyBuyOnce": only_buy_once,
        "canBeBuy": can_be_buy,
        "availableLevel":available_level
      })));
    
    });
  }, [handleErrCode]);
  return {list, waiting};
}

export const useMemberProductList = () => {
  const {handleErrCode} = useErrCodeHook();
  const [list, setList] = useState([]);
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    reqMemberProductList().then(([code, data]) => {
      if (code !== 0) {
        handleErrCode(code);
        return
      }
      setWaiting(false)
      setList(data.map(({token_count, p_no, price, duration, token_number_per_day,title, original_price, only_buy_once,can_be_buy}) => ({
            "title":title,
            "tokenCount":token_count / 10000,
            "pNo": p_no,
            "actuallyPaid":price / 100,
            "duration":duration,
            "price":price % 100 === 0
                    ? `${(price / 100).toString().split(".")[0]}`
                    : `${parseFloat((price / 100).toFixed(2))}`,
            "remarks":price % 100===0 ? `${(original_price / 100).toString().split(".")[0]}`:`${(original_price / 100).toString().split(".")[0]}${original_price % 100===0?"":`.${original_price % 100}`}`,
            "dailyGiveaways":token_number_per_day / 10000,
            "onlyBuyOnce":only_buy_once,
            "canBeBuy":can_be_buy,
            "discounts": (original_price - price) / 100
      })));
    });
  }, [handleErrCode]);
  return {list, waiting};
}