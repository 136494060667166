import {createSlice} from "@reduxjs/toolkit";

// 主要就是用来处理warehouse页面新建文档跳转到template页面的状态记录
const fromQT32Slice = createSlice({
  name: "fromQT32",
  initialState: {
    fromChatId: "",
    fromHistory: [],
  },

  reducers: {
    setFromChatId: (state, action) => {
      state.fromChatId = action.payload;
      return state;
    },

    setFromHistory: (state, action) => {
      state.fromHistory = action.payload;
      return state;
    }
  }
})

export const {setFromChatId, setFromHistory} = fromQT32Slice.actions;

export default fromQT32Slice.reducer;