export function getNewIdFromArray(arr) {
  let newId = (Math.random() * 10000).toFixed().toString();
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === newId) {
      // 重新生成 + 重新判断
      newId = (Math.random() * 10000).toFixed().toString();
      i = -1;
    }
  }
  return newId;
}

export function parseOriginToTarget(arr) {
  const _arr = JSON.stringify(arr);
  arr = JSON.parse(_arr);
  for(let i=0; i<arr.length; i++) {
    const heading_two = arr[i].heading_two;
    const children = [];
    for (let j=0; j<heading_two.length; j++) {
      children.push({
        id: getNewIdFromArray(children),
        title: heading_two[j]
      })
    }
    arr[i].id = getNewIdFromArray(arr);
    arr[i].title = arr[i].heading_one;
    arr[i].children = children;
  }
  return arr.slice();
}