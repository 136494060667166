import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReqVerifyCodeLastTime } from "../../../reducers/loginRegisterSlice";

export default function VerifyCodeCountDown({
                                              clickAble, onClick = () => {
  }
                                            }) {
  const dispatch = useDispatch();
  const reqVerifyCodeLastTime = useSelector(s => s.loginRegister.reqVerifyCodeLastTime);
  const [isReady, setIsReady] = useState(true);
  const [countdownSecond, setCountdownSecond] = useState(60);
  const onClickReqVerifyCode = (e) => {
    if (!isReady) {
      e.stopPropagation();
    }
    if (isReady && clickAble) {
      dispatch(setReqVerifyCodeLastTime((new Date()).getTime()));
      setIsReady(false);
      onClick();
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      const now = (new Date()).getTime();
      if (now - reqVerifyCodeLastTime >= 60 * 1000) {
        setIsReady(true);
        setCountdownSecond(60);
      } else {
        setIsReady(false);
        setCountdownSecond(((60 * 1000 - (now - reqVerifyCodeLastTime)) / 1000));
      }
    }, 200);
    return () => clearInterval(timer);
  }, [reqVerifyCodeLastTime]);

  return (
    <span onClick={onClickReqVerifyCode} className={isReady ? "" : "dim"}>
      {
        isReady
          ? "获取验证码"
          : countdownSecond.toFixed(0) + "秒后可重新获取"
      }
    </span>
  );
}