import {createSlice} from "@reduxjs/toolkit";

const _history = [
  // {
  //   role: "ai", // ai | human
  //   message: "Hello~😋"
  // },
  // {
  //   role: "human", // ai | human
  //   message: "我想做一个好人"
  // }
];

const globalChatSlice = createSlice({
  name: "globalChat",
  initialState: {
    listMap: {},
    activeUgdsNo: "",
  },
  reducers: {
    // 存储以session为元素的数组，如果该数组带上了history，则一起设置改session的history，否则设定一个初始化的history。
    setListMap: (state, action) => {
      if (Array.isArray(action.payload)) {
        const newListMap = {};
        action.payload.forEach(({ugdsNo, sessionName, history}) => {
          newListMap[ugdsNo] = {
            history: history || [..._history],
            ugdsNo,
            sessionName,
            isEditingSessionName: false,
          };
        });
        state.listMap = newListMap;
        return state;
      } else {
        console.error("setListMap but the type of action.payload is not Array");
      }
    },
    newGlobalChat: (state, action) => {
      const [ugdsNo, sessionName, history] = action.payload;
      if (state.listMap[ugdsNo]) {
        console.error("执行了newGlobalChat， 但该ugdsNo先前已经有对话了！");
      }
      state.listMap[ugdsNo] = {
        history: history || _history,
        ugdsNo,
        sessionName: sessionName || "",
        isEditingSessionName: false,
      };
    },
    activateUgdsNo: (state, action) => {
      state.activeUgdsNo = action.payload;
      return state;
    },
    setEditingSessionName: (state, action) => {
      const [ugdsNo, bool] = action.payload;
      if (bool === undefined) console.error("未设定setEditingSessionName的bool值");
      state.listMap[ugdsNo].isEditingSessionName = bool;
      return state;
    },
    updateSessionName: (state, action) => {
      const [ugdsNo, newName] = action.payload;
      if (state.listMap[ugdsNo]) {
        state.listMap[ugdsNo].sessionName = newName;
      } else {
        console.error(`ugdsNo-${ugdsNo}-指向的对话不存在`)
      }
      return state
    },
    setHistory: (state, action) => {
      const [ugdsNo, history] = action.payload;
      if(state.listMap[ugdsNo]) {
        state.listMap[ugdsNo].history = history;
      }
    },
    appendMessage: (state, action) => {
      const [ugdsNo, hIdx, text, role = "ai"] = action.payload;
      if (state.listMap[ugdsNo]) {
        const history = state.listMap[ugdsNo].history;
        if (history) {
          if(history[hIdx]) {
            history[hIdx].message += text;
          }
          else {
            history.push({
              role: role,
              message: text
            });
          }
        } else {
          if (history.length !== hIdx) {
            console.error(`${hIdx}并非history.length${state.history.length}， 请检查业务`);
          }
          history.push({
            role: role,
            message: text
          });
        }
      }
      return state
    }
  }
});

export default globalChatSlice.reducer;
export const {
  setListMap,
  newGlobalChat,
  activateUgdsNo,
  setEditingSessionName,
  updateSessionName,
  setHistory,
  appendMessage,
} = globalChatSlice.actions;