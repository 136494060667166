import { wlyAxios } from "../../composables/chatRequest";

export function reqDetailByIdea(chatId, firstIdea) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/beginnerEditionEntrepreneurialIdea/`, {
        "answer": firstIdea,
        "chat_id": chatId
      })
      .then(({code, data}) => {
        if (code === 0) {
          const {
            entrepreneurial_idea_one: detail1,
            entrepreneurial_idea_two: detail2,
            entrepreneurial_idea_three: detail3,
            balance
          } = data;
          resolve([code, {detail1, detail2, detail3, balance}]);
        } else {
          resolve([code, undefined])
        }
      })
      .catch(({code})=>resolve([code, undefined]))
  });
}

/**
 * @description 即将废弃
 * @param chatId
 * @param detail
 * @returns {Promise<unknown>}
 */
export function reqBeginnerBusinessPlan(chatId, detail) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/beginnerEditionGenerateBusinessPlan/`, {
        "change_entrepreneurial_ideas": detail,
        "chat_id": chatId,
        "uuid": chatId
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  });
}

export function reqBeginnerOutline(chatId, detail) {
  return new Promise((resolve, reject)=>{
    wlyAxios()
      .post(`/chat/generateProjectPlanOutline/`, {
        change_entrepreneurial_ideas: detail,
        chat_id: chatId,
        uuid: chatId,
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

export function reqBeginnerPlanByOutline(chatId, outline) {
  const outlineInOriginForm = parseTargetToOrigin(outline)
  return new Promise((resolve)=>{
    wlyAxios({needXWinId: true})
      .post(`/chat/generateTemplateFromOutline/`, {
        // change_entrepreneurial_ideas: outlineInOriginForm,
        outline_content: outlineInOriginForm,
        chat_id: chatId,
        uuid: chatId,
      })
      .then(({code, data})=>resolve([code, data]))
      .catch(({code})=>resolve([code, undefined]))
  })
}

// 智能版填充内容 - 生成前面两个标题对应板块的内容
export function reqBeginnerProPlanByOutline(chatId) {
  return new Promise((resolve)=>{
    wlyAxios({needXWinId: true})
      .post(`/chat/smartVersionFillContent/`, {
        chat_id: chatId,
        uuid: chatId,
        version: "1",
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

// 智能版填充剩余板块的内容
export function reqBeginnerProPlanFull(chatId, bp_content) {
  return new Promise((resolve)=>{
    wlyAxios({needXWinId: true})
      .post(`/chat/smartFillSurplusContent/`, {
        chat_id: chatId,
        bp_content,
        version: "1",
        uuid: chatId,
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

// 用于接口/chat/smartFillSurplusContent/接口前将outline的数据结构转成原始数据结构
export function parseTargetToOrigin(arr) {
  const _arr = JSON.stringify(arr);
  arr = JSON.parse(_arr);
  const res = [];
  for (let i=0; i<arr.length; i++) {
    const heading_two = [];
    for (let j=0; j<arr[i].children.length; j++) {
      heading_two.push(arr[i].children[j].title)
    }
    res.push({
      heading_one: arr[i].title,
      heading_two: heading_two,
    })
  }
  return res;
}

export function reqGenerateAnswersStartupQa(questionList,responseList,nextQuestion,chatId) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/generateAnswersStartupQa/`,{
        "question_list": questionList,
        "response_list": responseList,
        "next_question": nextQuestion,
        "chat_id": chatId
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

export function reqGetPreferredAnswers(questionList,responseList,question,userResponse,chatId) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/getPreferredAnswers/`,{
        "question_list": questionList,
        "response_list": responseList,
        "question":question,
        "user_response": userResponse,
        "chat_id": chatId
      })
      .then(({code, data}) => resolve([code, data]))
      .catch(({code}) => resolve([code, undefined]));
  })
}

