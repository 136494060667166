import { useEffect } from "react";

const ContactBox = ({contactShow,onClickOutside=()=>{},setContactShow,contactType,setContactType,modalType}) => {
  useEffect(() => {
    const clickOutside = () => {
      onClickOutside();
    };
    window.addEventListener("mousedown", clickOutside);
    return () => {
      window.removeEventListener("mousedown", clickOutside);
    };

  }, [onClickOutside]);

  const handleContactShow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setContactShow(!contactShow)
  }

  const handleContact = (e,type) => {
    e.stopPropagation();
    e.preventDefault();
    if(contactType !== type){
      setContactType(type)
    }
  }

  const handleContactLeave = (e) => {
    e.stopPropagation();
    setContactType("")
  }
  
  return (
    <div className="contact" onMouseDown={e=>e.stopPropagation()} >
      {!contactShow&&<img src="/icons/userinfo/contact.svg" alt="" onMouseOver={e=>handleContactShow(e)} className="pro-contact"></img>}
      {contactShow&&<img src="/icons/userinfo/contact_blue.svg" alt="" onMouseLeave={e=>handleContactShow(e)}></img>}
      {
        contactShow&&
          <div data-type={modalType}>
            <div className="origin-contact">
              <span>联系我们</span>
              <ul>
                <li
                  onMouseOver={e => handleContact(e,"COMPANYWC")} 
                  onMouseLeave={e => handleContactLeave(e)}
                  data-selected={contactType === "COMPANYWC" ? true:false}>
                  <img src="/icons/userinfo/companyWechat.svg" alt=""></img>
                  <span>添加企业微信</span>
                  <div><img src="/images/service_wechat.png" alt=""></img></div>
                </li>
              </ul>
            </div>
          </div>
      }
    </div>
    //  以下为原设计稿二维码放置位置 
    // <div className="contact" onMouseDown={e=>e.stopPropagation()}>
    //   {!contactShow&&<img src="/icons/userinfo/contact.svg" alt="" onClick={e=>handleContactShow(e)} className="pro-contact"></img>}
    //   {contactShow&&<img src="/icons/userinfo/contact_blue.svg" alt="" onClick={e=>handleContactShow(e)}></img>}
    //   {
    //     contactShow&&
    //       <div>
            
            
    //         <div>
    //           <span>联系我们</span>
    //           <ul>
    //             <li 
    //               onMouseOver={e => handleContact(e,"COMPANYWC")} 
    //               onMouseLeave={e => handleContactLeave(e)}
    //               data-selected={contactType === "COMPANYWC" ? true:false}>
    //               <img src="/icons/userinfo/companyWechat.svg" alt=""></img>
    //               <span>添加企业微信</span>

    //             </li>
    //           </ul>
    //         </div>
    //         {contactType === "COMPANYWC" && <img src="/images/service_wechat_frame.svg" alt=""></img>}
    //       </div>
    //   }
    // </div>
  )
}

export default ContactBox;