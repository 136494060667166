import { useCallback } from "react";
import { wlyAxios } from "./chatRequest";
import { useErrCodeHook } from "./errCodeHook";

export function usePreValidationHook() {
  const {handleErrCode} = useErrCodeHook();

  const preValidate = useCallback((apiStr, apiParams)=>{
    return new Promise((resolve)=>{
      wlyAxios()
        .post(
          `/chat/interfacePreconditionValidation/`,
          {
            api_name: apiStr,
            api_parameter: apiParams,
          })
        .then(({code})=>{
          if(code === 0) {
            resolve(true)
          } else {
            handleErrCode(code);
            resolve(false)
          }
        })
    })
  }, [handleErrCode])

  return {preValidate}
}

export function useExtraPreValidationHook() {
  const extraPreValidate = (apiStr, apiParams)=>{
    return new Promise((resolve)=>{
      wlyAxios()
        .post(
          `/chat/interfacePreconditionValidation/`,
          {
            api_name: apiStr,
            api_parameter: apiParams,
          })
        .then(({code})=>{
          if(code === 0) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
    })
  }
  return {extraPreValidate}
}