export function SureIcon({fill}) {
    return (
        <svg t="1698973426385" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5863"
            width="18" height="18">
            <path d="M0 0h1024v1024H0z" fill="#4B73EB" fillOpacity="0" p-id="5864"></path>
            <path d="M748.8 339.2L422.4 665.6 268.8 512c-12.8-12.8-38.4-19.2-51.2 0-19.2 19.2-12.8 38.4 6.4 51.2l179.2 179.2c12.8 12.8 38.4 19.2 51.2 0l51.2-51.2 300.8-300.8c12.8-12.8 19.2-38.4 0-51.2-19.2-25.6-44.8-12.8-57.6 0z"
                fill={fill?fill:"#4B73EB"} p-id="5865">
            </path>
        </svg>
    )
}

export function DenyIcon({fill}) {
    return (
        <svg t="1698973972046" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8230"
            width="18" height="18"><path d="M0 0h1024v1024H0z" fill="#4B73EB" fillOpacity=".01" p-id="8231"></path>
            <path d="M246.504974 198.233151m24.135911 24.135911l530.990053 530.990053q24.135911 24.135911 0 48.271823l0 0q-24.135911 24.135911-48.271823 0l-530.990053-530.990053q-24.135911-24.135911 0-48.271823l0 0q24.135911-24.135911 48.271823 0Z"
                  fill={fill?fill:"#4B73EB"} p-id="8232"></path>
            <path d="M777.490116 198.235185m-24.135911 24.135911l-530.990053 530.990052q-24.135911 24.135911 0 48.271823l0 0q24.135911 24.135911 48.271823 0l530.990052-530.990052q24.135911-24.135911 0-48.271823l0 0q-24.135911-24.135911-48.271822 0Z"
                  fill={fill?fill:"#4B73EB"} p-id="8233"></path>
        </svg>
    )
}