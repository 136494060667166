import { useEffect, useState } from "react";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useErrCodeHook } from "../../composables/errCodeHook";
import { saveMessageHistory } from "../../reducers/globelMessageSlice";
import { openShop, setRechargeMember } from "../../reducers/shopSlice";
import { setUserInfo } from "../../reducers/userSlice";
import ContactBox from "./ContactBox";
import MessageBox from "./MessageBox";
import { useWordCount } from "./hooks/wordCount";
import { reqGlobalMessage, reqUserInfo } from "./network";
import "./workHeader.css";
import { setMessage } from "../../reducers/QABeginnerSlice";

export default function WorkHeader({classType,backgroundColor,toHome,setNewurl="",setIsOpen="",cId,sId,handleClickStart=()=>{},modalType=""}) {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const isAuth = useIsAuthenticated();
  const user = useAuthUser();
  // 使用钩子主动请求的字数↓
  const {count, refreshCount} = useWordCount();  // 定时请求更新字数
  // 和redux同步，一般是websocket接到通知时获取的↓
  const wordCountLeft = useSelector(s => s.user.wordCount.left);
  // 最终展示的字数↓
  const [showWordCountLeft, setShowWordCountLeft] = useState(0);
  const [showMessageBox,setShowMessageBox] = useState(false);
  const dispatch = useDispatch();
  const hasNotifications = useSelector(s=>s.globalMessage.notifications);
  const {handleErrCode} = useErrCodeHook();
  const [contactShow,setContactShow] = useState(false);
  const [contactType,setContactType] = useState("");

  const userInfo = useSelector(s=>s.user.userInfo);
  const token = localStorage.getItem("_hongyao");

  // 如果请求函数有更新，则按照count更新
  useEffect(() => {
    setShowWordCountLeft(Math.max(0, count))
  }, [count]);

  // 如果websocket有更新，则按照wordCountLeft更新
  useEffect(()=>{
    setShowWordCountLeft(Math.max(0, wordCountLeft))
  }, [wordCountLeft])
 // 如果showWordCountLeft大于100万，拼接字符
  useEffect(()=>{
    const str = showWordCountLeft.toString();
    if (showWordCountLeft >= 100000000) {
      const result = str.slice(0, -8) + '亿';
      setShowWordCountLeft(result);
    }else if(showWordCountLeft>=1000000){
      const result = str.slice(0, -4) + '万';
      setShowWordCountLeft(result);
    }
  }, [showWordCountLeft])

  useEffect(() => {
    if (isAuth()&& token) {
      reqUserInfo()
        .then(([code, data]) => {
          if (code === 0) {
            dispatch(setUserInfo(data.user_info));
          } else {
            handleErrCode(code);
          }
        });//个人信息
      reqGlobalMessage()
        .then(([code,data])=>{
          handleErrCode(code);
          if(code === 0){
            dispatch(saveMessageHistory(data))
          }
        });//全局消息
    }else{
      dispatch(setUserInfo(null));
      dispatch(saveMessageHistory(null));
    }
    // eslint-disable-next-line
  }, [isAuth(), handleErrCode, dispatch]);


  const handleMessgeBox = () => {
    setShowMessageBox(!showMessageBox);
  }

  const handleSignOut = () => {
    setTimeout(() => {
      signOut();
      if(modalType==="mobile"){
        dispatch(setMessage({message: "", historyIndex: 1}))
      }
    }, 100);
    navigate("/");
  }

  const closeContactShow = () => {
    setContactShow(false);
  };

  const closeShowMessageBox = () => {
    setShowMessageBox(false);
  }
  const handleNavLink=(e)=>{
    e.preventDefault();
    if(setIsOpen&&setNewurl&&cId===0&&sId===0){
      setIsOpen(true);
      setNewurl(toHome?"/":"/warehouse");
      return;
    }
    navigate(toHome?"/":"/warehouse");
  }
  return (
    <>
      <div className={classType?`work-header ${classType}`:"work-header"} style={{background:backgroundColor}}>
        <NavLink  onClick={(e) => {isAuth()&&modalType!=="mobile" &&handleNavLink(e)}}>
          <img src={"/icons/header/logo_formal.svg"} alt={""} className={"logo"}/>
        </NavLink>
        <div className="user-place">
          <ContactBox modalType={modalType} contactShow={contactShow} setContactShow={setContactShow} contactType={contactType} setContactType={setContactType} onClickOutside={closeContactShow}></ContactBox>
          {isAuth() &&
            <MessageBox modalType={modalType} showMessageBox={showMessageBox} onClickOutside={closeShowMessageBox} hasNotifications={hasNotifications} handleMessgeBox={handleMessgeBox}></MessageBox>
          }
          <p data-show={isAuth() ? "true":"true"}></p>
          {isAuth() && <span className={"user-icon"}>
             <img alt={"P"} src={"/icons/common/user_avatar.svg"}/>
          </span>}
          {
            isAuth() &&
            <div className="userinfo" onClick={e=>e.stopPropagation()} data-type={modalType}>
              <div>
                <header>
                  <img src={"/icons/common/user_avatar.svg"} alt=""></img>
                  <span>{user()?.username.substring(0, 3) + '****' + user()?.username.substring(7)}</span>
                  <span>升级享全部会员权益</span>
                  <span onClick={(e)=>{e.preventDefault();handleSignOut()}}>退出登录</span>
                </header>
                <main>
                  <img src="/icons/userinfo/discounts_diamon.svg" alt=""></img>
                  <img src="/icons/userinfo/font_vip.svg" alt=""></img>
                  <span>限时优惠</span>
                  <button onClick={()=>{
                            dispatch(openShop());
                            dispatch(setRechargeMember(true));
                          }}>立即开通</button>
                  {userInfo?.premium_level==="Level 0"&&<><img src="/icons/userinfo/normal_diamon.svg" alt=""></img><span>普通用户</span></>}
                  {userInfo?.premium_level==="Level 1"&&<><img src="/icons/userinfo/vip_diamon.svg" alt=""></img><span>VIP</span></>}
                  {userInfo?.premium_level==="Level 2"&&<><img src="/icons/userinfo/svip_diamon.svg" alt=""></img><span>SVIP</span></>}
                  {(userInfo?.premium_level==="Level 1"||userInfo?.premium_level==="Level 2")&&<span data-svip="true">有效期:{userInfo.end_time}</span>}
                  {/* <span data-svip="true">有效期:1212</span> */}
                  <img src="/icons/userinfo/font.svg" alt=""></img>
                  <span>可用字数：<span title={"点击字数可刷新"} onClick={(e) => {e.preventDefault();refreshCount();}} style={{cursor: "pointer"}}>{showWordCountLeft}</span></span>
                </main>
              </div>
            </div>
          }
          {
            !isAuth() && <span className={"user-icon"} onClick={(e) => {e.preventDefault();e.stopPropagation();handleClickStart(e);}}>
              <img alt={"P"} src={"/icons/common/user_unlogin_avatar.svg"}/>
            </span>
          }
          {!isAuth() && <p className={"user-button"} onClick={(e) => {handleClickStart(e);}}>登录/注册</p>}
        </div>
      </div>
    </>
  )
}