import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useErrCodeHook } from "../../composables/errCodeHook";
import { useThrottle } from "../../hook";
import {
  messageReminding,
  saveMessageHistory,
} from "../../reducers/globelMessageSlice";
import {
  reqDeleteMessage,
  reqGlobalMessage,
  reqSetAllMessageRead,
  reqSetMessageRead
} from "./network";

const MessageBox = ({ showMessageBox,onClickOutside=()=>{} ,hasNotifications,handleMessgeBox,modalType}) => {
  const [messageType, setMessageType] = useState("UNREAD");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const listContent = useSelector((s) => s.globalMessage.messageHistory);
  const dispatch = useDispatch();
  const { handleErrCode } = useErrCodeHook();
  const throttle = useThrottle();
  // const [notificationShow, setNotificationShow] = useState(false);
  useEffect(() => {
    const unreadElement = listContent?.find((element) => element.is_read === 0);
    if (unreadElement) {
      dispatch(messageReminding("有未读的全局消息"));
    } else {
      dispatch(messageReminding(""));
    }
  }, [listContent, dispatch]);
  
  useEffect(() => {
      const clickOutside = () => {
        onClickOutside();
      };
      window.addEventListener("mousedown", clickOutside);
      return () => {
        window.removeEventListener("mousedown", clickOutside);
      };
  }, [onClickOutside]);

  const handleMessgeChange = (type) => {
    setSelectedMessage(null);
    switch (type) {
      case "UNREAD": {
        setMessageType("UNREAD");
        break;
      }
      case "READ": {
        setMessageType("READ");
        break;
      }
      default:
    }
  };

  const handleMessageAction = (gmNo, action) => {
    action(gmNo).then(() => {
      reqGlobalMessage().then(([code, data]) => {
        handleErrCode(code);
        if (code === 0) {
          dispatch(saveMessageHistory(data));
        }
      });
    });
  };

  const handleAllToRead = throttle(() => {//设为全部已读
    handleMessageAction("", reqSetAllMessageRead);
  })
  
  const handleSetMessageRead = throttle((gmNo) => {
    handleMessageAction(gmNo, reqSetMessageRead);
  });

  const handleAllReadDelete = throttle(() => {//把全部已读的消息删除
    const ReadList = listContent.filter((item)=>item.is_read === 1);
    if(ReadList.length !== 0){
      ReadList.forEach((item)=>{
        handleDeleteMessage(item.gm_no);
      })
    }
  })
  
  const handleDeleteMessage = (gmNo) => {
    handleMessageAction(gmNo, reqDeleteMessage);
  }

  const handleLiClick = (item) => {
    setSelectedMessage(item);
  };

  const handleClickClose = () => {
    setSelectedMessage(null);
  };
  const handleNotificationShow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleMessgeBox(true)
  }
  const handleNotificationClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleMessgeBox(false)
  }

  return (
    <div className={"notification"} data-tip={hasNotifications?"hasNotifications":""}  onMouseDown={e=>e.stopPropagation()}>
      {!showMessageBox&&<img src="/icons/header/notification.svg" alt="" onMouseOver={e=>handleNotificationShow(e)}  className="pro-notification"></img>}
      {showMessageBox&&<img src="/icons/userinfo/notification_blue.svg" alt="" onMouseLeave={e=>handleNotificationClose(e)}></img>}

      {showMessageBox&&listContent && (
      <div data-type={modalType} onClick={(e) => e.stopPropagation()} onMouseLeave={e=>handleNotificationClose(e)}>
        <div>
          <p
            className={messageType === "READ" ? "active" : ""}
            onClick={(e) => {
              e.stopPropagation();
              handleMessgeChange("READ");
            }}
          >
            已读消息
          </p>
          |
          <p
            className={messageType === "UNREAD" ? "active" : ""}
            onClick={(e) => {
              e.stopPropagation();
              handleMessgeChange("UNREAD");
            }}
          >
            未读消息
          </p>
          {messageType === "UNREAD" &&
            <span onClick={e=>{e.stopPropagation();handleAllToRead()}}>全部已读</span>
          }
          {messageType === "READ" &&
            <span onClick={e=>{e.stopPropagation();handleAllReadDelete()}}>全部删除</span>
          }
        </div>
        {messageType === "UNREAD" &&
          <ul>
            {selectedMessage 
            ? <li data-detail={"detail"}>
                <img src="/icons/message_box/message.svg" alt=""></img>
                <div>
                  <span>宏耀AI：</span>
                  <button onClick={handleClickClose}>×</button>
                </div>
                <span>{selectedMessage.content}</span>
              </li>
            : listContent.slice().reverse().map((item, index) => {
                if (item.is_read === 0) {
                  return (
                    <li
                      key={index}
                      onClick={throttle(() => {
                        handleLiClick(item);
                        handleSetMessageRead(item.gm_no);
                      })}
                    >
                      <img src="/icons/message_box/message.svg" alt=""></img>
                      <div>
                        <div>
                          <span>宏耀AI：</span>
                          <span className="message-timestamp">
                          {(()=>{
                              const d = new Date(item.create_time * 1000);
                              const y = d.getFullYear();
                              const m = d.getMonth()+1;
                              const day = d.getDate();

                              const currentYear = new Date().getFullYear();
                              if (y < currentYear) {
                                return `${y}年${m}月${day}日`;
                              } else {
                                return `${m}月${day}日`;
                              }
                            })()}
                         </span>
                        </div>
                        <span className="message-content">{item.content}</span>
                      </div>
                    </li>
                  );
                }
                return null;
              }
            )}
          </ul>
        }
        {messageType === "READ" &&
          <ul>
          {selectedMessage ? (
            <li data-detail={"detail"}>
              <img src="/icons/message_box/message.svg" alt=""></img>
              <div>
                <span>宏耀AI：</span>
                <button onClick={handleClickClose}>×</button>
              </div>
              <span>{selectedMessage.content}</span>
            </li>
          ) : (
            listContent.slice().reverse().map((item, index) => {
              if (item.is_read === 1) {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      handleLiClick(item);
                    }}
                    className="read"
                  >
                    <img src="/icons/message_box/message.svg" alt=""></img>
                    <div>
                      <div>
                        <span>宏耀AI：</span>
                        <span>
                          {(()=>{
                                const d = new Date(item.create_time * 1000);
                                const y = d.getFullYear();
                                const m = d.getMonth()+1;
                                const day = d.getDate();

                                const currentYear = new Date().getFullYear();
                                if (y < currentYear) {
                                  return `${y}年${m}月${day}日`;
                                } else {
                                  return `${m}月${day}日`;
                                }
                              })()}
                        </span> 
                      </div>
                      <span>{item.content}</span>
                    </div>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMessage(item.gm_no);
                      }}
                    >
                      <img src="/icons/message_delete.svg" alt=""></img>
                    </span>
                  </li>
                );
              }
              return null;
            })
          )}
          </ul>
        }
      </div>
      
      )}
    </div>
  );
};

export default MessageBox;
