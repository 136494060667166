import "animate.css";
import 'globalthis/auto';  // 这会自动导入并应用 globalThis polyfill(适配低版本浏览器)
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import { enableMapSet } from 'immer';
import "antd/dist/reset.css";
// import { inject } from '@vercel/analytics';
import { StyleProvider,legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { AuthProvider } from "react-auth-kit";
import { Provider } from 'react-redux'; // <-- Import Redux's Provider component
import store from './store'; // <-- Import the Redux store you've created
import { App as AntdApp } from 'antd';
// enableMapSet()
// inject();

// eslint-disable-next-line 
// 记录用户设备 
const deviceType = /Mobi|Android|iPhone/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
if(!localStorage.getItem("device"))
  localStorage.setItem("device", deviceType);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>  {/* <-- Wrap App with Provider and pass the store as a prop */}
    <AuthProvider
      authType={'localstorage'}
      authName={'_hongyao'}
      // refresh={refreshAPI} - 以后开发
    >
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]} >  {/* Wrap App with StyleProvider */}
        <AntdApp>
          <App />
        </AntdApp>
      </StyleProvider>
    </AuthProvider>
    </Provider>
    
);
