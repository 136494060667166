import { createSlice } from "@reduxjs/toolkit";

export const paySlice = createSlice({
    name: "pay",
    initialState : {
        paySuccess:""
    },
    reducers: {
        //支付成功返回
        paySuccessed: (state, action) => {
            state.paySuccess = action.payload;
            return state;
        }
    }
})

export const {paySuccessed} = paySlice.actions;

export default paySlice.reducer;
