import { wlyAxios } from "../../composables/chatRequest";

export async function reqSelectTemplate(chatId, version) {
  return new Promise((resolve) => {
    wlyAxios()
      .post(`/chat/selectBusinessPlanTemplate/`, {
        "chat_id": chatId,
        "version": version//（pro版"-1"，普通版"1"）
      })
      .then(({code, data})=>resolve([code, data]))
      .catch(({code})=>resolve([code, undefined]))
  });
}