import { wlyAxios } from "../../composables/chatRequest";

//列表
export function reqGlobalMessage() {
  return new Promise((resolve) => {
    wlyAxios({ needToken: false })
      .get(`/user/globalMessageList/`)
      .then(({ code, data }) => resolve([code, data]))
      .catch(({ code }) => resolve([code, undefined]));
  });
}

export function reqDeleteMessage(gmNo) {
  return new Promise((resolve) => {
    wlyAxios({ needToken: false })
      .delete(`/user/globalMessage/`, {
        params: {
          gm_no: gmNo,
        },
      })
      .then(({ code, data }) => resolve([code, data]))
      .catch(({ code }) => resolve([code, undefined]));
  });
}

export function reqSetMessageRead(gmNo) {
  return new Promise((resolve) => {
    wlyAxios({ needToken: false })
      .post(`/user/globalMessage/isRead/?gm_no=${gmNo}`)
      .then(({ code, data }) => resolve([code, data]))
      .catch(({ code }) => resolve([code, undefined]));
  });
}

export function reqSetAllMessageRead(gmNo) {
  return new Promise((resolve) => {
    wlyAxios({ needToken: false })
      .post(`/user/globalMessage/isRead/all/`)
      .then(({ code, data }) => resolve([code, data]))
      .catch(({ code }) => resolve([code, undefined]));
  });
}

export function reqUserInfo() {
  return new Promise((resolve) => {
    wlyAxios({ needToken: false })
      .get(`/user/info/`)
      .then(({ code, data }) => resolve([code, data]))
      .catch(({ code }) => resolve([code, undefined]));
  });
}
