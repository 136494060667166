import {createSlice} from "@reduxjs/toolkit";

const creativePopupSlice = createSlice({
  name: "creativePopup",
  initialState: {
    status: "none", // show | none | hidden
    history: [
      // {
      //   type: "优化", // 优化、分析、简写、扩写
      //   originText: "", // 选中的原文
      //   originRange: {start: {}, end: {}},
      //   distance
      //   chatId: "fc3d32066157d5afdc5aa558b9de7cac",
      //   creativeList: [
      //     {
      //       subType: "用词专业度", // 说服力 | 简洁性 | 逻辑性 | 逻辑分析 | ...
      //       text: "", // AI生成的内容
      //       deleted: false, // 是否被删除
      //       finished: false,  // 是否生成完成
      //     }
      //   ]
      // }
    ]
  },
  reducers: {
    /**
     * 创建一个新的creativePopup
     */
    openNewCreativePopup: (state, action) => {
      const {type, originText, subType, originRange, distance, chatId} = action.payload;
      state.history.push({
        type,
        originText,
        originRange,
        distance,
        chatId,
        creativeList: [
          {
            subType,
            text: "",
            deleted: false,
            finished: false,
          }
        ]
      });
      state.status = "show";
      return state;
    },

    // 添加一个分析条目
    addCreativeItem: (state, action) => {
      const {subType} = action.payload;
      state.history[state.history.length - 1].creativeList.push({
        subType,
        text: "",
        deleted: false,
        finished: false,
      });
    },

    // 在对应条目追加内容
    appendCreativeText: (state, action) => {
      const {historyIdx, creativeIdx, text} = action.payload;
      if (typeof (historyIdx) !== "number" || typeof (creativeIdx) !== "number") {
        console.error("historyIdx或creativeIdx使用了非法类型");
        return;
      }
      if (historyIdx < 0 || creativeIdx < 0 || historyIdx >= state.history.length || creativeIdx >= state.history[historyIdx].creativeList.length) {
        console.error("非法historyIdx或creativeIdx", historyIdx, creativeIdx);
        return;
      }
      state.history[historyIdx].creativeList[creativeIdx].text += text;
      return state;
    },

    // 标记对应条目为完成状态
    finishCreativeItem: (state, action) => {
      const {historyIdx, creativeIdx} = action.payload;
      if (typeof (historyIdx) !== "number" || typeof (creativeIdx) !== "number") {
        console.error("historyIdx或creativeIdx使用了非法类型", historyIdx, creativeIdx);
        return;
      }
      if (historyIdx < 0 || creativeIdx < 0 || historyIdx >= state.history.length || creativeIdx >= state.history[historyIdx].creativeList.length) {
        console.error("非法historyIdx或creativeIdx", historyIdx, creativeIdx);
        return;
      }
      state.history[historyIdx].creativeList[creativeIdx].finished = true;
      return state;
    },

    // 销毁popup组件 - 注意不会清除redux，只是status会被设置成none
    unMountCreativePopup: (state) => {
      state.status = "none";
      return state
    }
  }
});

export const {
  openNewCreativePopup,
  addCreativeItem,
  appendCreativeText,
  finishCreativeItem,
  unMountCreativePopup,
} = creativePopupSlice.actions;

export default creativePopupSlice.reducer;
